import Logo from "assets/images/newLogo.png";
import useLoggedInUser from "hooks/useLoggedInUser";
// import usePlan from "hooks/usePlan";
// import { toTitleCase } from "lib/titleCase";
import { useEffect } from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { useStore } from "store";
import Logo2 from "assets/images/qrOwl.svg";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import { getHeader } from "lib/getHeader";
import { CiMenuBurger } from "react-icons/ci";

const WorkspaceLayout = () => {
  const { user } = useLoggedInUser();
  const { setSideBar, isMobile, setIsMobileSidebarOpen, isMobileSidebarOpen } = useSideBarStore();
  // const { plan } = usePlan();
  const { setUser } = useStore();

  useEffect(() => {
    setUser(user?.data);
  }, [setUser, user?.data]);

  return (
    <div>
      <div className="flex items-center justify-between h-[80px] px-8 border-[1px] border-b-[#DFE5EC]">
        {!isMobile && (
          <Link to="/" className="logo">
            <img src={Logo} alt="Mooyi logo" className="w-[120px]" />
          </Link>
        )}
        <div className="flex flex-row gap-3">
          {isMobile && (
            <Link to="/">
              <img src={Logo2} alt="logo" width={50} height={50} />
            </Link>
          )}
          <p className="text-[16px] font-semibold">{getHeader(window.location.href)}</p>
        </div>

        {/* {isMobile && <CiMenuBurger onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)} />} */}

        {!isMobile && (
          <div className="">
            <div className="flex items-center space-x-2">
              <div className="text-ss font-bold text-right">
                <p className="font-bold">
                  <span>{user?.data?.firstname}</span> <span>{user?.data?.lastname}</span>
                </p>
                {/* <p className="text-ss font-[500] text-green">{toTitleCase(plan?.data?.plan)} Plan</p> */}
              </div>
              <div className="w-[40px] h-[40px] bg-[#D7D7D7] rounded-full flex items-center justify-center overflow-hidden ">
                {!user?.data?.profile_image.includes("https://") ? (
                  <img src={`https://${user?.data?.profile_image}`} alt="" className="w-[100%] h-[100%] object-cover" />
                ) : (
                  <p className="text-black  text-ss font-[500] mx-auto">
                    {[user?.data?.firstname.slice(0, 1).toUpperCase(), user?.data?.lastname.slice(0, 1).toUpperCase()]}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <Outlet />
    </div>
  );
};

export default WorkspaceLayout;
