import { formatDate } from "lib/formatDate";
import React, { useCallback, useEffect, useState } from "react";
import { BsSearch, BsThreeDots } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import EmptyState from "pages/Broadcasts/EmptyState";
import smsBroadcastIcon from "assets/icons/broadcast/smsBroadcastIcon.png";
import useOutsideClick from "hooks/useOutsideClick";
import { MdDeleteOutline, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMutation } from "@tanstack/react-query";
import { deleteCampaign } from "services/campaignService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import DeleteModal from "../../DeleteModal";
import Typography from "utils/typography";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const Scheduled = ({
  data,
  isFetching,
  isLoading,
  pageCountStart,
  pageCountEnd,
  currentPage,
  handlePrev,
  handleNext,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const [query, setQuery] = useState("");
  const [selectArray, setSelectArray] = useState([]);
  const [active, setActive] = useState(false);
  const [singleDelete, setSingleDelete] = useState("");
  const { isMobile } = useSideBarStore();

  const refer = useOutsideClick(() => setOpen(0));

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const { mutate: mutateCampaign } = useMutation({
    mutationFn: deleteCampaign,
    onSuccess: () => {
      Toast.success("Campaign(s) deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["allSmsCampaignsData"] });
      // queryClient.invalidateQueries({queryKey:["onBoarding"]);
      handleModal(2);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleSingleDelete = (id) => {
    handleModal(2);
    setSingleDelete(id);
  };

  const deleteCampaigns = () => {
    if (singleDelete === "") {
      mutateCampaign({
        campaignIds: selectArray,
      });
    } else
      mutateCampaign({
        campaignIds: [singleDelete],
      });
    setSingleDelete("");
  };

  const duplicateFunc = (id) => {
    handleModal();
  };

  const selectAll = (e, status) => {
    if (e.target.checked) {
      data?.campaigns.map((el) => selectArray.push(el._id));
      for (let i = 0; i < data?.campaigns?.length; i++) {
        setSelectArray([...selectArray, data?.campaigns[i]?._id]);
      }
    } else {
      setSelectArray([]);
    }
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in selectArray) {
      if (selectArray[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const handleCheck = (e, x) => {
    if (e.target.checked) {
      setSelectArray([...selectArray, x]);
    } else {
      const filterArray = selectArray?.filter((el) => el !== x);
      setSelectArray(filterArray);
    }
  };

  const checkArray = useCallback(() => {
    if (selectArray.length > 0) {
      setActive(true);
    } else if (selectArray.length <= 0) {
      setActive(false);
    }
  }, [selectArray.length]);

  useEffect(() => {
    checkArray();
  }, [checkArray]);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleFilter = useCallback(
    (searchTerm) => {
      const filter = data?.campaigns?.filter((item) => {
        const content = item?.campaign_name?.toLowerCase();
        return content?.includes(searchTerm?.toLowerCase());
      });

      setFilteredData(filter);
    },
    [data?.campaigns],
  );

  useEffect(() => {
    handleFilter(query);
  }, [handleFilter, query]);

  const columns = [
    {
      accessor: "inputProps",
      header: (
        <p className="pl-0 flex items-center justify-center">
          <input
            type="checkbox"
            name="inputProps"
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={(e) => selectAll(e, "scheduled")}
          />
        </p>
      ),
    },
    { accessor: "name", header: "SMS name" },
    { accessor: "audience", header: "Audience" },
    { accessor: "sent", header: "Sent" },
    { accessor: "delivered", header: "Delivered" },
    { accessor: "clicked", header: "Clicked" },
    { accessor: "createdAt", header: "Date created" },
    { accessor: "actionProps", header: "More" },
  ];

  const smsData =
    filteredData &&
    filteredData?.map((elm) => ({
      inputProps: (
        <p className="absolute mx-auto left-0 right-0 flex justify-center items-center w-[100%] h-[100%] top-0">
          <input
            type="checkbox"
            name="recipient"
            id="recipient"
            checked={checkInArray(elm?._id, selectArray)}
            className="pl-0 w-[15px] h-[15px] accent-purple"
            onChange={(e) => handleCheck(e, elm?._id)}
          />
        </p>
      ),
      id: elm?._id,
      name: (
        <>
          <p
            className="font-normal text-sm hover:underline cursor-pointer"
            onClick={() => navigate(`/broadcasts/sms/create/${elm._id}`)}
          >
            {elm?.campaign_name}
          </p>
        </>
      ),
      audience:
        (elm.recipient ?? elm?.recipient?.personalContacts?.length === 0) ? (
          0
        ) : (
          <div className="text-[12px]">
            <span className="bg-gray-100 rounded-md py-1 px-2 w-fit">{elm?.recipient?.personalContacts[0]}</span>

            <span>{`${
              elm?.recipient?.personalContacts?.length > 1 ? `+${elm?.recipient?.personalContacts?.length - 1}` : ""
            }`}</span>
          </div>
        ),
      sent: <p className="text-[#525252]">{elm?.metrics?.sent?.value}</p>,
      delivered: <p className="text-[#525252]">{elm?.metrics?.delivered?.value}</p>,
      clicked: <p className="text-[#525252]">{elm?.metrics?.clicked?.value}</p>,
      createdAt: <p className="text-xs">{formatDate(elm?.createdAt)}</p>,
      actionProps: (
        <div className="relative">
          <div
            className="flex items-center justify-center -pl-2 cursor-pointer"
            onClick={() => handleModal(`${elm?._id}-id`)}
          >
            <BsThreeDots />
          </div>
          {open === `${elm._id}-id` ? (
            <div
              className="bg-white shadow-md rounded-md overflow-hidden space-y-3 py-3 px-4 absolute w-fit z-30 top-[1em] right-[1em] flex justify-center items-center cursor-pointer"
              ref={refer}
            >
              <div className="space-y-3">
                <div onClick={() => duplicateFunc(elm?._id)}>
                  <Typography.SubText className={`hover:text-primary-800`}>Duplicate</Typography.SubText>
                </div>
                <div>
                  <div onClick={() => handleSingleDelete(elm._id)}>
                    <Typography.SubText className={`hover:text-primary-800`}>Delete</Typography.SubText>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ),
    }));

  return (
    <div>
      <div className="border-[1px]">
        <div className="p-4 flex items-center justify-between relative">
          <div className="flex items-center space-x-2">
            <div className="bg-[#F2F1F3] sm:w-[366px] h-[40px] rounded-[8px] flex items-center px-4 space-x-2">
              <BsSearch className="text-[#9CA3AF]" />
              <input
                type="text"
                className="placeholder:text-[#9CA3AF] placeholder:text-ss placeholder:tracking-[-0.02em] outline-none bg-transparent w-[100%] text-s tracking-[-0.02em]"
                placeholder="Search SMS... "
                onChange={handleChange}
              />
            </div>

            {!isMobile && (
              <div className="flex space-x-3 ">
                <button
                  className={`flex items-center bg-[#EBEBEB] rounded-[6px] px-[15px] py-[9px] text-s space-x-2 ${
                    active ? "text-[#EF4444] hover:bg-[#a09f9f] " : "text-[#999999]"
                  }`}
                  onClick={active ? () => handleModal(2) : null}
                >
                  <MdDeleteOutline />
                  <p>Delete</p>
                </button>
              </div>
            )}

            {/* <IconButton leftAlign icon={<FaPlus />} text="New Campaign" onClick={() => toggleModal(1)} /> */}
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="w-[100%] border-b-[1px] bg-white">
          <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
            <tr className="h-[50px] border-r-[1px]  rounded-[2px] overflow-hidden">
              {columns &&
                columns.map((head, i) => (
                  <th key={i} className="px-2 text-left border-l-[1px] font-normal">
                    {head.header}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {isFetching || isLoading ? (
              <>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => (
                  <tr
                    key={i}
                    className="h-[50px]  text-ss text-[#404040] font-[400] border-r-[1px] rounded-[2px] bg-white border-b-[1px]"
                  >
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-2 border-l-[1px] relative">
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <>
                {smsData &&
                  smsData?.map((row, i) => (
                    <tr
                      key={i}
                      className="h-[50px] text-ss  text-[#404040] font-[500] rounded-[2px] bg-white border-b-[1px] border-r-[1px]"
                    >
                      {/* <Link to={`./analytics/${row.id}`} key={i}> */}
                      {columns?.map((col, i) => (
                        <td key={i} className={`pl-2 border-l-[1px] relative`}>
                          {row[col.accessor] ? row[col.accessor] : row[col.accessor] === 0 ? "0" : <Skeleton />}
                        </td>
                      ))}
                      {/* </Link> */}
                    </tr>
                    // </Link>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      {data?.campaigns?.length > 0 && (
        <div className="flex justify-between items-center mt-3 sm:flex-row flex-col sm:gap-0 gap-3">
          <p className="text-s flex items-center space-x-1">
            <span>preview per page</span> <span className="border py-1 px-3 flex items-center justify-center">20</span>
          </p>

          <div className="flex items-center space-x-4">
            <div
              onClick={pageCountStart === 1 || isFetching ? null : handlePrev}
              className={`hover:border py-1 px-2 ${
                pageCountStart === 1 || isFetching ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <MdKeyboardArrowLeft />
            </div>
            <div className="flex items-center space-x-1 text-s">
              <span className="border py-1 px-3 flex items-center justify-center">
                {pageCountStart} - {pageCountEnd}
              </span>{" "}
              <span>of</span> <span className="border py-1 px-3 flex items-center justify-center">{currentPage}</span>
            </div>
            <div
              onClick={pageCountEnd === data?.totalCount || isFetching ? null : handleNext}
              className={`hover:border py-1 px-2 ${
                pageCountEnd === data?.totalCount || isFetching ? "text-gray-300 cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <MdKeyboardArrowRight />
            </div>
          </div>
        </div>
      )}
      {smsData === undefined || smsData.length === 0 ? (
        <EmptyState
          title="No SMS found."
          icon={smsBroadcastIcon}
          content="You haven't created any SMS yet. Click the 'Create new email' button to begin."
          button="Create new SMS"
          link="/broadcasts/sms/create"
        />
      ) : null}
      {open === 2 ? <DeleteModal toggleModal={handleModal} deleteCampaigns={deleteCampaigns} /> : null}
    </div>
  );
};

export default Scheduled;
