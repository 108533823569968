import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import SelectInput from "components/Inputs/SelectInput";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "utils/upload";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";
import { byteConverter } from "lib/byteConverter";
import { BsDot } from "react-icons/bs";
import { createTag } from "services/contactService";
import Checkbox from "components/Inputs/CheckBox";
import UploadModal from "./UploadModal";
import PreLoader from "components/Loader/PreLoader";
import { Link } from "react-router-dom";
import TagDropDown from "components/dropdowns/TagDropDown";
import useGetAllTags from "hooks/useGetAllTags";

const MatchColumns = ({ fileHeaders, topThreeRows, rawFile, fileName, fileSize, number }) => {
  const { tagData } = useGetAllTags();
  const [open, setOpen] = useState(false);
  const [tagArr, setTagArr] = useState([]);
  const [openDrop, setOpenDrop] = useState(false);
  const [query, setQuery] = useState("");
  const [searchedArray, setSearchedArray] = useState(tagData);
  const [isChecked, setIsChecked] = useState(false);
  const [columnMatch, setColumMatch] = useState({
    firstname: "",
    lastname: "",
    gender: "",
    email: "",
    phone: "",
    birthday: "",
    address: "",
  });
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const { user } = useLoggedInUser();

  const { mutate, isPending } = useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allContacts", "onBoarding"] });

      navigate("/contacts-processing");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: create, isPending: createLoading } = useMutation({
    mutationFn: createTag,
    onSuccess: () => {
      Toast.success("Tag created successfully");
      queryClient.invalidateQueries({ queryKey: ["allTags"] });
      toggleModal();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDropdown = () => {
    setOpenDrop(!openDrop);
  };

  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const checkInArray = (y) => {
    let checked = false;
    for (let x in tagArr) {
      if (tagArr[x] === y) {
        checked = true;
      }
    }
    return checked;
  };

  const [defaultFields] = useState([
    { value: "", label: "DO NOT IMPORT" },
    { value: "firstname", label: "FIRST NAME" },
    { value: "lastname", label: "LAST NAME" },
    { value: "gender", label: "GENDER" },
    { value: "email", label: "EMAIL" },
    { value: "phone", label: "PHONE" },
    { value: "address", label: "ADDRESS" },
    { value: "birthday", label: "DATE OF BIRTH" },
  ]);

  const handleColumnMatch = (e, headingName) => {
    setColumMatch((prevState) => {
      return {
        ...prevState,
        [e.target.value]: headingName,
      };
    });

    if (e.target.value === "email" || e.target.value === "phone") {
      setDisableButton(false);
    }
  };

  const topRowsFormatted = useMemo(() => {
    // compile the three top rows data into a
    // format that makes for easy display in column matching table

    let finalObj = {};

    for (const heading of fileHeaders) {
      for (const row of topThreeRows) {
        if (heading in finalObj) {
          finalObj[heading].push(row[heading]);
        } else {
          finalObj[heading] = [row[heading]];
        }
      }
    }

    return finalObj;
  }, [fileHeaders, topThreeRows]);

  const handleSubmit = async (e) => {
    const generateUploadObj = () => {
      let finalObj = {};

      for (const property in columnMatch) {
        if (columnMatch[property]) {
          finalObj[`fields[${property}]`] = columnMatch[property];
        }
      }

      return finalObj;
    };

    const parts = fileName.split(".");
    const lastString = parts[parts.length - 1];

    try {
      e.preventDefault();
      const userData = {
        ...generateUploadObj(),
        contact: rawFile,
        fileType: lastString,
        tag: tagArr,
      };
      console.log(userData, "being uploaded");
      mutate(userData);

      // trackEvent("add_contact", {
      //   email: user?.data?.email,
      //   addContactMethod: "CSV upload",
      //   date: dayjs().format(),
      // });

      // Uncomment and handle if using a mutation
      // mutate(userData);
    } catch (error) {
      console.log(error.message);
      Toast.error(error.message || "An unexpected error occurred");
    }
  };

  const handleCheck = (e, el) => {
    if (e.target.checked) {
      if (!tagArr.includes(el)) {
        setTagArr([...tagArr, el]);
      }
    } else {
      const filterArray = tagArr?.filter((elm) => elm !== el);
      setTagArr(filterArray);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    if (query !== "") {
      const filteredData = tagData?.filter(
        (filtered) => filtered?.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
      );
      if (filteredData.length !== 0) {
        setSearchedArray(filteredData);
      }
    } else {
      setSearchedArray(tagData);
    }
  };

  const handleTagInfo = (data) => {
    create(data);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const removedFromArray = (el) => {
    const filterArray = tagArr?.filter((elm) => elm !== el);
    setTagArr(filterArray);
  };

  if (isPending) {
    return <PreLoader />;
  }

  return (
    <div className="pb-[3em]">
      {open === 3 ? (
        <UploadModal toggleModal={toggleModal} handleSubmit={handleSubmit} tagArr={tagArr} number={number} />
      ) : null}

      <p className="text-xl font-semibold">Here is a preview of your data.</p>

      <p className="text-sm text-neutral-600 w-[70%]">
        Properly match your columns to various fields. You can click to edit additional column headers on the table.{" "}
        <a href="*" className="text-primary-800 underline underline-offset-1">
          Click here
        </a>
          to learn more about making the most of your CSV data.
      </p>

      <div className="border-[1px] my-4 rounded-lg p-4">
        <div className="flex items-center">
          <p className="text-sm">{fileName}</p>
          <BsDot className="text-xl" />

          <p className="text-xs text-neutral-600">{byteConverter(fileSize)}</p>
        </div>

        <div className="flex w-[100%]   p-2 overflow-x-scroll">
          {fileHeaders.map((heading, i) => {
            return (
              <div key={i} className="rounded-[12px]  w-[200px]">
                <div className="w-[100%] bg-neutral-200">
                  <p className=" text-black border-b-[1px] border-neutral-300  py-2 pl-5 text-s font-[500]">
                    {heading}
                  </p>
                  {/* <p className="font-[600]">Match to</p> */}

                  <div className="w-[100%] pl-5">
                    <SelectInput
                      options={defaultFields}
                      name={heading}
                      onChange={(e) => handleColumnMatch(e, heading)}
                      css="h-[35px] text-sm border-none bg-white font-medium text-black "
                    />
                  </div>
                </div>

                <div className=" w-[100%] bg-white">
                  {/* <p className=" text-black bg-[#F5F5F5] px-4 py-2 text-s font-[500]">{heading}</p> */}

                  <div className="space-y-1 text-s font-[400]  border-t-[1px] ">
                    {topRowsFormatted[heading].map((ele, i) => {
                      return (
                        <p key={i} className=" border-b-[1px]  text-xs pl-8 w-[100%] py-2 truncate ...">
                          {ele}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TagDropDown tagArr={tagArr} setTagArr={setTagArr} />

      <div className="mb-4">
        <div className="flex items-start space-x-3 w-full">
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} />

          <div className="-mt-[3px]">
            <p className="text-ss mt-1">
              I confirm that I have permission from my customers (or they have signed up to receive messages) to share
              their data with third-party platforms for marketing purposes. By proceeding, you agree to our{" "}
              <Link to="https://usemooyi.com/terms-of-use" className="text-blue-800">
                {" "}
                Terms of use{" "}
              </Link>
            </p>
          </div>
        </div>
      </div>

      <BlueButton
        text="Continue"
        disabled={disableButton || isPending || !isChecked}
        onClick={() => toggleModal(3)}
        type="button"
      />
    </div>
  );
};

export default MatchColumns;
