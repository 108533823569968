import React, { useEffect, useRef, useState } from "react";
import QuestionTab from "./QuestionsTab";
import GreyButton from "components/Button/GreyButton";
import { SkipLogicIcon } from "assets/icons/survey/SkipLogicIcon";
import { MdDelete } from "react-icons/md";
import { surveyOption } from "lib/surveyFunctions";
import Toggle from "react-toggle";
import { IoIosArrowDown } from "react-icons/io";
import { surveyIcons } from "lib/surveyFunctions";
import { surveyType } from "lib/surveyFunctions";
import { RiSave3Fill } from "react-icons/ri";
import { LuDot, LuPlus } from "react-icons/lu";
import { IoChevronDown, IoCopy, IoHeart } from "react-icons/io5";
import { TbCopyPlusFilled } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createSurveyQuestions } from "services/newSurveyService";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import Toast from "config/toast";
import PreLoader from "components/Loader/PreLoader";
import { fetchBySurveyId } from "services/newSurveyService";
import SaveAndExitModal from "../SaveAndExitModal";
import { queryClient } from "config/gateway";
import { brandTrackingTemplate } from "data/brandTrackingTemplate";
import { surveyTypes } from "lib/surveyFunctions";
import SkipLogicModalPreview from "../SkipLogicModalPreview";
import SkipLogicModal from "../SkipLogicModal";
import BottomBar from "../BottomBar";
import AudienceModal from "../AudienceModal";
import Typography from "utils/typography";
import { useStore } from "store";
import { toTitleCase } from "lib/titleCase";
import BlueButton from "components/Button/BlueButton";
import FileUpload from "components/Inputs/FileUpload";
import { FaPencilAlt } from "react-icons/fa";
import SurveyDetailsModal from "../SurveyDetailsModal";
import {
  // setAutoSaveQuestions,
  getAutoSavedQuestions,
  removeAutoSavedQuestions,
} from "store/surveyStore/autoSaveSurveyQuestions";
import CopyAndPasteModal from "../CopyAndPasteModal";

const CreateQuestions = ({ setNewStep, newStep, newCardRef }) => {
  const [step, setStep] = useState(1);
  const [openSurveyType, setOpenSurveyType] = useState("");
  const [open, setOpen] = useState(null);
  const [surveyQuestionsArray, setSurveyQuestionsArray] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { surveyTemplate, setSurveyTemplate } = useStore();
  const [openThankYou, setOpenThankYou] = useState(false);
  const [hover, setHover] = useState();

  const pathArray = location.pathname.split("/");

  const { data } = useSuspenseQuery({
    queryKey: ["fetchBySurveyId-question", id],
    queryFn: () => fetchBySurveyId(id),
    enabled: !!id,
  });

  console.log(data, "this is the loaded questions");
  console.log(getAutoSavedQuestions());

  const [thankYouInfo, setThankYouInfo] = useState({
    title: "Thank you for your response. ",
    description: "We value your feedback and we will take all your concerns into consideration.",
    image: "",
  });

  const handleHover = (id) => {
    setHover(hover !== id ? id : null);
  };

  useEffect(() => {
    location?.state === "Edit" && handleModal("openSurveyModal");
  }, [location?.state]);

  const handleThankYouInput = (e) => {
    setThankYouInfo({
      ...thankYouInfo,
      [e.target.name]: e.target.value,
    });
  };

  const freshData = data?.data;

  useEffect(() => {
    if (freshData?.questions && freshData?.questions.length === 0 && id) {
      if (pathArray.includes("brand-tracking")) {
        setSurveyQuestionsArray([...brandTrackingTemplate]);
      } else if (pathArray.includes("online-survey") || pathArray.includes("field-interview")) {
        setSurveyQuestionsArray([...(surveyTemplate?.questions || [])]);
      }
    }
  }, []);

  useEffect(() => {
    if (freshData?.questions && freshData?.questions.length > 0) {
      setSurveyQuestionsArray([...freshData?.questions]);
    }
  }, [freshData?.questions]);

  const [surveyQuestion] = useState({
    question: "",
    description: "",
    questionType: "text_response",
    optional: false,
    options: [],
  });

  const createQuestion = () => {
    setSurveyQuestionsArray((prevArray) => [...prevArray, surveyQuestion]);

    if (newCardRef.current) {
      newCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const prevSurveyQuestionsLength = useRef(surveyQuestionsArray.length);

  useEffect(() => {
    const currentLength = surveyQuestionsArray.length;

    if (newCardRef.current && currentLength > prevSurveyQuestionsLength.current) {
      const element = newCardRef.current;
      element.scrollIntoView({ behavior: "smooth", block: "start" }); // Scroll to the top smoothly
    }

    // Update the previous length after the effect runs
    prevSurveyQuestionsLength.current = currentLength;
  }, [newCardRef, surveyQuestionsArray]);

  const handleSurveyTypeDropDown = (id) => {
    setOpenSurveyType(openSurveyType === id ? null : id);
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const createValueOptions = (index) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: [...updatedValues[index]?.options, ""],
      };

      return updatedValues;
    });
  };

  const addMooyiOptions = (index, value) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: updatedValues[index]?.options.includes(value)
          ? updatedValues[index]?.options?.filter((el) => el !== value)
          : [...updatedValues[index]?.options, value],
      };

      return updatedValues;
    });
  };

  const deleteValueOptions = (i, optionIndex) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];

      updatedValues[i] = {
        ...updatedValues[i],
        options: updatedValues[i]?.options?.filter((_, index) => index !== optionIndex),
      };

      return updatedValues;
    });
  };

  const handleOptional = (buttonId, presentValue) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[buttonId] = { ...updatedValues[buttonId], optional: !presentValue };
      return updatedValues;
    });
  };

  /********************** Handle Question Text [question and description] ****************/
  const handleText = (e, index) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = { ...updatedValues[index], [e.target.name]: e.target.value };
      return updatedValues;
    });
  };

  /********************** Handle  Question Text [question and description] ****************/

  const handleOptionValues = (e, index, newIndex) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      const updatedQuestion = { ...updatedValues[index] };

      updatedQuestion.options = [...updatedQuestion.options, (updatedQuestion.options[newIndex] = e.target.value)];

      return updatedValues;
    });
  };

  const duplicateQuestion = (index) => {
    setSurveyQuestionsArray((prevArray) => {
      // Create a new array with the duplicated object
      const newArray = [...prevArray];
      const objectToDuplicate = { ...prevArray[index] };

      // Insert the duplicated object into the array
      newArray.splice(index + 1, 0, objectToDuplicate);

      return newArray;
    });
  };

  const deleteQuestion = (id) => {
    setSurveyQuestionsArray((prevArray) => {
      const updatedArray = prevArray.filter((_, index) => index !== id);
      return updatedArray;
    });
  };

  const { mutate: saveQuestion } = useMutation({
    mutationFn: createSurveyQuestions,
    onSuccess: () => {
      Toast.success("Question saved successfully");
      removeAutoSavedQuestions();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const saveQuestionFunc = () => {
    saveQuestion({
      surveyId: id,
      payload: {
        questions: surveyQuestionsArray,
      },
    });
  };

  const createMatrixOptions = (index, key) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: {
          ...updatedValues[index]?.options,
          [key]: [...updatedValues[index]?.options[key], ""],
        },
        // options: [...updatedValues[index]?.options, ""],
      };

      return updatedValues;
    });
  };

  const deleteMatrixOptions = (i, optionIndex, key) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];

      updatedValues[i] = {
        ...updatedValues[i],

        options: {
          ...updatedValues[i]?.options,
          [key]: updatedValues[i]?.options[key].filter((_, index) => index !== optionIndex),
        },
      };

      return updatedValues;
    });
  };

  const handleMatrixValues = (e, index, newIndex, key) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      const updatedQuestion = { ...updatedValues[index] };
      updatedQuestion.options = {
        ...updatedQuestion.options,
        [key]: [...updatedQuestion.options[key], (updatedQuestion.options[key][newIndex] = e.target.value)],
      };

      return updatedValues;
    });
  };

  const handleTypeSelect = (value, index) => {
    if (value === "likert") {
      setSurveyQuestionsArray((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = {
          ...updatedValues[index],
          questionType: value,
          options: {
            max: {
              value: 10,
              label: "",
            },
            min: {
              value: 1,
              label: "",
            },
          },
        };
        return updatedValues;
      });
    } else if (value === "matrix") {
      setSurveyQuestionsArray((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = {
          ...updatedValues[index],
          questionType: value,
          options: {
            row: [],
            column: [],
            multichoice: false,
          },
        };
        return updatedValues;
      });
    } else if (value === "rating") {
      setSurveyQuestionsArray((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = {
          ...updatedValues[index],
          questionType: value,
          options: {
            shape: "star",
            iconQuantity: "",
            label: {
              first: "",
              last: "",
            },
          },
        };
        return updatedValues;
      });
    } else if (value === "text_response") {
      setSurveyQuestionsArray((prevValues) => {
        const updatedValues = [...prevValues];
        const { options, ...questionWithoutOption } = updatedValues[index];
        updatedValues[index] = { ...questionWithoutOption, questionType: value };
        return updatedValues;
      });
    } else if (value === "single_choice" || value === "multi_choice" || value === "drop_down" || value === "ranking") {
      setSurveyQuestionsArray((prevValues) => {
        const updatedValues = [...prevValues];
        // updatedValues[index] = {
        //   ...updatedValues[index],
        //   questionType: value,
        //   options: [...updatedValues[index]?.options],
        // };
        const currentOptions = Array.isArray(updatedValues[index]?.options) ? updatedValues[index]?.options : [];

        updatedValues[index] = {
          ...updatedValues[index],
          questionType: value,
          options: [...currentOptions], // Spread the existing or empty array
        };
        return updatedValues;
      });
    } else {
      setSurveyQuestionsArray((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[index] = { ...updatedValues[index], questionType: value, options: [] };
        return updatedValues;
      });
    }

    handleSurveyTypeDropDown();
  };

  /**************************Handle the likert Input fields ****************/
  const handleLikertType = (e, index, parent) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      const valueType = parent === "likertMax" ? "max" : "min";
      updatedValues[index] = {
        ...updatedValues[index],
        options: {
          ...updatedValues[index].options,
          [valueType]: {
            ...updatedValues[index].options[valueType],
            [e.target.name]: e.target.name === "value" ? +e.target.value : e.target.value,
          },
        },
      };
      return updatedValues;
    });
  };

  const handleRatingTextType = (e, index) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: {
          ...updatedValues[index].options,
          label: {
            ...updatedValues[index].options.label,
            [e.target.name]: e.target.value,
          },
        },
      };
      return updatedValues;
    });
  };

  const handleRatingSelectType = (e, index) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: {
          ...updatedValues[index].options,
          [e.target.name]: +e.target.value,
        },
      };
      return updatedValues;
    });
  };

  const handleRatingDropDownType = (e, index) => {
    setSurveyQuestionsArray((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = {
        ...updatedValues[index],
        options: {
          ...updatedValues[index].options,
          shape: e.value,
        },
      };
      return updatedValues;
    });
  };

  /**************************Handle the likert Input fields ****************/

  const hasNonNullSkipLogic = (question) => {
    const skipLogicValues = Object.values(question.skipLogic || {});
    return skipLogicValues.some((value) => value !== null);
  };

  const pickSelectedQuestion = (i) => {
    const foundQuestion = surveyQuestionsArray.find((el, index) => index === +i.split("-")[1]);
    setSelectedQuestionIndex(i.split("-")[1]);

    console.log(i, i.split("-")[1], foundQuestion, surveyQuestionsArray);

    setSelectedQuestion(foundQuestion);
    handleModal(5);
  };

  const { mutate, isPending: mutatingSurvey } = useMutation({
    mutationFn: createSurveyQuestions,
    onSuccess: () => {
      Toast.success("Question saved successfully");
      removeAutoSavedQuestions();
      setSurveyTemplate({
        id: 0,
        surveyName: "",
        surveyCategory: "",
        surveyDescription: "",
        coverImage: "",
        reward: 0,
        campaignType: "",
        bg: "bg-bgOne",
        questions: [],
      });
      if (pathArray.includes("online-survey")) {
        handleModal(3);
      } else {
        setNewStep(newStep + 1);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: saveMutate, isPending: saveLoading } = useMutation({
    mutationFn: createSurveyQuestions,
    onSuccess: () => {
      Toast.success("Question saved successfully");
      removeAutoSavedQuestions();
      setSurveyTemplate({
        surveyName: "",
        surveyCategory: "",
        surveyDescription: "",
        coverImage: "",
        reward: 0,
        campaignType: "",
        bg: "bg-bgOne",
        questions: [],
      });
      queryClient.invalidateQueries({ queryKey: ["onlineSurveyDraft", "brandTrackingDraft", "offline_surveyDraft"] });
      if (pathArray.includes("brand-tracking")) {
        navigate(`/research/brand-tracking`);
      } else if (pathArray.includes("online-survey")) {
        navigate(`/research/online-survey`);
      } else {
        navigate(`/research/field-interview`);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleSave = () => {
    mutate({
      surveyId: id,
      payload: {
        questions: surveyQuestionsArray,
      },
    });
  };

  const handleSaveAndExit = () => {
    saveMutate({
      surveyId: id,
      payload: {
        questions: surveyQuestionsArray,
      },
    });
  };

  const surveyTypeArray = surveyTypes;

  if (mutatingSurvey || saveLoading) {
    return <PreLoader />;
  }

  return (
    <div className="h-[70vh] flex items-center ">
      {open === 4 ? <SaveAndExitModal handleModal={handleModal} callBackFn={handleSaveAndExit} /> : null}

      {open === 3 ? <AudienceModal handleModal={() => handleModal(3)} setNewStep={setNewStep} /> : null}

      {open === "openSurveyModal" ? (
        <SurveyDetailsModal handleOpen={handleModal} from="onlineSurvey" fromStep={true} />
      ) : null}

      {open === "copyAndPasteModal" && (
        <CopyAndPasteModal
          setSurveyQuestionsArray={setSurveyQuestionsArray}
          surveyQuestionsArray={surveyQuestionsArray}
          handleModal={handleModal}
        />
      )}

      <div className="w-[30%] border-[1px] h-[100%] overflow-y-scroll ">
        <QuestionTab
          step={step}
          setStep={setStep}
          surveyQuestionsArray={surveyQuestionsArray}
          setSurveyQuestionsArray={setSurveyQuestionsArray}
          surveyQuestion={surveyQuestion}
          newCardRef={newCardRef}
        />
      </div>

      <div className="w-[70%]  border-[1px] h-[100%] bg-neutral-100 overflow-y-scroll">
        <div className="h-[50px] border-[1px] flex items-center justify-between px-4 bg-white">
          <div className="text-sm flex items-center">
            <Typography.SubText className="font-semibold">{freshData?.surveyDetails?.surveyName}</Typography.SubText>
            <LuDot className="text-2xl" />
            <p className="font-semibold text-primary-800">
              {toTitleCase(freshData?.surveyDetails?.recurringProp?.recurringInterval)}
            </p>
            <div
              className="hover:bg-gray-200 p-2 cursor-pointer rounded"
              onClick={() => handleModal("openSurveyModal")}
            >
              <FaPencilAlt className="text-gray-600" />
            </div>
          </div>

          <div className="flex items-center space-x-3 px-5">
            <div
              className="bg-primary-50 hover:bg-primary-100 w-[35px] h-[35px] rounded text-primary-800 flex items-center justify-center cursor-pointer"
              onClick={saveQuestionFunc}
            >
              <RiSave3Fill />
            </div>

            {/* <div
              className="bg-primary-50 hover:bg-primary-100 cursor-pointer w-[35px] h-[35px] rounded text-primary-800 flex items-center justify-center"
              onClick={() => handleModal(3)}
            >
              <FaEye />
            </div> */}
          </div>
        </div>
        {surveyQuestionsArray.length === 0 ? (
          <div className="flex h-[90%] items-center justify-center flex-col space-y-2">
            <Typography.SubText className="font-semibold">Add a question to get started</Typography.SubText>

            <div className="relative">
              <BlueButton
                text={
                  <div className="flex items-center space-x-2">
                    <Typography.MicroText className="text-white">Add Question</Typography.MicroText>

                    <IoChevronDown />
                  </div>
                }
                onClick={() => handleModal(1)}
              />
              {open === 1 ? (
                <div className="shadow-md bg-white overflow-hidden rounded-[8px] absolute top-[3em] left-0 w-[230px] z-20 border-[1px] ">
                  {surveyTypeArray.map((elm) => {
                    return (
                      <div
                        className="flex items-center space-x-2 h-[35px] text-slate-700 hover:bg-slate-50 pl-4  cursor-pointer "
                        key={elm}
                        onClick={() => createQuestion(elm)}
                      >
                        <div className="w-[30px]">{surveyIcons(elm)}</div>
                        <p className="text-ss">{surveyType(elm)}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="flex h-[90%] items-center">
            <div className="p-4 w-[93%] h-[100%] overflow-y-scroll ">
              {surveyQuestionsArray?.map((_, i) => {
                return (
                  <div className=" p-4 bg-white my-2 rounded" key={`${i}-surveyQuestionsArray`} ref={newCardRef}>
                    <div className="flex items-center space-x-2">
                      <p className="my-4 text-sm font-semibold">Question {i + 1}</p>

                      {hasNonNullSkipLogic(surveyQuestionsArray[i]) ? (
                        <div className="bg-primary-50 flex justify-center items-center px-2 py-1 rounded-md">
                          <SkipLogicIcon />
                        </div>
                      ) : null}
                    </div>

                    <div className="flex justify-between">
                      <input
                        type="text"
                        className="w-[63%] text-ss placeholder:text-ss font-[400] h-[40px] pl-2 border-b-[1px] outline-none"
                        placeholder="Type your question here"
                        value={surveyQuestionsArray[i]?.question}
                        onChange={(e) => handleText(e, i)}
                        name="question"
                      />

                      <div className="relative">
                        <div
                          className="border-[1px] w-[190px] h-[40px]  flex items-center text-s justify-between space-x-4 rounded-md cursor-pointer px-4"
                          onClick={() => handleSurveyTypeDropDown(i)}
                        >
                          <div className="flex space-x-2 items-center">
                            <div>{surveyIcons(surveyQuestionsArray[i]?.questionType)}</div>
                            <p className="text-ss">{surveyType(surveyQuestionsArray[i]?.questionType)}</p>
                          </div>

                          <IoIosArrowDown />
                        </div>

                        {openSurveyType === i ? (
                          <div className="shadow-md bg-white overflow-hidden rounded-[8px] absolute top-[3em] w-[190px] z-20">
                            {surveyTypeArray.map((elm) => {
                              return (
                                <div
                                  className="flex items-center space-x-2 h-[35px] text-[#737373] pl-4 border-b-[1px] cursor-pointer "
                                  key={elm}
                                  onClick={() => handleTypeSelect(elm, i)}
                                >
                                  <div className="w-[30px]">{surveyIcons(elm)}</div>
                                  <p className="text-ss">{surveyType(elm)}</p>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <input
                      type="text"
                      className="w-[100%] mt-[1em] text-ss placeholder:text-ss font-[400] h-[40px] pl-2 border-b-[1px] outline-none"
                      placeholder="Type your description here"
                      value={surveyQuestionsArray[i]?.description}
                      onChange={(e) => handleText(e, i)}
                      name="description"
                    />

                    <div></div>

                    <>
                      {surveyOption(
                        surveyQuestionsArray[i]?.questionType,
                        i,
                        handleLikertType,
                        surveyQuestionsArray[i],
                        createValueOptions,
                        handleOptionValues,
                        deleteValueOptions,
                        addMooyiOptions,
                        createMatrixOptions,
                        deleteMatrixOptions,
                        handleMatrixValues,
                        handleRatingTextType,
                        handleRatingSelectType,
                        handleRatingDropDownType,
                      )}
                    </>

                    <div className="flex justify-between border-t-[1px] items-center mt-[1em] py-2">
                      <div className="flex space-x-2 items-center ">
                        <label>
                          <Toggle
                            checked={surveyQuestionsArray[i]?.optional}
                            icons={false}
                            onChange={() => handleOptional(i, surveyQuestionsArray[i]?.optional)}
                            className="toggle"
                          />
                        </label>

                        <p className="text-ss -mt-1">Required</p>
                      </div>

                      <div className="flex items-center space-x-3">
                        {surveyQuestionsArray[i]?.questionType === "single_choice" ? (
                          <>
                            <GreyButton
                              text={
                                <p
                                  className="flex items-center space-x-2"
                                  onClick={() => pickSelectedQuestion(`skip-${i}`)}
                                >
                                  <SkipLogicIcon />
                                  <span className="text-xs">Skip Logic</span>
                                </p>
                              }
                              type="button"
                            />
                          </>
                        ) : null}

                        <div
                          className="bg-slate-100 hover:bg-slate-200 cursor-pointer w-[35px] h-[35px] rounded text-neutral-500 flex items-center justify-center"
                          onClick={() => duplicateQuestion(i)}
                        >
                          <IoCopy />
                        </div>

                        <div
                          className="bg-slate-100 hover:bg-slate-200 cursor-pointer w-[35px] h-[35px] rounded text-neutral-500 flex items-center justify-center"
                          onClick={() => deleteQuestion(i)}
                        >
                          <MdDelete className="text-xl" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {openThankYou ? (
                <div className="bg-white p-4 rounded">
                  <Typography.SubText className="font-semibold">Thank you screen</Typography.SubText>

                  <div className="space-y-4 py-4">
                    <input
                      type="text"
                      className="w-[100%] text-ss placeholder:text-ss font-[400] h-[40px] pl-2 border-b-[1px] outline-none"
                      placeholder="Enter title"
                      value={thankYouInfo.title}
                      onChange={handleThankYouInput}
                      name="title"
                    />

                    <input
                      type="text"
                      className="w-[100%] text-ss placeholder:text-ss font-[400] h-[40px] pl-2 border-b-[1px] outline-none"
                      placeholder="Type your description here"
                      value={thankYouInfo.description}
                      onChange={handleThankYouInput}
                      name="description"
                    />

                    <div className="">
                      <div className="flex space-x-2 items-center cursor-pointer" onClick={() => handleModal(2)}>
                        <Typography.SubText className="font-semibold">Add cover image</Typography.SubText>

                        <Toggle checked={open === 2} onChange={() => handleModal(2)} icons={false} />
                      </div>

                      {open === 2 ? (
                        <div className="mt-2">
                          <FileUpload name="image" setData={setThankYouInfo} data={thankYouInfo} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="w-[7%] h-auto bg-primary-50 place-items-center justify-center border-[1px] border-primary-200 rounded flex flex-col items-center py-4 space-y-3">
              <div
                onClick={createQuestion}
                onMouseEnter={() => handleHover(1)}
                onMouseLeave={() => handleHover(null)}
                className="bg-white hover:bg-slate-100 w-[40px] h-[50px] flex items-center justify-center text-xl border-[1px] border-neutral-500 rounded-md relative cursor-pointer"
              >
                <LuPlus className="text-neutral-500" />
                {hover === 1 && (
                  <div className="absolute top-3 right-10 px-3 py-1 flex items-center justify-center border rounded-md w-[100px] bg-white text-xs">
                    Add question
                  </div>
                )}
              </div>

              <div
                onClick={() => handleModal("copyAndPasteModal")}
                className="bg-white hover:bg-slate-100 w-[40px] h-[50px] flex items-center justify-center text-xl border-[1px] border-neutral-500 rounded-md relative cursor-pointer"
                onMouseEnter={() => handleHover(2)}
                onMouseLeave={() => handleHover(null)}
              >
                <TbCopyPlusFilled className="text-neutral-500" />
                {hover === 2 && (
                  <div className="absolute top-3 right-10 px-3 py-1 flex items-center justify-center border rounded-md w-[150px] bg-white text-xs">
                    Paste questions
                  </div>
                )}
              </div>

              <div
                onClick={() => setOpenThankYou(!openThankYou)}
                onMouseEnter={() => handleHover(3)}
                onMouseLeave={() => handleHover(null)}
                className={`  w-[40px] h-[50px] flex items-center justify-center text-xl border-[1px] border-neutral-500 rounded-md cursor-pointer relative  ${
                  open === "thankYou" ? "bg-primary-300 text-primary-50" : "bg-white hover:bg-slate-50 text-neutral-500"
                }`}
              >
                <IoHeart className="" />
                {hover === 3 && (
                  <div className="absolute top-3 right-10 px-3 py-1 flex items-center justify-center border rounded-md w-[150px] bg-white text-xs">
                    Thank you screen
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {open === 5 ? <SkipLogicModalPreview handleModal={handleModal} selectedQuestion={selectedQuestion} /> : null}
      {open === 6 ? (
        <SkipLogicModal
          selectedQuestion={selectedQuestion}
          handleModal={handleModal}
          surveyQuestionsArray={surveyQuestionsArray}
          setSurveyQuestionsArray={setSurveyQuestionsArray}
          selectedQuestionIndex={selectedQuestionIndex}
        />
      ) : null}

      <BottomBar step={newStep} setStep={setNewStep} handleSave={handleSave} handleModal={() => handleModal(4)} />
    </div>
  );
};

export default CreateQuestions;
