import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import Dropdown from "components/DropDown";
import PrimaryInput from "components/Inputs/PrimaryInput";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
import dayjs from "dayjs";
import useLoggedInUser from "hooks/useLoggedInUser";
import useOutsideClick from "hooks/useOutsideClick";
import { formatDate } from "lib/formatDate";
import React, { useMemo, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import { deleteSMSId, getAllSMSId } from "services/senderIdService";
import BlueButton from "components/Button/BlueButton";
import { campaignSettings, getCampaignSettings } from "services/campaignService";
import SmsIDModal from "../SenderID/SmsIDModal";
import { useStore } from "store";
import Compliance from "../SenderID/Compliance";
import { formatTime } from "lib/checkTime";
import PreLoader from "components/Loader/PreLoader";
import Pagination from "components/Pagination";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const cleanUpDate = [
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
];

const creditData = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

const smsColumns = [
  { field: "name", header: "Name" },
  { field: "createdAt", header: "Date Added" },
  { field: "verified", header: "Status" },
  { field: "viewProps", header: "" },
];

const Sms = () => {
  const { user } = useLoggedInUser();
  const [open, setOpen] = useState();
  const { onBoarding } = useStore();
  const [sendIDModal, setSendIDModal] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { isMobile } = useSideBarStore();

  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };
  const refer = useOutsideClick(() => setOpen(null));

  const { data: sms } = useQuery({ queryKey: ["smsSenderIDs"], queryFn: getAllSMSId });
  const { data, isLoading: loadSettings } = useQuery({
    queryKey: ["smsSettings"],
    queryFn: () => getCampaignSettings("sms"),
  });

  const settings = data?.data;

  const initialValues = {
    amount: settings?.sendingLimit?.amount,
    timeframe: settings?.sendingLimit?.timeframe,
    cleanUp: settings?.cleanupList?.unit,
    interval: settings?.cleanupList?.interval,
    from: loadSettings ? "" : formatTime(settings?.deliveryWindow?.from),
    to: loadSettings ? "" : formatTime(settings?.deliveryWindow?.to),
    automaticRetry: settings?.automaticRetry?.retry,
  };

  const { mutate } = useMutation({
    mutationFn: deleteSMSId,
    onSuccess: () => {
      Toast.success("SMS sender ID deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["smsSenderIDs"] });
      toggleModal();
    },
    onError: () => {
      Toast.error("Failed to delete sms sender ID");
    },
  });

  const handleDelete = (id) => {
    mutate(id);
  };

  const { mutate: updateSms, isLoading } = useMutation({
    mutationFn: campaignSettings,
    onSuccess: () => {
      Toast.success("SMS setting updated successfully");
      queryClient.invalidateQueries({ queryKey: ["smsSettings"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleClickNewID = (id) => {
    if (!onBoarding?.compliance) {
      setSendIDModal(2);
    } else {
      setSendIDModal(sendIDModal === id ? null : id);
    }
  };

  const onSubmit = async (data) => {
    const channel = "sms";
    const payload = {
      sendingLimit: {
        amount: data?.amount,
        timeframe: data?.timeframe,
      },
      deliveryWindow: {
        from: data?.from,
        to: data?.to,
      },
      cleanupList: {
        unit: data?.cleanUp,
        interval: data.interval,
      },
      automaticRetry: data.automaticRetry,
    };
    try {
      updateSms({ channel, payload });
    } catch (err) {
      Toast.error(err);
    }

    trackEvent("update_settings", {
      email: user?.data?.email,
      date: dayjs().format(),
      channel: "SMS",
    });
  };

  const { handleChange, values, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const smsData = sms?.map((el) => ({
    id: el?._id,
    name: el?.name,
    createdAt: formatDate(el?.created_at),
    plan: el?.plan,
    amount: el?.amount,
    verified: (
      <>{el?.isActive ? <p className="text-[#22C55E]">Verified</p> : <p className="text-[#737373]">Not Verified</p>}</>
    ),
    viewProps: (
      <div className="relative">
        {el?.isDefault ? null : (
          <div className="flex items-center space-x-2 text-black cursor-pointer" onClick={() => toggleModal(el?._id)}>
            <BsThreeDots />
          </div>
        )}

        {open === el?._id ? (
          <div
            className="shadow-lg rounded-[5px] bg-white absolute w-[150px] z-[10] right-[2em] cursor-pointer"
            ref={refer}
          >
            <div
              className="flex items-center space-x-[5px] text-s border-b-[1px] p-[9px] text-[#EF4444] hover:text-[#d31212]"
              onClick={() => handleDelete(el?._id)}
            >
              <MdOutlineDelete />
              <p>Delete</p>
            </div>
          </div>
        ) : null}
      </div>
    ),
  }));

  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * 5;
    const end = start + 5;
    return smsData.slice(start, end);
  }, [smsData, currentPage]);

  if (loadSettings) {
    return <PreLoader />;
  }

  return (
    <div className="bg-white p-5">
      <div>
        <div className="flex justify-between items-center">
          <p className="font-[500] sm:text-[20px] text-[14px]">{`Sender ID (${sms.length})`}</p>
          {!isMobile && (
            <div>
              <BlueButton type="button" text="New sender ID" onClick={() => handleClickNewID(1)} />
            </div>
          )}
        </div>
        <p className="font-semibold sm:text-[18px] text-[12px] my-4">Registered SMS ID</p>
        <div className="overflow-x-auto whitespace-nowrap">
          <table className="w-[100%]">
            <thead className=" text-s bg-[#F9FAFB]  border-b-[1px] ">
              <tr className=" h-[45px] px-7 text-left">
                {smsColumns &&
                  smsColumns.map((head, i) => (
                    <th key={i} className="pl-4">
                      {head.header}{" "}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {paginatedData &&
                paginatedData.map((row, i) => (
                  <tr key={i} className="h-[50px] border-b-[1px] text-s text-[#404040] font-[500]">
                    {smsColumns?.map((col, i) => (
                      <td key={i} className="pl-4">
                        {row[col.field]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          data={smsData}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          dataCount={smsData?.length}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

      <form
        action="
      "
        onSubmit={handleSubmit}
      >
        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Set sending limit</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Limit the number of SMS to be sent out from your account in a specified period.
          </p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <p className="text-sm font-semibold">Enter number of SMS</p>
              <div className="my-1 ">
                <PrimaryInput
                  name="amount"
                  text=""
                  type="number"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="0"
                />
              </div>
            </div>

            <div>
              <p className="text-sm font-semibold">Time frame</p>
              <div className="my-1">
                <Dropdown
                  tag={values.timeframe || "Choose an option"}
                  options={creditData}
                  onSelect={(select) => setFieldValue("timeframe", select.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Auto retry</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Automatically retries unsent messages after 48 hours of hitting the sending limit
          </p>

          <p className="text-sm font-semibold">Retry messages after:</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="automaticRetry"
                  text=""
                  value={values.automaticRetry}
                  type="number"
                  onChange={handleChange}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="my-1 w-[150px]">
              <div className="border p-2 w-[100px] rounded-md flex items-center justify-center text-[12px]">
                <p>Hours</p>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Delivery window</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">Set a time to send out your SMS.</p>

          <p className="text-sm font-semibold">Set a timeframe</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="from"
                  value={values.from}
                  text=""
                  onBlur={handleBlur}
                  type="time"
                  onChange={handleChange}
                />
              </div>
            </div>
            <p>to</p>
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="to"
                  value={values.to}
                  text=""
                  onBlur={handleBlur}
                  type="time"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="h-[1px] w-[100%] bg-[#DFE5EC] my-[1em]"></div>

        <div className="space-y-2 w-[100%]">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-semibold">Clean up list</p>
            {/* <Tooltip /> */}
          </div>

          <p className="text-xs text-gray-500">
            Remove contacts who have not interacted with your SMS over a period of time.
          </p>

          <p className="text-sm font-semibold">Set a timeframe</p>

          <div className="flex items-center space-x-4 w-[100%]">
            <div>
              <div className="my-1 ">
                <PrimaryInput
                  name="cleanUp"
                  text=""
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cleanUp}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="my-1 w-[150px]">
              <Dropdown
                tag={values.interval || "Choose an option"}
                options={cleanUpDate}
                onSelect={(select) => setFieldValue("interval", select.value)}
              />
            </div>
          </div>
          <div>
            <BlueButton type="submit" text="Save" loading={isLoading} />
          </div>
        </div>
      </form>
      {sendIDModal === 1 ? <SmsIDModal toggleModal={() => setSendIDModal(null)} /> : null}
      {sendIDModal === 2 ? <Compliance toggleModal={() => setSendIDModal(null)} /> : null}
    </div>
  );
};

export default Sms;
