import React from "react";
import { Route, Routes } from "react-router-dom";
import Email from "./email";
import Sms from "./sms";
import Voice from "./voice";
import EmailTabs from "./email/tabs";
import CreateSms from "./sms/tabs";
import EmailReport from "./email/Report";
import SmsReport from "./sms/Report";
import CreateVoice from "./voice/tabs";
import SMSRetargeting from "./Retargeting/sms";
import EmailRetargeting from "./Retargeting/email";
import VoiceReport from "./voice/Report";
import Home from "./Home";
import VoiceRetargeting from "./Retargeting/voice";
import MesssagingSetup from "./MessagingSetup";
import GetFollowers from "./GetFollowers";
import GetFollowersTab from "./GetFollowers/tabs/index";

const Broadcasts = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/setup" element={<MesssagingSetup />} />
      <Route exact path="/email/*" element={<Email />} />
      <Route exact path="/email/setup" element={<EmailTabs />} />
      <Route exact path="/email/setup/:id" element={<EmailTabs />} />
      <Route exact path="/email/setup/:id/:array" element={<EmailTabs />} />
      <Route path="/email/:id" element={<EmailReport />} />
      <Route exact path="/email/retarget/:emailId" element={<EmailRetargeting />} />

      <Route exact path="/sms" element={<Sms />} />
      <Route path="/sms/:id" element={<SmsReport />} />
      <Route exact path="/sms/retarget/:smsId" element={<SMSRetargeting />} />
      <Route exact path="/sms/create" element={<CreateSms />} />
      <Route exact path="/sms/create/:id" element={<CreateSms />} />

      <Route exact path="/voice/*" element={<Voice />} />
      <Route path="/voice/:id" element={<VoiceReport />} />
      <Route exact path="/voice/create" element={<CreateVoice />} />
      <Route exact path="/voice/create/:id" element={<CreateVoice />} />
      <Route exact path="/voice/retarget/:id" element={<VoiceRetargeting />} />

      <Route exact path="/get-followers/*" element={<GetFollowers />} />
      <Route exact path="/get-followers/setup" element={<GetFollowersTab />} />

      {/* <Route exact path="/sms/report" element={<SMSReport />} /> */}
    </Routes>
  );
};

export default Broadcasts;
