import useLoggedInUser from "hooks/useLoggedInUser";
import React, { useState } from "react";
import Business from "./Business";
import Goals from "./Goals";
import Profile from "./Profile";
import GreaterThan from "assets/images/greaterThan.svg";
import OnlinePresence from "./OnlinePresence";

const tabs = [
  { name: "Profile Information", value: 0 },
  { name: "Company Information", value: 1 },
  { name: "Online Presence", value: 2 },
  { name: "Additional Information", value: 3 },
];

const Home = () => {
  const [step, setStep] = useState(0);
  const { user } = useLoggedInUser();

  const displayStep = () => {
    switch (step) {
      case 0:
        return <Profile user={user} setStep={setStep} />;
      case 1:
        return <Business user={user} setStep={setStep} />;
      case 2:
        return <OnlinePresence user={user} setStep={setStep} />;
      case 3:
        return <Goals user={user} setStep={setStep} />;
      default:
    }
  };

  const moveToStep = (value) => {
    setStep(value);
  };

  return (
    <div className=" mt-[4%]">
      <div className="flex justify-between sm:w-[70%] mx-auto my-[2em] w-[90%]">
        <div className="flex space-x-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%] mx-auto overflow-scroll whitespace-nowrap no-scrollbar sm:gap-0 gap-3">
          {tabs.map((el, i) => {
            return (
              <div
                className="flex items-center justify-between sm:w-[33%] cursor-pointer"
                key={el.value}
                onClick={() => moveToStep(el.value)}
              >
                <div className="flex items-center">
                  <p
                    className={`${
                      step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                    } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                  >
                    {el.value + 1}
                  </p>
                  <p
                    className={`px-2 text-s cursor-pointer ${
                      step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                    } `}
                  >
                    {el.name}
                  </p>
                </div>

                {el.value === 2 ? null : <img src={GreaterThan} alt="GreaterThan" />}
              </div>
            );
          })}
        </div>

        {/* {step !== 3 ? <p className="text-[#29085C] font-semibold ">Step {step + 1}</p> : <div></div>} */}
      </div>

      <div className="sm:w-[35%] w-[80%] mx-auto">{displayStep()}</div>
    </div>
  );
};

export default Home;
