import { useState } from "react";
import GreaterThan from "assets/images/greaterThan.svg";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import { useNavigate, useParams } from "react-router-dom";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import Details from "./Details";
import Publish from "./Publish";

const GetFollowersTab = () => {
  const [step, setStep] = useState(1);
  const { sidebar } = useSideBarStore();
  const navigate = useNavigate();
  const [disableButton, setDisabledButton] = useState(true);
  const [editorOpen, setEditorOpen] = useState(false);

  const tabs = [
    { name: "Details", value: 1 },
    { name: "Publish", value: 2 },
  ];

  const displayStep = () => {
    switch (step) {
      case 1:
        return <Details />;
      case 2:
        return <Publish />;
      default:
    }
  };

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const handleModal = (id) => {
    // setOpen(open === id ? null : id);
  };

  const handleNext = () => {
    // if (step === 1) {
    //     // getTemplateId();
    // }
    // if (step < 3) {
    //     setStep(step + 1);
    // } else if (step === 3) {
    //     setOpen(1);
    // }
  };

  return (
    <div className="bg-white p-6 rounded-[8px] border-[1px]">
      <div className={` w-[100%] mx-auto`}>
        <div className="flex space-x-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%] mx-auto">
          {tabs.map((el, i) => {
            return (
              <div
                className="flex items-center justify-between w-[50%] cursor-pointer"
                key={el.value}
                onClick={() => moveToStep(el.value)}
              >
                <div className="flex items-center justify-center w-[100%]">
                  <p
                    className={`${
                      step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                    } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                  >
                    {el.value}
                  </p>
                  <p
                    className={`px-2 text-s cursor-pointer ${
                      step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                    } `}
                  >
                    {el.name}
                  </p>
                </div>

                {el.value === 2 ? null : <img src={GreaterThan} alt="GreaterThan" />}
              </div>
            );
          })}
        </div>
      </div>

      <div className={`w-[100%]  my-2 flex `}>
        <div className="w-[100%]">{displayStep()}</div>
      </div>

      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <GreyButton text="Back" onClick={() => (step !== 0 ? setStep(step - 1) : navigate(-1))} />

        <div className="flex justify-between space-x-4">
          <GreyButton text="Save & exit" onClick={() => handleModal(4)} disabled={disableButton} />

          <BlueButton
            text={`${step === 3 ? "Publish" : "Next"}`}
            css={`text-white ${step === 3 ? "bg-[#25C15E]" : "bg-primary-800"}  h-[40px] text-s`}
            disabled={disableButton}
            onClick={step === 1 ? () => setEditorOpen(true) : handleNext}
          />
        </div>
      </div>
    </div>
  );
};

export default GetFollowersTab;
