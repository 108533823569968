import { cn } from "helpers/classHelpers";
import React, { useState } from "react";
import { IoIosArrowRoundDown } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Typography from "utils/typography";
import defaultSurveyImage from "assets/images/defaultSurveyImage.png";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import { toTitleCase } from "lib/titleCase";
import ProgressBar from "../../../components/ProgressBarNew";
import BlueButton from "../../../components/Button/BlueButton";
import EmptyResearch from "../../../assets/svgs/emptyResearch.svg";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import CustomTable from "components/Table";

const Research2 = ({ recentSurveys, isLoading }) => {
  return (
    <div className="flex flex-col sm:overflow-x-visible  overflow-x-scroll w-full whitespace-nowrap ">
      {/* <div className="flex flex-row w-full"> */}
      <div className="flex items-center p-2 bg-gray-50 border-b-[1px] space-x-8   ">
        <div className="flex w-[40%] items-center space-x-1 ">
          <Typography.MicroText>Name</Typography.MicroText>

          <IoIosArrowRoundDown />
        </div>

        <Typography.MicroText className="w-[25%]">Responses</Typography.MicroText>

        <Typography.MicroText className="w-[10%]">Status</Typography.MicroText>

        <Typography.MicroText className="w-[25%]">Last updated</Typography.MicroText>
      </div>

      {/* </div> */}

      {isLoading ? (
        <div className="">
          <Skeleton className="h-[60px]" />
          <Skeleton className="h-[40px]" />
          <Skeleton className="h-[20px]" />
        </div>
      ) : (
        <div className="flex flex-col   whitespace-nowrap">
          {recentSurveys?.map((el, i) => (
            <Link
              to={`/research/online-survey/analytics/${el.publishId}/${el.surveyId}`}
              className={cn(
                "flex items-center py-2 space-x-8 hover:bg-gray-100 whitespace-nowrap",
                i === 2 ? "" : "border-b-[1px]",
              )}
            >
              <div className="flex items-center space-x-3 sm:w-[40%] w-[40%] ">
                <div className="w-[80px] border-[1px] h-[50px] rounded-md overflow-hidden">
                  <img
                    src={el?.surveyImage || defaultSurveyImage}
                    alt="defaultSurveyImage"
                    className="w-[100%] h-[100%] object-cover"
                  />
                </div>

                <div className="w-[60%]">
                  <Typography.MicroText className="font-semibold w-[100%] truncate ...">
                    {el?.surveyName}
                  </Typography.MicroText>

                  <Typography.MicroText>{el?.surveyCategory}</Typography.MicroText>
                </div>
              </div>

              <div className="sm:w-[25%] w-[50%] flex flex-row sm:flex-row items-center sm:space-x-3  md:overflow-x-scroll sm:no-scrollbar justify-center ">
                <ProgressBar percent={el?.responses} className="sm:w-[60%] w-[80%] whitespace-nowrap" />
                <Typography.MicroText className="whitespace-nowrap sm:w-[40%] w-[20%]">
                  {el?.responses} / {el?.expectedResponse}
                </Typography.MicroText>
              </div>

              <Typography.MicroText className="w-[10%]">{toTitleCase(el?.surveyStatus)}</Typography.MicroText>

              <Typography.MicroText className="sm:w-[25%] w-[10%]">
                {dayjs(el.date).format("ddd, DD MMM, YYYY")}
              </Typography.MicroText>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const Research = ({ recentSurveys, isLoading, setTableDetailsTapped, isMobile = false }) => {
  const navigate = useNavigate();
  return (
    <table className="whitespace-nowrap w-full border-collapse">
      <thead className="bg-gray-50">
        <tr className="border-b">
          <th className="px-4 py-2 text-left font-[300]">
            <div className="flex items-center space-x-1">
              <Typography.MicroText>Name</Typography.MicroText>
              <IoIosArrowRoundDown />
            </div>
          </th>
          <th className="px-4 py-2 text-left font-[300]">
            <Typography.MicroText className="">Responses</Typography.MicroText>
          </th>
          <th className="px-4 py-2 text-left font-[300]">
            <Typography.MicroText>Status</Typography.MicroText>
          </th>
          <th className="px-4 py-2 text-left font-[300]">
            <Typography.MicroText>Last updated</Typography.MicroText>
          </th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="4" className="px-4 py-2">
              <Skeleton className="h-[60px] mb-2" />
              <Skeleton className="h-[40px] mb-2" />
              <Skeleton className="h-[20px]" />
            </td>
          </tr>
        ) : (
          recentSurveys?.map((el, i) => (
            <tr
              key={i}
              className="border-b hover:bg-gray-50 transition-colors cursor-pointer"
              onClick={() => {
                isMobile
                  ? setTableDetailsTapped(true)
                  : navigate(`/research/online-survey/analytics/${el.publishId}/${el.surveyId}`);
              }}
            >
              <td className="px-4 py-2 flex items-center space-x-3">
                <div className="w-[80px] border h-[50px] rounded-md overflow-hidden">
                  <img
                    src={el?.surveyImage || defaultSurveyImage}
                    alt="Survey Thumbnail"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <Typography.MicroText className="font-semibold truncate">{el?.surveyName}</Typography.MicroText>
                  <Typography.MicroText>{el?.surveyCategory}</Typography.MicroText>
                </div>
              </td>
              <td className="px-4 py-2">
                <div className="flex flex-row items-center justify-center ">
                  <ProgressBar percent={el?.responses} className="w-[60%] whitespace-nowrap" />
                  <Typography.MicroText className="whitespace-nowrap">
                    {el?.responses} / {el?.expectedResponse}
                  </Typography.MicroText>
                </div>
              </td>
              <td className="px-4 py-2">
                <Typography.MicroText>{toTitleCase(el?.surveyStatus)}</Typography.MicroText>
              </td>
              <td className="px-4 py-2">
                <Typography.MicroText>{dayjs(el.date).format("ddd, DD MMM, YYYY")}</Typography.MicroText>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

const Messaging2 = ({ recentBroadCasts, isLoading }) => {
  return (
    <div className="flex flex-col sm:overflow-x-visible overflow-x-scroll  whitespace-nowrap ">
      <div className="flex flex-row  items-center py-2 bg-gray-50 border-b-[1px] gap-32 w-[150%] max-w-fit">
        <div className="flex w-[50%] min-w-[50%] max-w-[50%] items-center space-x-1  ">
          <Typography.MicroText>Name</Typography.MicroText>
          <IoIosArrowRoundDown />
        </div>

        <div className="">
          <Typography.MicroText className="sm:w-[25%] w-[20%] min-w-[20%] max-w-[20%]">Sends</Typography.MicroText>
        </div>

        <div className="">
          <Typography.MicroText className="sm:w-[10%] w-[5%] min-w-[5%] max-w-[5%]">Status</Typography.MicroText>
        </div>

        <div className="bg-green-300">
          <Typography.MicroText className="sm:w-[25%] w-[25%] min-w-[25%] max-w-[25%]">
            Last updated
          </Typography.MicroText>
        </div>
      </div>

      {isLoading ? (
        <div className="">
          <Skeleton className="h-[60px]" />
          <Skeleton className="h-[40px]" />
          <Skeleton className="h-[20px]" />
        </div>
      ) : (
        <div className="flex flex-col  w-fit">
          {recentBroadCasts?.map((el, i) => (
            <Link
              to={`/broadcasts/${el?.campaign_channel}/${el?._id}`}
              className={cn(
                "flex items-center py-2 space-x-8 hover:bg-gray-100 w-full",
                i === 2 ? "" : "border-b-[1px]",
              )}
            >
              <div className="flex items-center space-x-3 w-[50%] min-w-[50%] max-w-[50%]">
                <div className="w-[80px] border-[1px] h-[50px] rounded-md overflow-hidden">
                  <img src={defaultSurveyImage} alt="defaultSurveyImage" className="w-[100%] h-[100%] object-cover" />
                </div>

                <div className="w-[60%]">
                  <Typography.MicroText className="font-semibold w-[100%] truncate ...">
                    {el?.campaign_name}
                  </Typography.MicroText>

                  <Typography.MicroText> {el?.campaignObjective} </Typography.MicroText>
                </div>
              </div>

              <div className="sm:w-[25%] w-[20%] min-w-[20%] max-w-[20%]   flex flex-row sm:flex-row items-center sm:space-x-3  md:overflow-x-scroll sm:no-scrollbar justify-center ">
                <ProgressBar percent={el?.metrics?.delivered?.value} className="sm:w-[60%] w-[80%] whitespace-nowrap" />
                <Typography.MicroText className="whitespace-nowrap sm:w-[40%] w-[20%]">
                  {el?.metrics?.delivered?.value} / {el?.metrics?.sent?.value}
                </Typography.MicroText>
              </div>

              <Typography.MicroText className="w-[5%]"> {toTitleCase(el?.campaign_status)} </Typography.MicroText>

              <Typography.MicroText className="w-[25%]">
                {dayjs(el?.campaign_date).format("ddd, DD MMM, YYYY  ")}
              </Typography.MicroText>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const Messaging = ({ recentBroadCasts, isLoading, setTableDetailsTapped, isMobile = false }) => {
  const navigate = useNavigate();
  return (
    <table className="whitespace-nowrap w-full border-collapse">
      {/* Table Header */}
      <thead className="bg-gray-50">
        <tr className="border-b">
          <th className="px-4 py-2 text-left font-[300]">
            <div className="flex items-center space-x-1">
              <Typography.MicroText>Name</Typography.MicroText>
              <IoIosArrowRoundDown />
            </div>
          </th>
          <th className="px-4 py-2 text-left font-[300]">
            <Typography.MicroText className="sm:w-[25%] w-[20%] min-w-[20%] max-w-[20%]">Sends</Typography.MicroText>
          </th>
          <th className="px-4 py-2 text-left font-[300]">
            <Typography.MicroText className="sm:w-[10%] w-[5%] min-w-[5%] max-w-[5%]">Status</Typography.MicroText>
          </th>
          <th className="px-4 py-2 text-left font-[300]">
            <Typography.MicroText className="sm:w-[25%] w-[25%] min-w-[25%] max-w-[25%]">
              Last updated
            </Typography.MicroText>
          </th>
        </tr>
      </thead>

      {/* Table Body */}
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="4" className="px-4 py-4 text-center">
              <Skeleton className="h-[60px] mb-2" />
              <Skeleton className="h-[40px] mb-2" />
              <Skeleton className="h-[20px]" />
            </td>
          </tr>
        ) : (
          recentBroadCasts?.map((el, i) => (
            <tr
              key={i}
              className="border-b hover:bg-gray-100 transition-colors cursor-pointer"
              onClick={() => {
                isMobile ? setTableDetailsTapped(true) : navigate(`/broadcasts/${el?.campaign_channel}/${el?._id}`);
              }}
            >
              <td className="px-4 py-2 flex items-center space-x-3">
                <div className="w-[80px] border h-[50px] rounded-md overflow-hidden">
                  <img src={defaultSurveyImage} alt="defaultSurveyImage" className="w-full h-full object-cover" />
                </div>
                <div className="w-[60%]">
                  <Typography.MicroText className="font-semibold truncate">{el?.campaign_name}</Typography.MicroText>
                  <Typography.MicroText>{el?.campaignObjective}</Typography.MicroText>
                </div>
              </td>

              <td className="px-4 py-2">
                <div className="flex flex-row items-center justify-center ">
                  <ProgressBar percent={el?.metrics?.delivered?.value} className="w-[60%] whitespace-nowrap" />
                  <Typography.MicroText className="whitespace-nowrap">
                    {el?.metrics?.delivered?.value} / {el?.metrics?.sent?.value}
                  </Typography.MicroText>
                </div>
              </td>

              <td className="px-4 py-2">
                <Typography.MicroText>{toTitleCase(el?.campaign_status)}</Typography.MicroText>
              </td>

              <td className="px-4 py-2">
                <Typography.MicroText>{dayjs(el?.campaign_date).format("ddd, DD MMM, YYYY")}</Typography.MicroText>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

const EmptyResearchState = (
  <div className="flex flex-col text-center  w-full gap-3">
    <div className="flex flex-row w-full justify-center pt-3">
      <img src={EmptyResearch} className="w-20 h-20" alt="empty audience" />
    </div>
    <div className="flex flex-col w-full ">
      <Typography.SubText className="pb-2 font-[600]">No Surveys Yet?</Typography.SubText>
      <Typography.MicroText>Start gathering insights from your </Typography.MicroText>
      <Typography.MicroText>target audience by creating your first survey</Typography.MicroText>
    </div>
    <div className="flex flex-row w-full justify-center">
      <BlueButton text="Create Your First Survey" className="w-auto" />
    </div>
  </div>
);

const EmptyMessageState = (
  <div className="flex flex-col text-center  w-full gap-3">
    <div className="flex flex-row w-full justify-center pt-3">
      <img src={EmptyResearch} className="w-20 h-20" alt="empty audience" />
    </div>
    <div className="flex flex-col w-full ">
      <Typography.SubText className="pb-2 font-[600]">No messaging campaigns yet?</Typography.SubText>
      <Typography.MicroText>Keep your audience engaged by sending </Typography.MicroText>
      <Typography.MicroText>personalised SMS or email campaigns that</Typography.MicroText>
      <Typography.MicroText>that build loyalty and drive actions.</Typography.MicroText>
    </div>
    <div className="flex flex-row w-full justify-center">
      <BlueButton text="Create Your First Campaign" className="w-auto" />
    </div>
  </div>
);

const RecentCampaignsCard = ({
  recentSurveys,
  recentBroadCasts,
  isLoading,
  setActiveTab,
  setShowViewOnlyModal,
  setTableDetailsTapped,
}) => {
  const [step, setStep] = useState(1);
  const { setSideBar, isMobile, setIsMobileSidebarOpen, isMobileSidebarOpen } = useSideBarStore();

  const handleTabChange = (e) => {
    setStep(e);
    setActiveTab(e);
  };

  if (isMobile)
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="flex flex-row space-x-0  w-full justify-between ">
            <Typography.MicroText className={`py-2 border-[0px]  text-center font-[800]`}>
              Market Intelligence
            </Typography.MicroText>
          </div>
          <div className="w-[100%] bg-white border-[1px] rounded-md p-4">
            <div className="flex flex-col space-x-0 p-0 gap-2">
              <div className="w-[100%] border-[1px] rounded-md p-0 overflow-x-scroll">
                {recentSurveys && recentSurveys.length > 0 ? (
                  <Research
                    recentSurveys={recentSurveys}
                    isLoading={isLoading}
                    isMobile={isMobile}
                    setTableDetailsTapped={setTableDetailsTapped}
                  />
                ) : (
                  EmptyResearchState
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-row space-x-0  w-full justify-between ">
            <Typography.MicroText className={`py-2 border-[0px]  text-center font-[800]`}>
              Engagement
            </Typography.MicroText>
          </div>

          <div className="w-[100%] bg-white border-[1px] rounded-md p-4">
            <div className="flex flex-col space-x-0 p-2 gap-2">
              <div className="w-[100%] border-[1px] rounded-md p-0 overflow-x-scroll">
                {recentBroadCasts && recentBroadCasts.length > 0 ? (
                  <Messaging
                    recentBroadCasts={recentBroadCasts}
                    isLoading={isLoading}
                    setTableDetailsTapped={setTableDetailsTapped}
                    isMobile={isMobile}
                  />
                ) : (
                  EmptyMessageState
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="w-[100%] bg-white border-[1px] rounded-md p-4">
      <div className="flex items-center justify-between"></div>

      <div className="flex flex-col space-x-0 p-2 gap-2">
        <div className="flex flex-row space-x-0  w-full justify-between ">
          <div className="flex flex-row  ">
            <div className="">
              <Typography.MicroText
                className={` py-2 cursor-pointer border-[1px] w-32 text-center ${
                  step === 1
                    ? " text-[#0F172A] border-primary-800 font-semibold bg-[#F2F5FE] "
                    : "text-[#6C6685] border-gray-300"
                } `}
                onClick={() => handleTabChange(1)}
              >
                Market Intelligence
              </Typography.MicroText>
            </div>
            <div className="">
              <Typography.MicroText
                className={` py-2 cursor-pointer border-[1px] w-32 text-center ${
                  step === 2
                    ? " text-[#0F172A] border-primary-800 font-semibold bg-[#F2F5FE] "
                    : "text-[#6C6685] border-gray-300"
                } `}
                onClick={() => handleTabChange(2)}
              >
                Engagement
              </Typography.MicroText>
            </div>
          </div>

          <Link to={step === 1 ? `/research/` : "/broadcasts"}>
            <Typography.Text className="py-4 text-[#1D19AF] text-xs cursor-pointer">See all</Typography.Text>
          </Link>
        </div>

        {step === 1 ? (
          <div className="w-[100%] border-[1px] rounded-md p-4">
            {recentSurveys && recentSurveys.length > 0 ? (
              <Research recentSurveys={recentSurveys} isLoading={isLoading} />
            ) : (
              EmptyResearchState
            )}
          </div>
        ) : (
          <div className="w-[100%] border-[1px] rounded-md p-4">
            {recentBroadCasts && recentBroadCasts.length > 0 ? (
              <Messaging recentBroadCasts={recentBroadCasts} isLoading={isLoading} />
            ) : (
              EmptyMessageState
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentCampaignsCard;
