import { Link } from "react-router-dom";
import CalenderMonth from "./components/CalenderMonth";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import WeeklyView from "./components/WeeklyView";
import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";
import { useQuery } from "@tanstack/react-query";
import { getAllHolidayCalendar } from "services/campaignService";
import { getCalenderSurvey } from "services/surveyService";
import { getDateIndex } from "lib/getDateIndex";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import { useStore } from "store";
import ViewOnlyNotificationModal from "components/Modals/ViewOnlyNotificationModal";
import imgageSVG from "assets/images/onboarding.png";
import BlueButton from "components/Button/BlueButton";

const CalenderIndex = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [view, setView] = useState("month");
  const { isMobile } = useSideBarStore();
  const [showViewOnlyModal, setShowViewOnlyModal] = useState(false);
  const { remindMeOnNextVisit, setRemindMeOnNextVisit } = useStore();

  const currentMonth = currentDate.format("MMMM");

  const currentYear = currentDate.format("YYYY");

  const { data: surveyData } = useQuery({
    queryKey: ["allSurveyCalendar", currentYear, currentMonth],
    queryFn: () => getCalenderSurvey(currentYear, getDateIndex(currentMonth)),
  });
  const { data: holidayData } = useQuery({ queryKey: ["allHolidayCalendar"], queryFn: () => getAllHolidayCalendar() });

  useEffect(() => {
    if (remindMeOnNextVisit) {
      setShowViewOnlyModal(false);
    } else {
      setShowViewOnlyModal(true);
    }
  }, []);

  const handleRemindMeOnNextVisit = () => {
    setRemindMeOnNextVisit(true);
    setShowViewOnlyModal(false);
  };

  const toggleView = (value) => {
    setView(value);
  };

  const goToPrevious = () => {
    if (view === "month") {
      setCurrentDate(currentDate.subtract(1, "month"));
    } else {
      setCurrentDate(currentDate.subtract(1, "week"));
    }
  };

  const goToNext = () => {
    if (view === "month") {
      setCurrentDate(currentDate.add(1, "month"));
    } else {
      setCurrentDate(currentDate.add(1, "week"));
    }
  };

  const getMonthAndYear = (date) => {
    return isMobile ? date.format("MMM YYYY") : date.format("MMMM YYYY");
  };

  const getWeekRange = (date) => {
    const startOfWeek = isMobile ? date.startOf("week").format("D MMM") : date.startOf("week").format("D MMMM");
    const endOfWeek = isMobile ? date.endOf("week").format("D MMM, YYYY") : date.endOf("week").format("D MMMM, YYYY");
    return `${startOfWeek} - ${endOfWeek}`;
  };

  // const sortHoliday = useCallback(() => {
  //   const evt = holidayData?.filter((elm) => dayjs(elm.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"));
  //   setEvent(evt);
  // }, [day, holidayData]);

  return (
    <div className="flex flex-col items-center w-[100%] bg-white p-4 rounded-md border">
      <div className="flex items-center justify-between w-[100%] my-6 flex-row ">
        <div className="flex items-center justify-between sm:space-x-5 space-x-1">
          <div className="flex items-center space-x-4">
            <div
              onClick={goToPrevious}
              className="border-[1px] rounded hover:bg-primary-50 h-[25px] w-[25px] flex items-center justify-center text-lg cursor-pointer"
            >
              <HiMiniChevronLeft />
            </div>
            <div
              onClick={goToNext}
              className="border-[1px] rounded hover:bg-primary-50 h-[25px] w-[25px] flex items-center justify-center text-lg cursor-pointer"
            >
              <HiMiniChevronRight />
            </div>
          </div>

          <div className="text-lg font-semibold ">
            {view === "month" ? (
              isMobile ? (
                <h6 className="text-[12px]">{getMonthAndYear(currentDate)}</h6>
              ) : (
                <h2 className="">{getMonthAndYear(currentDate)}</h2>
              )
            ) : isMobile ? (
              <h6 className="text-[12px]">{getWeekRange(currentDate)}</h6>
            ) : (
              <h2 className="">{getWeekRange(currentDate)}</h2>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between border-[1px] rounded-md text-sm border-gray-300 text-gray-600 overflow-hidden">
          {/*<p*/}
          {/*  className={`border-r-[1px] p-2 w-[70px] flex items-center justify-center border-gray-300 cursor-pointer ${*/}
          {/*    view === "day" ? "bg-primary-200 text-primary-800 border-[1px] border-primary-800" : ""*/}
          {/*  }`}*/}
          {/*  onClick={() => toggleView("day")}*/}
          {/*>*/}
          {/*  Day*/}
          {/*</p>*/}
          <p
            className={`border-r-[1px] p-2 w-[70px] flex items-center justify-center border-gray-300 cursor-pointer ${
              view === "week" ? "bg-primary-100 text-primary-800 border-[1px] border-primary-800" : ""
            }`}
            onClick={() => toggleView("week")}
          >
            Week
          </p>
          <p
            className={`p-2 w-[70px] flex items-center justify-center cursor-pointer ${
              view === "month" ? "bg-primary-100 text-primary-800 border-[1px] border-primary-800" : ""
            }`}
            onClick={() => toggleView("month")}
          >
            Month
          </p>
        </div>
      </div>

      <div className="w-[100%] overflow-x-auto">
        {view === "month" ? (
          <CalenderMonth currentDate={currentDate} holidayData={holidayData} surveyData={surveyData} />
        ) : (
          <WeeklyView currentDate={currentDate} />
        )}
      </div>

      {isMobile && showViewOnlyModal && (
        <ViewOnlyNotificationModal
          setShowViewOnlyModal={setShowViewOnlyModal}
          img={imgageSVG}
          handleRemindMeOnNextVisit={handleRemindMeOnNextVisit}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="text-[16px] font-[600]">View-only feature</div>
            <div className="text-[12px]">This feature is designed for larger screens to</div>
            <div className="text-[12px]">provide the best experience.To access,please</div>
            <div className="text-[12px]">switch to a laptop or desktop.</div>
          </div>
          <div className="flex flex-col gap-5 items-center justify-center  p-2 w-full">
            <BlueButton text={"Continue"} onClick={setShowViewOnlyModal} loading={false} className="w-full" />
            <div>
              <Link
                to={""}
                className="text-sm text-primary-900 underline font-[700]"
                onClick={() => handleRemindMeOnNextVisit(true)}
              >
                Remind me on next visit
              </Link>
            </div>
          </div>
        </ViewOnlyNotificationModal>
      )}

      {/* <Calendar/> */}
    </div>
  );
};

export default CalenderIndex;
