import PrimaryButton from "components/Button/PrimaryButton";
import React, { useEffect, useMemo, useState } from "react";
import BroadcastImage from "assets/images/broadcastImg.png";
import SmsModal from "./tabs/SmsModal";
import SmsPreview from "./tabs/TemplatePreview";
import { useQuery } from "@tanstack/react-query";
import Drafts from "./tableTabs/Drafts";
import Scheduled from "./tableTabs/Scheduled";
import Sent from "./tableTabs/Sent";
import { SmsIcon } from "assets/svgs/SmsIcon";
import { useBroadcastStore } from "store/broadcastStore/broadcastState";
import { getAllSmsCampaigns } from "services/campaignService";
import { useSearchParams } from "react-router-dom";
import IframeCard from "components/Videocard/IframeCard";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const Sms = () => {
  const [openModal, setOpenModal] = useState(null);
  const [campCurrentPage, setCampCurrentPage] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  const { smsTabs, setSmsTabs } = useBroadcastStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useSideBarStore();
  localStorage.removeItem("tagData");
  localStorage.removeItem("segmentData");

  const {
    data: allSms,
    isLoading: load,
    isFetching: fetch,
  } = useQuery({
    queryKey: ["allSmsCampaignsData", campCurrentPage],
    queryFn: () => getAllSmsCampaigns(campCurrentPage),
  });

  useEffect(() => {
    setSmsTabs({
      draft: allSms?.draft?.totalCount,
      scheduled: allSms?.scheduled?.totalCount,
      sent: allSms?.sent?.totalCount,
    });
  }, [allSms?.draft?.totalCount, allSms?.scheduled?.totalCount, allSms?.sent?.totalCount, setSmsTabs]);

  const tab = [
    { name: "Sent", value: 0, icon: SmsIcon, count: smsTabs?.sent },
    { name: "Drafts", value: 1, icon: SmsIcon, count: smsTabs?.draft },
    { name: "Scheduled", value: 2, icon: SmsIcon, count: smsTabs?.scheduled },
  ];

  const switchTab = (value) => {
    setTabValue(value);
    setSearchParams({ tab: value });
  };

  useEffect(() => {
    const navValue = parseInt(searchParams.get("tab") || "0");
    setTabValue(navValue);
  }, [searchParams]);

  const total =
    tabValue === 0
      ? allSms?.sent?.totalCount
      : tabValue === 1
        ? allSms?.draft?.totalCount
        : tabValue === 2
          ? allSms?.scheduled?.totalCount
          : 0;

  const [pageCountStart, pageCountEnd] = useMemo(() => {
    const totalValue = total;

    let startValue = (campCurrentPage - 1) * 20;
    startValue = startValue + 1;

    let endValue = Math.min(campCurrentPage * 20, totalValue);
    return [startValue, endValue];
  }, [campCurrentPage, total]);

  const handlePrev = () => {
    setCampCurrentPage(campCurrentPage - 1);
  };

  const handleNext = () => {
    setCampCurrentPage(campCurrentPage + 1);
  };

  const displayTab = () => {
    switch (tabValue) {
      case 0:
        return (
          <Sent
            data={allSms?.sent}
            isFetching={fetch}
            isLoading={load}
            pageCountStart={pageCountStart}
            pageCountEnd={pageCountEnd}
            currentPage={campCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <div>
            <Drafts
              data={allSms?.draft}
              isFetching={fetch}
              isLoading={load}
              pageCountStart={pageCountStart}
              pageCountEnd={pageCountEnd}
              currentPage={campCurrentPage}
              handlePrev={handlePrev}
              handleNext={handleNext}
            />
          </div>
        );
      case 2:
        return (
          <Scheduled
            data={allSms?.scheduled}
            isFetching={fetch}
            isLoading={load}
            pageCountStart={pageCountStart}
            pageCountEnd={pageCountEnd}
            currentPage={campCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      default:
    }
  };

  const toggleModal = (id) => {
    setOpenModal(!openModal === id ? null : id);
  };

  const availableData =
    allSms?.draft?.totalCount !== 0 || allSms?.scheduled?.totalCount !== 0 || allSms?.sent?.totalCount !== 0
      ? false
      : true;

  return (
    <div className="border rounded-md bg-white p-5 overflow-y-auto h-[calc(100vh-80px)]">
      {!availableData ? (
        <div className="">
          {!isMobile && (
            <div
              className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 cursor-pointer my-5"
              onClick={() => toggleModal(1)}
            >
              <p className="text-sm font-medium text-white">Create new SMS</p>
            </div>
          )}

          <div className="sm:grid sm:grid-cols-3 sm:gap-x-[3em] flex gap-x-[30px] w-[100%] overflow-x-scroll no-scrollbar">
            {tab.map((el, i) => {
              const IconComponent = el.icon;
              return (
                <div
                  key={i}
                  className={`${
                    tabValue === el.value ? "bg-[#F2F5FE] border-primary-600" : ""
                  } border cursor-pointer border-b-0 p-4 rounded-t-md`}
                  onClick={() => switchTab(el.value)}
                >
                  <div className="flex items-center space-x-3">
                    <IconComponent
                      fill1={tabValue === el.value ? "#95ADFF" : "#D4D4D4"}
                      fill2={tabValue === el.value ? "#1D19AF" : "#404040"}
                    />
                    <div className="flex items-center space-x-5">
                      <p className={`${tabValue === el.value ? "font-[600] text-primary-800" : ""}`}>{el.name}</p>
                      <div
                        className={`w-[24px] h-[24px] rounded flex items-center justify-center bg-gray-200 text-gray-500 ${
                          tabValue === el.value ? "font-[700] text-primary-800 bg-primary-200" : ""
                        }`}
                      >
                        {load || fetch ? 0 : <p>{el.count}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="bg-white h-full">{displayTab()}</div>
        </div>
      ) : (
        <div className="w-full h-[230px] border-[1px] rounded-xl flex items-center overflow-hidden">
          <div className="flex flex-col space-y-3 p-6 w-[50%]">
            <p className="bg-primary-100 text-primary-800 w-[100px] flex  justify-center items-center text-xs rounded-xl py-1 font-semibold">
              Messaging
            </p>

            <p className="text-2xl font-medium text-primary-800">Send out SMS</p>
            <p className="text-sm w-[75%]">
              Let your audience hear from you. Share messages with your uploaded contacts to expand your reach.
            </p>

            <PrimaryButton
              text="Get Started"
              css="bg-primary-800 text-white h-[40px] text-xs w-[150px]"
              onClick={() => toggleModal(1)}
            />
          </div>
          <div className="w-[50%] min-h-full">
            <IframeCard videoUrl="https://youtu.be/KQcQ64v1gFs?si=r7W3Z2ORKFw6saQZ" />
          </div>

          {/* <img src={BroadcastImage} alt="BroadcastImage" className="max-h-[150px]" /> */}
        </div>
      )}

      {openModal === 1 ? <SmsModal handleModal={() => toggleModal(3)} onClose={() => toggleModal()} /> : null}
      {openModal === 3 ? <SmsPreview handleModal={() => toggleModal(1)} /> : null}
    </div>
  );
};

export default Sms;
