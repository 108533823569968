import React, { useState, useEffect } from "react";
import Typography from "utils/typography";
import CPInsight from "./components/CPInsight";
import NPSInsight from "./components/NPSInsight";
import CSATInsight from "./components/CSATInsight";
import { useInsightOverview } from "hooks/useInsightOverview";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import completionRate from "assets/images/completionRate.png";
import docIcon from "assets/images/docIcon.png";
import detractors from "assets/images/detractors.png";
import BlueButton from "../../../../components/Button/BlueButton";
import EmptyInsights from "../../../../assets/svgs/emptyInsights.svg";

const tabs = [
  { id: 1, name: "Profiles" },
  { id: 2, name: "NPS" },
  { id: 3, name: "CSAT" },
];
const InsightCard = () => {
  const [step, setStep] = useState(1);
  //const [onlineSummary, setOnlineSummary] = useState([]);
  const [npsOnlineSummary, setNPSOnlineSummary] = useState([]);
  const [csatOnlineSummary, setCSATOnlineSummary] = useState([]);

  const { data: cpData, isLoading: cpIsLoading } = useInsightOverview("cp");
  const { data: npsData, isLoading: npsIsLoading } = useInsightOverview("nps");
  const { data: csatData, isLoading: csatIsLoading } = useInsightOverview("csat");

  useEffect(() => {
    if (npsData) {
      const summaryData = npsData?.totalNpsMetrics;
      setNPSOnlineSummary([
        {
          id: "npsScore",
          name: "NPS score",
          image: totalScore,
          value: summaryData?.npsScore ?? 0,
          color: "text-primary-800",
        },
        {
          id: "promoter",
          name: "Promoters",
          image: promoters,
          value: summaryData?.promoter ?? 0,
          color: "text-green-500",
        },
        {
          id: "passive",
          name: "Passives",
          image: passives,
          value: summaryData?.passive ?? 0,
          color: "text-yellow-500",
        },
        {
          id: "detractor",
          name: "Detractors",
          image: detractors,
          value: summaryData?.detractor ?? 0,
          color: "text-red-500",
        },
      ]);
    }
  }, [npsData]);

  useEffect(() => {
    if (csatData) {
      const summaryData = csatData?.totalCsatMetrics;
      setCSATOnlineSummary([
        {
          id: "onlineSummary1",
          name: "CSAT score",
          image: totalScore,
          value: summaryData?.csatScore ?? 0,
        },
        {
          id: "onlineSummary2",
          name: "Satisfied",
          image: promoters,
          value: summaryData?.satisfied ?? 0,
        },
        {
          id: "onlineSummary3",
          name: "Neutral",
          image: passives,
          value: summaryData?.neutral ?? 0,
        },
        {
          id: "onlineSummary3",
          name: "Unsatisfied",
          image: detractors,
          value: summaryData?.unsatisfied ?? 0,
          color: "text-red-500",
        },
      ]);
    }
  }, [csatData]);

  const handleTabChange = (e) => {
    setStep(e);
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <CPInsight nameKey="customerProfile" overViewData={{ cpData }} />;
      case 2:
        return <NPSInsight overViewData={npsOnlineSummary} graphData={npsData?.npsHistory} isLoading={npsIsLoading} />;
      case 3:
        return (
          <CSATInsight overViewData={csatOnlineSummary} graphData={csatData?.csatHistory} isLoading={csatIsLoading} />
        );

      default:
    }
  };

  const EmptyState = (
    <div className="flex flex-col text-center  w-full gap-3">
      <div className="flex flex-row w-full justify-center pt-3">
        <img src={EmptyInsights} className="w-20 h-20" alt="empty audience" />
      </div>
      <div className="flex flex-col w-full ">
        <Typography.SubText className="pb-2 font-[600]">No Insights Yet?</Typography.SubText>
        <Typography.MicroText>Your insights will appear here once you start </Typography.MicroText>
        <Typography.MicroText> collecting responses.Create a survey to uncover</Typography.MicroText>
        <Typography.MicroText>the data that matters most to your business.</Typography.MicroText>
      </div>
      <div className="flex flex-row w-full justify-center">
        <BlueButton text="Create Your First Survey" className="w-auto" />
      </div>
    </div>
  );

  return (
    <div className="flex w-[100%] bg-white rounded-md  h-full ">
      {cpData && npsData && csatData ? (
        <div className="flex flex-col w-[100%] gap-5 py-5">
          <div className="flex space-x-0  w-full items-center justify-center">
            {tabs.map((el) => {
              return (
                <Typography.MicroText
                  className={` p-[4px] cursor-pointer border-[1px] w-32 text-center ${
                    step === el.id
                      ? " text-[#0F172A] border-primary-800 font-semibold bg-[#F2F5FE]"
                      : "text-[#6C6685] border-gray-300"
                  } `}
                  onClick={el.id !== 4 ? () => handleTabChange(el.id) : null}
                  key={el.id}
                >
                  {el.name}
                </Typography.MicroText>
              );
            })}
          </div>
          <div>{displayStep()}</div>
        </div>
      ) : (
        EmptyState
      )}
    </div>
  );
};

export default InsightCard;
