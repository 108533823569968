import mixpanel from "mixpanel-browser";

const MIXPANEL_TOKEN =
  process.env.REACT_APP_NODE_ENV !== "production"
    ? process.env.REACT_APP_MIXPANEL_STAGING
    : process.env.REACT_APP_MIXPANEL_LIVE;

const initMixpanel = () => {
  if (!MIXPANEL_TOKEN) {
    console.error("Mixpanel token not found. Please set the MIXPANEL_TOKEN environment variable.");
    return;
  }

  console.log("Initializing Mixpanel with Token:", MIXPANEL_TOKEN);

  try {
    mixpanel.init(MIXPANEL_TOKEN, { debug: true, disable_all_events: false });
    console.log("Mixpanel successfully initialized.");
  } catch (error) {
    console.error("Mixpanel initialization failed:", error);
  }
};

const identifyUser = (userId, additionalProperties = {}) => {
  if (!userId) {
    console.warn("User ID not provided for Mixpanel identify.");
    return;
  }

  mixpanel.identify(userId);
  mixpanel.people.set(additionalProperties);
};

const trackEvent = (eventName, properties = {}) => {
  if (!mixpanel.config) {
    console.error("Mixpanel is not initialized.");
    return;
  }

  try {
    mixpanel.track(eventName, properties);
  } catch (error) {
    console.error(`Failed to track event '${eventName}':`, error);
  }
};

export { initMixpanel, identifyUser, trackEvent };
