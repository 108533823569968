import { useQuery } from "@tanstack/react-query";
import useLoggedInUser from "hooks/useLoggedInUser";
import usePlan from "hooks/usePlan";
import { useEffect, useState } from "react";
import { getWalletBalance, viewWalletLedger } from "services/walletService";
import { getAccountLegder } from "services/userService";
import { useStore } from "store";
import MyAccount from "./MyAccount";
import Company from "./Company";
import { useSearchParams } from "react-router-dom";
import Workspaces from "./WorkSpaces";
import PlanAndBilling from "./PlanAndBillings";
import ComplianceTab from "./ComplianceTab";
import { trackEvent } from "config/mixpanel";
import dayjs from "dayjs";

const tabs = [
  { name: "Personal", value: 0 },
  { name: "Company", value: 1 },
  { name: "Compliance and Verification", value: 2 },
  { name: "Billing and Usage", value: 3 },
  { name: "Workspaces", value: 4 },
  // { name: "Notification", value: 3 },
];

const Profile = () => {
  const [step, setStep] = useState(0);
  const { plan } = usePlan();
  const { user } = useLoggedInUser();
  const { setTotalDoc } = useStore();
  const [accLedgerBatch, setAccLedgerBatch] = useState(1);
  const { data } = useQuery({ queryKey: ["viewWalletLedger"], queryFn: viewWalletLedger });
  const { data: accLedger, isLoading: accLoading } = useQuery({
    queryKey: ["viewAccLedger", accLedgerBatch],
    queryFn: () => getAccountLegder(accLedgerBatch),
  });
  const [searchBarParams, setSearchBarParams] = useSearchParams();
  const { data: walletData, isLoading } = useQuery({ queryKey: ["walletBalance"], queryFn: getWalletBalance });

  useEffect(() => {
    setTotalDoc(accLedger?.totalDocuments);
  }, [accLedger?.totalDocuments, setTotalDoc]);

  useEffect(() => {
    if (searchBarParams.get("g") === "compliance") {
      setStep(1);
    }
  }, [searchBarParams]);

  useEffect(() => {
    if (searchBarParams.get("from") === "dashboard") {
      setStep(2);
    }
  }, [searchBarParams]);

  const displayStep = () => {
    switch (step) {
      case 0:
        return <MyAccount user={user} />;
      case 1:
        return <Company user={user} />;
      case 2:
        return <ComplianceTab user={user} />;
      case 3:
        trackEvent("check_spend", {
          email: user?.data?.email,
          date: dayjs().format(),
        });

        return (
          <PlanAndBilling
            plan={plan}
            ledger={data.result}
            totalCount={data.totalCount}
            walletData={walletData}
            isLoading={isLoading}
            accLedger={accLedger}
            setAccLedgerBatch={setAccLedgerBatch}
            accLedgerBatch={accLedgerBatch}
            accLoading={accLoading}
          />
        );
      case 4:
        return <Workspaces />;
      default:
    }
  };

  const handleTabChange = (e) => {
    setStep(e);
    setSearchBarParams("");
  };

  return (
    <div>
      {/* <p className="font-[800] text-[28px]">Profile</p> */}

      <div className="flex space-x-4 mt-4 overflow-auto whitespace-nowrap no-scrollbar">
        {tabs.map((el) => {
          return (
            <p
              className={` pb-[4px] px-2 cursor-pointer ${
                step === el.value ? "border-b-[2px] text-[#0F172A] border-purple font-semibold " : "text-[#6C6685]"
              } `}
              onClick={() => handleTabChange(el.value)}
              key={el.value}
            >
              {el.name}
            </p>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>
    </div>
  );
};

export default Profile;
