import Modal from "components/Modal";
import { surveyTemplates, fieldSurveyTemplate } from "data/surveyTemplates";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { useStore } from "store";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import useSurveyStore from "store/insightStore";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { FileIcon } from "assets/icons/survey/FileIcon";
import CopyAndPasteModal from "./CopyAndPasteModal";

const CreateSurveyModal = ({ handleModal, handleTemplatePreview }) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const templateOption = path.includes("field-interview") ? fieldSurveyTemplate : surveyTemplates;

  const { user } = useLoggedInUser();
  const { setSurveyTemplate } = useStore();
  const { surveyQuestionsArray, setSurveyQuestionsArray } = useSurveyStore();
  const retrieveTemplate = (id) => {
    const foundTemplate = templateOption.find((el) => el.id === id);

    setSurveyTemplate(foundTemplate);
    handleModal(3);

    trackEvent("select_template", {
      email: user?.data?.email,
      templateName: foundTemplate?.surveyName,
      date: dayjs().format(),
      surveyType: "Online survey",
    });
  };

  const handlePreview = (id) => {
    const foundTemplate = templateOption?.find((el) => el.id === id);
    setSurveyQuestionsArray(foundTemplate?.questions);
    setSurveyTemplate(foundTemplate);
    handleTemplatePreview(foundTemplate.id);
  };

  console.log(surveyQuestionsArray);

  const handleBlankSurvey = () => {
    handleModal(3);
    setSurveyTemplate({
      surveyName: "",
      surveyCategory: "",
      surveyDescription: "",
      coverImage: "",
      reward: 0,
      campaignType: "",
      bg: "bg-bgOne",
      questions: [],
    });
  };

  const handleCopyPasteModal = () => {
    handleModal("CopyAndPaste");
  };

  return (
    <>
      <Modal title="Create a survey" onClose={() => handleModal(2)}>
        <div className="w-[870px] px-5">
          <p className="">Start survey from scratch or choose from our available templates to begin.</p>

          <div className="mt-[1.5em] grid grid-cols-3 gap-[1em] max-h-[60vh] overflow-y-scroll">
            {templateOption?.map((el, i) => {
              if (el.id === 0) {
                return (
                  <div
                    className="bg-[#FAFAFA] rounded-md w-[260px] h-[250px] text-center flex flex-col justify-center items-center space-y-3 cursor-pointer"
                    key={`templates${el.id}`}
                    onClick={handleBlankSurvey}
                  >
                    <div className="border-[1px] border-dashed rounded-md border-black w-[100px] h-[100px] flex items-center justify-center mx-auto bg-white mb-3">
                      <div className="bg-primary-50 w-[54px] h-[54px] flex items-center justify-center rounded-full text-[24px]">
                        <FaPlus className="text-primary-800" />
                      </div>
                    </div>

                    <p className="text-m font-medium">Blank template</p>
                    <p className="text-ss w-[70%]">Create your survey from the ground up.</p>
                  </div>
                );
              } else if (el.id === 100) {
                return (
                  <div
                    className="bg-[#FAFAFA] rounded-md w-[260px] h-[250px] text-center flex flex-col justify-center items-center space-y-3 cursor-pointer"
                    key={`templates${el.id}`}
                    onClick={handleCopyPasteModal}
                  >
                    <div className="border-[1px] border-dashed rounded-md border-black w-[100px] h-[100px] flex items-center justify-center mx-auto bg-white mb-3">
                      <div className="bg-primary-50 w-[54px] h-[54px] flex items-center justify-center rounded-full text-[24px]">
                        <FileIcon />
                      </div>
                    </div>

                    <p className="text-m font-medium">Paste questions</p>
                    <p className="text-ss w-[80%]">Copy and paste your questions from an already existing document.</p>
                  </div>
                );
              } else {
                return (
                  <div className={`w-[260px] shadow-md overflow-hidden rounded-md h-[250px]`} key={`temp${i}`}>
                    <div className="w-[100%] h-[88px] overflow-hidden">
                      <img src={el.coverImage} alt="lol" className="w-[100%] h-[100%] object contain" />
                    </div>

                    <div className="my-[0.7em] rounded-md p-2">
                      <p className="text-[14px] font-semibold">{el.surveyName}</p>

                      <p className="text-[12px] pt-1">
                        {el.surveySubTitle.length > 75 ? `${el.surveySubTitle.slice(0, 75)}...` : el.surveySubTitle}
                        {/* {el.surveyDescription.length > 75 ? "..." : ""} */}
                      </p>
                    </div>
                    <div className="my-3">
                      <hr />
                    </div>
                    <div className="flex items-center justify-center space-x-5 text-[14px]">
                      <div onClick={() => handlePreview(el.id)} className="cursor-pointer">
                        <p className="text-primary-800 font-[600]">Preview</p>
                      </div>
                      <div>
                        <p
                          className="px-5 py-2 bg-primary-100 rounded-[4px] flex items-center justify-center text-primary-800 font-[600] cursor-pointer"
                          onClick={() => retrieveTemplate(el.id)}
                        >
                          Use template
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateSurveyModal;
