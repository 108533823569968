import React from "react";
import OverviewCard from "../components/OverviewCard";
import overviewDoc from "assets/images/broadcast/overviewIcons/overviewSmsTotal.png";
import overviewSmsFailed from "assets/images/broadcast/overviewIcons/overviewSmsFailed.png";
import overviewClicked from "assets/images/broadcast/overviewIcons/overviewClicked.png";
import { useQuery } from "@tanstack/react-query";
import { getCampOverview } from "services/campaignService";

const SmsTab = () => {
  const { data, isLoading } = useQuery({ queryKey: ["smsOverview"], queryFn: () => getCampOverview("sms") });
  const campValue = data?.data?.sms;
  const emailSUmmary = [
    { id: 1, name: "Total sent", image: overviewDoc, value: campValue?.total_sent },
    { id: 2, name: "Clicked", image: overviewClicked, value: campValue?.clicked },
    { id: 3, name: "Failed", image: overviewSmsFailed, value: campValue?.failed },
  ];

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between sm:space-x-4 sm:flex-row flex-col sm:gap-0 gap-2">
        {emailSUmmary.map((elm) => (
          <OverviewCard key={elm.id} elm={elm} loading={isLoading} />
        ))}
      </div>
    </div>
  );
};

export default SmsTab;
