import HalfDoughnutChart from "../../../../Insight/components/analyticsTab/metricsCard/HalfPieChart";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

const CSATInsight = ({ isLoading, overViewData, graphData }) => {
  const cleanCSATData = graphData
    ? graphData?.map((elm) => ({
        Neutral: elm.neutral,
        Satisfied: elm.satisfied,
        Unsatisfied: elm.unsatisfied,
        Date: dayjs(elm.publishDate).format("DD/MM/YYYY"),
      }))
    : null;

  const pieChartCSATData = {
    Satisfied: overViewData[1]?.value,
    Neutral: overViewData[2]?.value,
    Unsatisfied: overViewData[3]?.value,
  };
  const pieChartData = Object.values(pieChartCSATData);
  const piecChartLabels = Object.keys(pieChartCSATData);

  return (
    <div className="flex flex-col items-center w-[100%] bg-white p-4 border-[0px] rounded-lg space-y-[3em]">
      <div className="w-[100%] h-[130px] flex flex-col space-y-4">
        <div className="mx-auto relative">
          {/* <PieChart className={"text-primary-800"} value={isLoading ? 0 : overViewData[0]?.value} /> */}
          <HalfDoughnutChart data={pieChartData} labels={piecChartLabels} />

          <div className=" w-full flex flex-col items-center">
            {isLoading ? (
              <Skeleton count={1} className="w-[40%]" />
            ) : (
              <Typography.Headers>{overViewData[0]?.value ?? 0}</Typography.Headers>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSATInsight;
