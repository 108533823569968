import { useMutation, useQuery } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import GreyButton from "components/Button/GreyButton";
import ModalWithoutClose from "components/ModalWithoutClose";
import { SurveyStatuses } from "data/constants/surveyConstants";
import { formatDateWithDay } from "lib/formatDate";
import { survStatus } from "lib/survStatus";
import React from "react";
import { BsDot } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getSingleSurvey } from "services/surveyService";
import Typography from "utils/typography";
import EmailBlue from "assets/images/BlueEmail.svg";
import EmailYellow from "assets/images/YellowEmail.svg";
import { deleteSurvey } from "services/newSurveyService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import PreLoader from "components/Loader/PreLoader";

const DetailsComponents = () => {
  const [params, setParams] = useSearchParams();

  const surveyId = params.get("id");

  const { data, isLoading } = useQuery({
    queryKey: ["getSingleSurvey", surveyId],
    queryFn: () => getSingleSurvey(surveyId),
    onSuccess: (data) => {},
    enabled: !!surveyId,
  });

  const { mutate: mutateDeleteSurvey, isLoading: isDeletingSurvey } = useMutation({
    mutationFn: deleteSurvey,
    onSuccess: () => {
      Toast.success("Survey removed from contact successfully");
      closeModal();
      queryClient.invalidateQueries({
        queryKey: [
          "onlineSurveyActive",
          "onlineSurveyDraft",
          "onlineSurveyClosed",
          "onlineSurveyScheduled",
          "brandTrackingActive",
          "brandTrackingDraft",
          "brandTrackingClosed",
          "brandTrackingScheduled",
          "offline_surveyActive",
          "offline_surveyDraft",
          "offline_surveyClosed",
          "offline_surveyScheduled",
        ],
      });
      queryClient.invalidateQueries({ queryKey: ["onBoarding"] });

      queryClient.invalidateQueries({ queryKey: ["allSurveyCalendar"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDeleteSurvey = () => {
    mutateDeleteSurvey({
      surveyIds: [surveyId],
    });
  };

  const closeModal = () => {
    params.delete("id");
    setParams(params);
  };

  console.log(data);
  const navigate = useNavigate();

  const handleSingleReport = () => {
    switch (data?.surveyDetails?.surveyType) {
      case "onlineSurvey":
        if (data?.surveyStatus === SurveyStatuses.DRAFT) {
          navigate(`/research/online-survey/${data?._id}`);
        } else {
          navigate(`/research/online-survey/analytics/${data?.publish?.publishId}/${data?._id}`, { state: data });
        }
        break;

      case "brandTracking":
        if (data?.surveyStatus === SurveyStatuses.DRAFT) {
          navigate(`/research/brand-tracking/${data?._id}`);
        } else {
          navigate(`/research/brand-tracking/analytics/${data?.publish?.publishId}/${data?._id}`, { state: data });
        }
        break;

      case "fieldInterview":
        if (data?.surveyStatus === SurveyStatuses.DRAFT) {
          navigate(`/research/field-interview/${data?._id}`);
        } else {
          navigate(`/research/field-interview/analytics/${data?.publish?.publishId}/${data?._id}`, { state: data });
        }
        break;
      default:
    }
  };

  if (isDeletingSurvey) {
    return <PreLoader />;
  }

  return (
    <ModalWithoutClose>
      <div className="sm:w-[600px] h-[70vh] bg-white flex justify-between flex-col">
        <div className="bg-primary-50 h-[130px]">
          <div className="bg-sentBg w-[100%] h-[100%] bg-repeat-x bg-contain flex items-center justify-between p-8">
            <p></p>

            <IoCloseCircle className="text-gray-500 text-2xl cursor-pointer" onClick={closeModal} />
          </div>
        </div>

        {/* <p>{data?.surveyDetails?.surveyType}</p> */}

        {isLoading ? (
          <div className="p-4 h-[calc(70vh-130px-70px)]">
            <Skeleton count={1} height={100} />
            <div className="mt-[1em]">
              <Skeleton count={3} className="w-[70%]" />
            </div>

            <Skeleton count={1} height={50} className="w-[50%] my-4" />

            <Skeleton count={1} height={20} className="w-[40%] my-4" />
          </div>
        ) : (
          <div className="p-4 h-[calc(70vh-130px-70px)]">
            <div className="space-y-6 ">
              <div className="flex items-start space-x-4">
                <img
                  src={data?.surveyStatus === "active" || data?.surveyStatus === "closed" ? EmailBlue : EmailYellow}
                  alt="EmailBlue"
                  className="w-[40px]"
                />

                <div>
                  <div className="flex items-center space-x-3">
                    <Typography.H3>{data?.surveyDetails?.surveyName}</Typography.H3>
                    <p>{survStatus(data?.surveyStatus)}</p>
                  </div>

                  <div className="flex  items-center space-x-2">
                    <Typography.SubText>{data?.surveyDetails?.surveyCategory}</Typography.SubText>

                    <BsDot />

                    <Typography.SubText>{formatDateWithDay(data?.createdAt)}</Typography.SubText>
                  </div>
                </div>
              </div>

              <div>
                <Typography.Text className="font-medium">Audience</Typography.Text>

                <div className="flex items-center space-x-3 my-2 flew-wrap">
                  {data?.targetingOption?.contactTag?.map((el) => (
                    <div className="bg-gray-200 px-6 py-2 rounded" key={el}>
                      <p className="text-xs text-gray-700">{el}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <Typography.SubText>Unit spent</Typography.SubText>
                <Typography.H3>{data?.cost}</Typography.H3>
              </div>
            </div>
          </div>
        )}

        {isLoading ? (
          <Skeleton count={1} className="w-[100%] h-[70px]" />
        ) : (
          <div className="border-t-[1px] h-[70px] flex items-center justify-between sm:px-[2em]">
            <div></div>

            <div className="flex items-center space-x-3 sm:w-auto w-full  justify-center">
              {data?.surveyStatus === SurveyStatuses.DRAFT ? (
                <div className="flex items-center space-x-3">
                  <Link to="">
                    <GreyButton text="Delete" className="w-[150px]" onClick={handleDeleteSurvey} />
                  </Link>

                  <BlueButton text="Edit" className="w-[150px]" onClick={handleSingleReport} />
                </div>
              ) : (
                <BlueButton text="View Report" onClick={handleSingleReport} />
              )}
            </div>
          </div>
        )}
      </div>
    </ModalWithoutClose>
  );
};

export default DetailsComponents;
