import { useMutation, useQuery } from "@tanstack/react-query";
import Flag from "assets/images/flag.svg";
import SecondaryInput from "components/Inputs/SecondaryInput";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateUser } from "services/userService";
import { uploadImage } from "utils/upload";
import Password from "./Password";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "services/userService";
import BlueButton from "components/Button/BlueButton";
import { useState } from "react";
import DeleteModal from "./DeleteModal";
import ScaleLoader from "react-spinners/ScaleLoader";
import SecondarySelectInput from "components/Inputs/SecondarySelectInput";
import { roleData } from "./data";
import { removeToken } from "helpers/authHelper";
import { removeWorkspaceToken } from "helpers/authHelper";
import { trackEvent } from "config/mixpanel";
import dayjs from "dayjs";

const MyAccount = ({ user }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [loadingImage, setLoadingImage] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
  };

  const { mutate, isFetching: fetchingImage } = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      Toast.success("account information updated successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
  });

  const { mutate: UploadImage, isLoading: loadingImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: () => {
      Toast.success("Profile image updated successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { refetch } = useQuery({
    queryKey: ["delete"],
    queryFn: deleteAccount,
    enabled: false,
    onSuccess: () => {
      Toast.success("Account deleted successfully");
      removeToken();
      removeWorkspaceToken();
      navigate("/auth/register");
      toggleModal();
      trackEvent("deactivate_workspace", {
        email: user?.data?.email,
        date: dayjs().format(),
      });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleDelete = () => {
    refetch();
  };

  const initialValues = {
    firstname: user?.data?.firstname || "",
    lastname: user?.data?.lastname || "",
    businessname: user?.data?.business?.businessName || "",
    email: user?.data?.email || "",
    phone: user?.data?.phone || "",
    userRole: user?.data?.userRole || "",
  };

  const onSubmit = async (data) => {
    const userData = {
      ...data,
    };
    mutate(userData);
  };

  const validationSchema = Yup.object({
    email: Yup.string("Your emails will be sent from this address")
      .email("Enter a valid email address")
      .required("Please fill in this field"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Please fill in this field"),
  });

  const { handleChange, values, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const getError = (key) => {
    return touched[key] && errors[key];
  };

  const handleRoleUpdate = () => {
    mutate(values);
  };

  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      console.error("Please select only one image");
    } else {
      let formData = new FormData();
      formData.append("profile", e.target.files[0]);
      UploadImage(formData);
    }
  };

  return (
    <div className="bg-white rounded-md p-5">
      <div>
        <p className="text-[#292D32] font-semibold text-[20px]">Details</p>
        <p className="text-[#737373] text-s font-[450] pb-2 pt-2">View and update your account details</p>
        <hr />

        <div className="flex items-center space-x-[30px] mt-6">
          <div className="w-[96px] h-[96px] bg-[#D7D7D7] rounded-full flex items-center justify-center overflow-hidden border-[1px]">
            {loadingImage || fetchingImage ? (
              <div className="bg-white flex justify-center items-center w-full h-[100vh]">
                <ScaleLoader height="30px" width={2} margin={2} color="#29085C" />
              </div>
            ) : (
              <>
                {!user?.data?.profile_image.includes("https://") ? (
                  <img src={`https://${user?.data?.profile_image}`} alt="" className="w-[100%] h-[100%] object-cover" />
                ) : (
                  <p className="text-black  text-[25px] font-[500] mx-auto">
                    {[user?.data?.firstname.slice(0, 1).toUpperCase(), user?.data?.lastname.slice(0, 1).toUpperCase()]}
                  </p>
                )}
              </>
            )}
          </div>

          <div>
            <div className="h-[33px] w-[130px] rounded-[6px] font-semibold flex items-center  justify-center text-primary-800 hover:text-[#4908AA] relative bg-primary-100 hover:shadow-lg">
              <p className="text-ss relative flex items-center justify-center ">Update new image</p>

              <input
                type="file"
                name=""
                id=""
                onChange={onSelectFile}
                accept=".jpg, .jpeg, .png"
                className="absolute cursor-pointer top-0 left-0 opacity-0  w-[100%] h-[100%]  bottom-0 "
              />
            </div>
          </div>
        </div>

        <div>
          {/* <p className="text-[#292D32] font-[800] text-[24px]">Basic Details</p>
          <p className="text-[#737373]">
            You can add new contact to one or multiple segment.
          </p> */}

          <form className="sm:w-[60%] w-[100%] space-y-4 mt-4" onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-2 sm:gap-[2em] grid-cols-1 w-full">
              <SecondaryInput
                text="First name"
                placeholder="Enter First Name"
                onChange={handleChange}
                name="firstname"
                type="text"
                value={values.firstname}
              />

              <SecondaryInput
                text="Last name"
                placeholder="Enter Last Name"
                onChange={handleChange}
                name="lastname"
                type="text"
                value={values.lastname}
              />
            </div>

            <div className="grid sm:grid-cols-2 sm:gap-[2em] grid-cols-1">
              <SecondaryInput
                text="Email address"
                placeholder="Enter Email address"
                onChange={handleChange}
                name="email"
                error={getError("email")}
                type="text"
                value={values.email}
                disabled={true}
              />

              <div className="text-primary flex flex-col w-[100%] mt-1">
                <label htmlFor="phone" className="text-[#292D32] text-m font-[500]">
                  Mobile number
                </label>

                <div className="border-[#D0D5DD] border-[1px] flex items-center rounded-[8px] my-2 ">
                  <div className="flex items-center px-3">
                    <img src={Flag} alt="Flag" />
                    <p className="ml-2">+234</p>
                  </div>
                  <input
                    type="tel"
                    placeholder="Enter mobile number"
                    onChange={handleChange}
                    value={values.phone}
                    error={getError("phone")}
                    name="phone"
                    className=" border-l-[1px]  border-[#D0D5DD] h-[40px] pl-2 outline-none w-[70%]  bg-[#FAFAFA] text-m"
                    maxLength="10"
                    // disabled={disabled}
                  />
                </div>
                <p className="text-ss text-red-500">{errors["phone"]}</p>
              </div>
            </div>

            {/* <div className="grid grid-cols-2 gap-[2em]">
              <SecondaryInput
                text="Business name"
                placeholder="Enter Business Name"
                onChange={handleChange}
                name="businessname"
                type="text"
                value={values.businessname}
              />
            </div> */}

            <BlueButton text="Save" css="ml-2" />
          </form>

          <div className=" mt-[1em] mb-[3em]">
            <hr />
          </div>

          <p className="text-[#292D32] font-semibold text-[20px]">Role</p>

          <div className="sm:w-[30%] w-[100%]">
            <SecondarySelectInput
              text="What’s your role"
              options={roleData}
              name="userRole"
              value={values.userRole}
              onChange={handleChange}
            />

            <div className="mt-2">
              <BlueButton text="Update" onClick={handleRoleUpdate} />
            </div>
          </div>

          <div className=" mt-[1em] mb-[3em]">
            <hr />
          </div>

          <Password />

          <div className=" mt-[1em] mb-[3em]">
            <hr />
          </div>
          <p className="text-[#292D32] font-semibold text-[20px]">Delete Account</p>
          <p className="text-[#737373] text-s font-[450]">This action cannot be undone.</p>

          <div
            className="text-white bg-[#EF4444] hover:bg-[#d31212] rounded-[4px] h-[40px] w-[180px] flex items-center justify-center text-s mt-[1em] cursor-pointer"
            onClick={toggleModal}
          >
            <p>Delete Account</p>
          </div>
        </div>
      </div>

      {open ? <DeleteModal toggleModal={toggleModal} handleDelete={handleDelete} /> : null}
    </div>
  );
};

export default MyAccount;
