import { useQuery } from "@tanstack/react-query";
import PreLoader from "components/Loader/PreLoader";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Overview from "./Overview";
import Details from "./Details";
import { getCampaignReport, getACampaignReport } from "services/campaignService";
import { getCampaignGraph } from "services/campaignService";
import Toast from "config/toast";
import Typography from "utils/typography";
import { reportStatus } from "lib/survStatus";
import { toTitleCase } from "lib/titleCase";
import { FaChevronLeft } from "react-icons/fa";
import Header from "../Header";

const EmailReportShare = () => {
  const { id } = useParams();
  const [select, setSelect] = useState("");

  const componentRef = useRef();

  const { data, isLoading: reportLoading } = useQuery({
    queryKey: ["oneCampaign", id],
    queryFn: () => getCampaignReport(id),
    onError: (error) => {
      Toast.error(error);
    },
  });
  const { data: campDetails, isLoading: detailsLoading } = useQuery({
    queryKey: ["oneCampaignReport", id],
    queryFn: () => getACampaignReport(id),

    onError: (error) => {
      Toast.error(error);
    },
  });

  const { data: campGraph, refetch } = useQuery({
    queryKey: ["campaignGraph", id, select],
    queryFn: () => getCampaignGraph({ id: id, select: select }),

    onError: (error) => {
      Toast.error(error);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, select]);

  const transformGraphData = (data) => {
    const hours = 24;
    const graphData = [];

    for (let hour = 1; hour <= hours; hour++) {
      const dayIndex = hour - 1;
      graphData.push({
        name: hour,
        clicked: data?.clicked[dayIndex] ?? 0,
        opened: data?.opened ? (data.opened[dayIndex] ?? 0) : 0,
      });
    }

    return graphData;
  };

  const graphData = transformGraphData(campGraph);

  if (reportLoading || detailsLoading) {
    return <PreLoader text="Loading Report" />;
  }

  return (
    <div ref={componentRef}>
      <Header text="Email Summary" />
      <div className="w-full h-screen overflow-y-auto flex items-center justify-center">
        <div className="w-[85%] p-10 rounded-[8px] bg-white border mt-[20rem]">
          <div className="flex items-center justify-between mt-3 ">
            <div className="flex items-start space-x-3">
              <div>
                <Typography.H3 className="font-[600] flex items-center space-x-2 ">
                  <span>{campDetails.campaignName}</span>
                  <span>{reportStatus(toTitleCase(campDetails.campaignStatus))}</span>
                </Typography.H3>
              </div>
            </div>

            <div className="flex justify-between items-center">
              {/* <p className="text-[25px] font-[800]">{campDetails?.campaignName}</p> */}
            </div>
          </div>
          <div className="mt-[2rem]">
            <Overview
              data={data}
              campDetails={campDetails}
              graphData={graphData}
              select={select}
              setSelect={setSelect}
            />
          </div>
          <div>
            <Details data={campDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailReportShare;
