import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import Toggle from "react-toggle";
import { IoPersonCircleSharp } from "react-icons/io5";
import dayjs from "dayjs";
import { serializeMessage } from "lib/personalizationFunctions";
import useLoggedInUser from "hooks/useLoggedInUser";
import { createTestCampaign } from "services/campaignService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";

const Publish = ({ data, setData }) => {
  const { user } = useLoggedInUser();
  const [active, setActive] = useState(false);
  const [display, setDisplay] = useState(false);

  const handleChange = () => {
    setDisplay(!display);
  };

  const handleTelChange = (e) => {
    if (e.target.value.length === 10) {
      setActive(true);
    } else {
      setActive(false);
    }
    setData({
      ...data,
      testContact: e.target.value,
    });
  };
  const { mutate: testMutate } = useMutation({
    mutationFn: createTestCampaign,
    onSuccess: () => {
      trackEvent("test_campaign", {
        email: user?.data?.email,
        sender: data?.sender_name,
        campaignName: data?.campaign_name,
        date: dayjs().format(),
        campaignType: "SMS",
      });
      Toast.success("Test created successfully");
      queryClient.invalidateQueries({ queryKey: ["wsCredit"] });
      setData({
        ...data,
        testContact: "",
      });
    },
    onMutate: () => {
      Toast.success("Sending Test Campaign");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const handleTest = async () => {
    const test = {
      campaign_channel: "sms",
      sender_name: data.sender_name,
      campaign_subject: data.campaign_subject,
      content: data.content,
      tester: { phone: "+234" + data.testContact },
    };

    testMutate(test);
  };

  return (
    <div className="flex items-start justify-between mt-5">
      <div className="w-[60%]">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-[18px] text-[#0F172A] tracking-[-0.02em] font-semibold">Preview </p>
            <p>Confirm the details of the email before publishing.</p>
          </div>
        </div>

        <div className="p-5 border rounded-lg mt-10">
          <div className="">
            <p className="text-[15px] tracking-[-0.02em] font-semibold pt-5">Audience</p>

            {/* <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Cost</p>

              <p className="text-right font-[700] text-primary-800 text-[24px]">20</p>
            </div> */}

            <div className="text-m flex justify-between border-b-[1px] py-4 text-right">
              <p className=" text-[#737373] font-[450]">Tags</p>
              <div className="flex items-center space-x-2">
                {/* {data?.recipientArray?.map((el, i) => {
                  return ( */}
                <p className="text-[12px] p-2 bg-gray-200 rounded-md ">{data?.receiver[0]?.phone}</p>
                {/* );
                })} */}
              </div>
            </div>

            <p className="text-[15px] tracking-[-0.02em] font-semibold mt-5">Details</p>

            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Subject</p>
              <p className="">{data.campaign_name}</p>
            </div>

            <div className="text-m flex justify-between py-4">
              <p className=" text-[#737373] font-[450]">Objective</p>
              <p className=" max-w-[65%] leading-5 text-left break-all">{data.campaignObjective}</p>
            </div>
            <div className="text-m flex justify-between border-b-[1px] py-4">
              <p className=" text-[#737373] font-[450]">Broadcast type</p>
              <p className=" max-w-[65%] leading-5 text-left break-all">SMS</p>
            </div>
          </div>
        </div>

        <div className="flex space-x-4 items-center mt-5">
          <p className="text-s font-semibold">Send Test</p>
          <Toggle checked={display} onChange={handleChange} icons={false} />
        </div>

        {display && (
          <div>
            <div className="flex space-x-4 items-center my-[1em]">
              <div className="flex items-center px-4 rounded-[8px] border-[#DFE5EC] border-[1px] space-x-2 w-[] h-[45px]">
                <p>+234</p>
                <input
                  type="tel"
                  className="outline-none h-[100%]"
                  placeholder="8123456789"
                  value={data.testContact}
                  onChange={handleTelChange}
                  maxlength="10"
                />
              </div>
              <p
                className={`text-ss cursor-pointer ${active ? "text-primary-800 font-bold " : "text-[#737373] "}`}
                onClick={active ? handleTest : null}
              >
                Send Test
              </p>
            </div>
            <p className="text-ss">Each test SMS would be deducted from your credit balance</p>
          </div>
        )}
      </div>
      <div>
        <div className="border-[2px]  border-black  w-[400px] h-[600px] items-end rounded-[48px] p-4 pb-0">
          <div className="border-[2px]  border-[#D0D5DD]  w-[100%] h-[97%] items-end rounded-[32px] overflow-hidden">
            <div className="bg-[#F5F5F5] h-[90px] flex items-center justify-center flex-col space-y-1">
              <div className="bg-[#969AA5] w-[40px] h-[40px] mx-auto rounded-full flex justify-center items-center">
                <div className=" bg-[#969AA5]  flex items-center justify-center rounded-full overflow-hidden w-[30px] h-[30px]">
                  <IoPersonCircleSharp className="text-[28px] text-white" />
                </div>
              </div>

              <p className="text-s font-[400]">{data.sender_name || "Select a sender ID"}</p>
            </div>

            <div className="text-center space-y-[1px] mt-2 text-ss">
              <p>Text message</p>
              <p>{dayjs().format("h:mm a")}</p>
            </div>

            <div className="bg-[#E9E9EB] m-3 p-3 rounded-[10px] min-h-[100px]">
              <p className="text-s">
                {data.content.length < 1
                  ? "Enter your SMS text to see preview."
                  : serializeMessage(data.content, user?.data)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publish;
