import cancel from "assets/images/pricing/cancel.png";
import fullCheck from "assets/images/pricing/fullCheck.png";

const tableData1 = [
  {
    planDetails: "Build rich audience profiles with 360° views and dynamic segmentation.",
    free: "same",
    sme: "same",
    business: "same",
    pro: "same",
  },
  {
    planDetails: "Contact management, lead tracking, and audience segmentation.",
    free: "same",
    sme: "same",
    business: "same",
    pro: "same",
  },
];

const tableData2 = [
  {
    planDetails: "Engage your customers through SMS and email messaging.",
    free: "same",
    sme: "same",
    business: "same",
    pro: "same",
  },
  {
    planDetails: "Voice messaging to engage your audience and drive action seamlessly.",
    free: "",
    sme: "",
    business: "same",
    pro: "same",
  },
];
const tableData3 = [
  {
    planDetails: "Keep track of key customer KPIs, like NPS, CSAT, and advanced profiling.",
    free: "",
    sme: "same",
    business: "same",
    pro: "same",
  },
];
const tableData4 = [
  {
    planDetails: "Create a quantitative or video survey, share links, and analyse feedback.",
    free: "same",
    sme: "same",
    business: "same",
    pro: "same",
  },
  {
    planDetails: "Get insights from our panel or your customers, partners, and employees.",
    free: "",
    sme: "",
    business: "same",
    pro: "same",
  },
  {
    planDetails: "Use advanced answer filters and export capabilities to drill down into your data.",
    free: "",
    sme: "",
    business: "same",
    pro: "same",
  },
  {
    planDetails: "Create an offline or field survey, analyse feedback and reward participants.",
    free: "",
    sme: "",
    business: "",
    pro: "same",
  },
  {
    planDetails: "Create a brand tracker and monitor your awareness, perception and other KPIs",
    free: "",
    sme: "",
    business: "",
    pro: "same",
  },
];

const tableData5 = [
  {
    planDetails: "Coordinate, share responsibilities, and manage research projects efficiently.",
    free: "1 seat",
    sme: "2 seats",
    business: "5 seats",
    pro: "Unlimited seats",
  },
];

const MobileTables = ({ toggleValue }) => {
  const removeThreeIndices = (array, index1, index2, index3) => {
    // Ensure indices are sorted in descending order to handle the removal properly
    const indices = [index1, index2, index3].sort((a, b) => b - a);

    // Remove elements from highest index to lowest
    indices.forEach((index) => array.splice(index, 1));

    return array;
  };

  const columns = [
    { field: "planDetails", header: "Plan Details" },
    { field: "free", header: "Free" },
    { field: "sme", header: "SME" },
    { field: "business", header: "Business" },
    { field: "pro", header: "Pro" }, // Added Pro column here
  ];

  const mobileColumns =
    toggleValue === 0
      ? removeThreeIndices([...columns], 2, 3, 4) // Show 'Free' and keep 'planDetails'
      : toggleValue === 1
        ? removeThreeIndices([...columns], 1, 3, 4) // Show 'SME' and keep 'planDetails'
        : toggleValue === 2
          ? removeThreeIndices([...columns], 1, 2, 4) // Show 'Business' and keep 'planDetails'
          : toggleValue === 3
            ? removeThreeIndices([...columns], 1, 2, 3) // Show 'Pro' and keep 'planDetails'
            : columns;

  const check = (
    <div className="w-[24px] h-[24px] text-center">
      <img src={fullCheck} alt="" />
    </div>
  );

  const cancelled = (
    <div className="w-[24px] h-[24px] text-center">
      <img src={cancel} alt="" />
    </div>
  );

  return (
    <table className=" w-full px-5 my-[3em]">
      <thead className="h-[50px] text-s border-t-[1px] border-b-[1px]">
        <tr className="my-2">
          {columns.map((el, i) => (
            <th
              key={el.field}
              className="max-w-[200px] min-w-[200px] border-r-2"
              //className={el.field === "planDetails" ? "font-[600]" : "text-center font-[600]"}
            >
              {el.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="">
          <td className="font-[600] text-center py-4 bg-blue-100 text-blue-800 " colSpan={5}>
            Audience Hub
          </td>
        </tr>

        {tableData1.map((el, i) => (
          <tr key={i} className={`${i % 2 === 0 ? "bg-neutral-100" : ""} `}>
            <td className="max-w-[200px] min-w-[200px] py-2 font-[400]">{el.planDetails}</td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.free === "same" ? check : el.free === "" ? cancelled : el.free}</p>
            </td>

            {/* SME */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center"> {el.sme === "same" ? check : el.sme} </p>
            </td>

            {/* Business */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.business === "same" ? check : el.business}</p>
            </td>

            {/* Pro */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.pro === "same" ? check : el.pro}</p>
            </td>
          </tr>
        ))}

        <tr>
          <td className="font-[600] text-center py-4 bg-blue-100 text-blue-800" colSpan={5}>
            Broadcast Hub
          </td>
        </tr>

        {tableData2.map((el, i) => (
          <tr key={i} className={`${i % 2 === 0 ? "bg-neutral-100" : ""} my-2`}>
            <td className="max-w-[200px] min-w-[200px] py-2 font-[400]">{el.planDetails}</td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.free === "same" ? check : el.free === "" ? cancelled : el.free}</p>
            </td>

            {/* SME */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.sme === "same" ? check : el.sme === "" ? cancelled : el.sme}</p>
            </td>

            {/* Business */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">
                {el.business === "same" ? check : el.business === "" ? cancelled : el.business}
              </p>
            </td>

            {/* Pro */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.pro === "same" ? check : el.pro === "" ? cancelled : el.pro}</p>
            </td>
          </tr>
        ))}

        <tr>
          <td className="font-[600] text-center py-4 bg-blue-100 text-blue-800" colSpan={5}>
            Insights Hub
          </td>
        </tr>

        {tableData3.map((el, i) => (
          <tr key={i} className={`${i % 2 === 0 ? "bg-neutral-100" : ""} my-2`}>
            <td className="max-w-[200px] min-w-[200px] py-2 font-[400]">{el.planDetails}</td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.free === "same" ? check : el.free === "" ? cancelled : el.free}</p>
            </td>

            {/* SME */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center"> {el.sme === "same" ? check : el.sme === "" ? cancelled : el.sme}</p>
            </td>

            {/* Business */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">
                {el.business === "same" ? check : el.business === "" ? cancelled : el.business}
              </p>
            </td>

            {/* Pro */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.pro === "same" ? check : el.pro === "" ? cancelled : el.pro}</p>
            </td>
          </tr>
        ))}

        <tr>
          <td className="font-[600] text-center py-4 bg-blue-100 text-blue-800" colSpan={5}>
            Research Hub
          </td>
        </tr>

        {tableData4.map((el, i) => (
          <tr key={i} className={`${i % 2 === 0 ? "bg-neutral-100" : ""} my-2`}>
            <td className="max-w-[200px] min-w-[200px] py-2 font-[400]">{el.planDetails}</td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.free === "same" ? check : el.free === "" ? cancelled : el.free}</p>
            </td>

            {/* SME */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.sme === "same" ? check : el.sme === "" ? cancelled : el.sme}</p>
            </td>

            {/* Business */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">
                {el.business === "same" ? check : el.business === "" ? cancelled : el.business}
              </p>
            </td>

            {/* Pro */}
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.pro === "same" ? check : el.pro === "" ? cancelled : el.pro}</p>
            </td>
          </tr>
        ))}

        <tr>
          <td className="font-[600] text-center py-4 bg-blue-100 text-blue-800" colSpan={5}>
            Collaboration
          </td>
        </tr>

        {tableData5.map((el) => (
          <tr key={el.planDetails} className="">
            <td className="max-w-[200px] min-w-[200px] py-2 font-[400]">{el.planDetails}</td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.free}</p>
            </td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.sme}</p>
            </td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center">{el.business}</p>
            </td>
            <td className="max-w-[200px] min-w-[200px] py-2">
              <p className="flex justify-center"> {el.pro}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MobileTables;
