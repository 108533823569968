import { useSuspenseQuery, useQuery } from "@tanstack/react-query";
import { getWorkspaceToken } from "helpers/authHelper";
const { getInsight } = require("services/insightService");

const useInsight = (insightType) => {
  const workspaceToken = getWorkspaceToken();
  const { data: insightData, isLoading: loadingInsight } = useQuery({
    queryKey: [`getInsight-${insightType}`],
    queryFn: () => {
      try {
        const response = getInsight(insightType);
        if (!response) {
          throw new Error("No data received");
        }
        return response;
      } catch (error) {
        console.error("Error fetching insights:", error);
        throw error;
      }
    },
    enabled: !!workspaceToken,
  });

  return {
    insightData,
    loadingInsight,
  };
};

export default useInsight;
