import HalfDoughnutChart from "../../../../Insight/components/analyticsTab/metricsCard/HalfPieChart";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";

const NPSInsight = ({ isLoading, overViewData, graphData }) => {
  const cleanData = graphData
    ? graphData?.map((elm) => ({
        Promoters: elm.promoter,
        Detractors: elm.detractor,
        Passives: elm.passive,
        Date: dayjs(elm.publishDate).format("DD/MM/YYYY"),
      }))
    : null;

  const pieChartNPSData = {
    Promoters: overViewData[1]?.value,
    Passives: overViewData[2]?.value,
    Detractors: overViewData[3]?.value,
  };
  const pieChartData = Object.values(pieChartNPSData);
  const pieChartLabels = Object.keys(pieChartNPSData);

  return (
    <div className="flex flex-col items-center w-[100%] bg-white p-4 border-[0px] rounded-lg space-y-[3em]">
      <div className="w-[100%] h-[130px] flex flex-col space-y-4">
        <div className="mx-auto relative">
          <HalfDoughnutChart data={pieChartData} labels={pieChartLabels} />

          <div className=" w-full flex flex-col items-center">
            {isLoading ? (
              <Skeleton count={1} className="w-[40%]" />
            ) : (
              <Typography.Headers>{overViewData[0]?.value ?? 0}</Typography.Headers>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NPSInsight;
