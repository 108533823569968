import { useMutation, useQuery } from "@tanstack/react-query";
import { BranchingIcon } from "assets/icons/survey/BranchingIcon";
import { UIConstants } from "data/constants/uiConstants";
import { surveyTypes } from "lib/surveyFunctions";
import { surveyOption } from "lib/surveyFunctions";
import { surveyType } from "lib/surveyFunctions";
import { surveyIcons } from "lib/surveyFunctions";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IoCopy } from "react-icons/io5";
import { LuPlus } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { TbCopyPlusFilled } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Toggle from "react-toggle";
import { fetchBySurveyId } from "services/newSurveyService";
import useBranchingStore from "store/branchingStore";
import Typography from "utils/typography";
import BottomBar from "../BottomBar";
import { createSurveyQuestions } from "services/newSurveyService";
import Toast from "config/toast";
import { useStore } from "store";
import AudienceModal from "../AudienceModal";
import { RiSave3Fill } from "react-icons/ri";
import SurveyDetailsModal from "../SurveyDetailsModal";
import CopyAndPasteModal from "../CopyAndPasteModal";
import SaveAndExitModal from "../SaveAndExitModal";
import { queryClient } from "config/gateway";

const SectionQuestionsPanel = ({ newStep, setNewStep }) => {
  const [open, setOpen] = useState(null);
  const {
    editSectionName,
    sectionArray,
    getAllQuestions,
    handleOptional,
    handleText,
    addOptionToQuestion,
    handleOptionValues,
    deleteValueOptions,
    handleLikertType,
    handleRatingTextType,
    handleRatingSelectType,
    addMooyiOptions,
    createMatrixOptions,
    deleteMatrixOptions,
    handleMatrixValues,
    sectionIndex,
    createQuestion,
    handleTypeSelect,
    deleteQuestion,
    duplicateQuestion,
    initializeSections,
    setSectionArray,
    transformQuestions,
  } = useBranchingStore();
  const [openSurveyType, setOpenSurveyType] = useState("");
  const [hover, setHover] = useState();
  const newCardRef = useRef(null);
  const [scrollToNewCard, setScrollToNewCard] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const { setSurveyTemplate } = useStore();
  const navigate = useNavigate();

  const pathArray = location.pathname.split("/");

  const { data } = useQuery({ queryKey: ["fetchBySurveyId", id], queryFn: () => fetchBySurveyId(id), enabled: !!id });

  const freshData = data?.data;

  const { mutate } = useMutation({
    mutationFn: createSurveyQuestions,
    onSuccess: () => {
      Toast.success("Branching question saved successfully");
      setSurveyTemplate({
        id: 0,
        surveyName: "",
        surveyCategory: "",
        surveyDescription: "",
        coverImage: "",
        reward: 0,
        campaignType: "",
        bg: "bg-bgOne",
        questions: [],
      });
      if (pathArray.includes("online-survey")) {
        handleModal(3);
      } else {
        setNewStep(newStep + 1);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: saveQuestion } = useMutation({
    mutationFn: createSurveyQuestions,
    onSuccess: () => {
      Toast.success("Question saved successfully");
    },
    onMutate: () => {
      Toast.success("Question saved successfully");
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const { mutate: saveMutate, isPending: saveLoading } = useMutation({
    mutationFn: createSurveyQuestions,
    onSuccess: () => {
      Toast.success("Question saved successfully");

      setSurveyTemplate({
        surveyName: "",
        surveyCategory: "",
        surveyDescription: "",
        coverImage: "",
        reward: 0,
        campaignType: "",
        bg: "bg-bgOne",
        questions: [],
      });
      queryClient.invalidateQueries({ queryKey: ["onlineSurveyDraft", "brandTrackingDraft", "offline_surveyDraft"] });
      if (pathArray.includes("brand-tracking")) {
        navigate(`/research/brand-tracking`);
      } else if (pathArray.includes("online-survey")) {
        navigate(`/research/online-survey`);
      } else {
        navigate(`/research/field-interview`);
      }
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const branchOptions = useMemo(() => {
    return data?.data?.surveyDetails?.branchingQuestion?.options?.map((opt) => opt.option) || [];
  }, [data?.data?.surveyDetails?.branchingQuestion?.options]);

  useEffect(() => {
    if (branchOptions.length > 0 && freshData?.questions?.length === 0) {
      initializeSections(branchOptions);
    } else {
      const x = transformQuestions(freshData?.questions, freshData?.surveyDetails?.branchingQuestion);
      setSectionArray(x);
    }
  }, [
    branchOptions,
    freshData?.questions,
    freshData?.surveyDetails?.branchingQuestion,
    initializeSections,
    setSectionArray,
    transformQuestions,
  ]);

  const handleHover = (id) => {
    setHover(hover !== id ? id : null);
  };

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const onTypeChange = (value, questionIndex) => {
    handleTypeSelect(value, questionIndex);
    handleSurveyTypeDropDown();
    // Additional actions if needed
  };

  const handleSectionName = (e) => {
    editSectionName(sectionIndex, e.target.value);
  };

  const handleSurveyTypeDropDown = (id) => {
    setOpenSurveyType(openSurveyType === id ? null : id);
  };

  const onOptionalToggle = (questionIndex, presentValue) => {
    handleOptional(questionIndex, presentValue);
    // Additional actions if needed
  };

  const createValueOptions = (index) => {
    addOptionToQuestion(sectionIndex, index);
  };

  const handleQuestionText = (e, questionIndex) => {
    handleText(e.target.name, e.target.value, sectionIndex, questionIndex);
  };

  const handleOptionChange = (e, index, optionIndex) => {
    handleOptionValues(sectionIndex, index, optionIndex, e.target.value);
  };

  const handleDeleteOption = (questionIndex, optionIndex) => {
    deleteValueOptions(sectionIndex, questionIndex, optionIndex);
  };

  const handleLikertChange = (e, questionIndex, parent) => {
    handleLikertType(sectionIndex, questionIndex, parent, e.target.name, e.target.value);
  };

  const handleRatingTextChange = (e, questionIndex) => {
    handleRatingTextType(sectionIndex, questionIndex, e.target.name, e.target.value);
  };

  const handleRatingSelectChange = (e, questionIndex) => {
    handleRatingSelectType(sectionIndex, questionIndex, e.target.name, e.target.value);
  };

  const handleRatingDropDownType = (e, questionIndex) => {
    handleRatingSelectType(sectionIndex, questionIndex, "shape", e.value);
  };

  const handleAddMooyiOptions = (questionIndex, value) => {
    addMooyiOptions(sectionIndex, questionIndex, value);
  };

  const handleMatrixOptions = (questionIndex, key) => {
    createMatrixOptions(sectionIndex, questionIndex, key);
  };

  const handleMatrixDeleteOptions = (questionIndex, optionIndex, key) => {
    deleteMatrixOptions(sectionIndex, questionIndex, optionIndex, key);
  };
  const handleMatrixTextChange = (e, questionIndex, newIndex, key) => {
    handleMatrixValues(e, sectionIndex, questionIndex, newIndex, key);
  };

  const handleCreateQuestion = () => {
    createQuestion();
    setScrollToNewCard(true);
    // Trigger scrolling
  };

  useEffect(() => {
    if (scrollToNewCard && newCardRef.current) {
      newCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setScrollToNewCard(false);

      // Reset the state
    }
  }, [scrollToNewCard]);

  const handleSubmit = () => {
    const allQuestions = getAllQuestions();

    const userData = {
      surveyId: id,
      payload: {
        questions: allQuestions,
      },
    };
    mutate(userData);
  };

  const handleSaveAndExit = () => {
    const allQuestions = getAllQuestions();
    saveMutate({
      surveyId: id,
      payload: {
        questions: allQuestions,
      },
    });
  };

  const saveQuestionFunc = () => {
    const allQuestions = getAllQuestions();
    saveQuestion({
      surveyId: id,
      payload: {
        questions: allQuestions,
      },
    });
  };

  return (
    <div className={`w-[70%] border-[1px] shadow h-[100%] bg-neutral-100  ${UIConstants.customScrollBarClassNames}`}>
      {open === "openSurveyModal" ? (
        <SurveyDetailsModal handleOpen={handleModal} from="onlineSurvey" fromStep={true} />
      ) : null}
      {open === 3 ? <AudienceModal handleModal={() => handleModal(3)} setNewStep={setNewStep} /> : null}

      {open === 4 ? <SaveAndExitModal handleModal={() => handleModal(4)} callBackFn={handleSaveAndExit} /> : null}

      {open === "copyAndPasteModal" && <CopyAndPasteModal handleModal={handleModal} from="branching" />}

      <div className="h-[50px] border-b-[1px] flex items-center justify-between px-4 bg-white">
        <div className="text-sm flex items-center">
          <Typography.SubText className="font-semibold">{freshData?.surveyDetails?.surveyName}</Typography.SubText>

          <div className="hover:bg-gray-200 p-2 cursor-pointer rounded" onClick={() => handleModal("openSurveyModal")}>
            <FaPencilAlt className="text-gray-600" />
          </div>
        </div>

        <div className="flex items-center space-x-3 px-5">
          <div
            className="bg-primary-50 hover:bg-primary-100 w-[35px] h-[35px] rounded text-primary-800 flex items-center justify-center cursor-pointer"
            onClick={saveQuestionFunc}
          >
            <RiSave3Fill />
          </div>
        </div>
      </div>

      <div className=" flex items-center justify-between p-4">
        <div className="w-[90%] h-[calc(100vh-310px)] overflow-y-auto">
          <div className=" rounded-md overflow-hidden border-[1px] after:">
            <div className="bg-primary-50 w-[100%] h-[50px] flex space-x-2 items-center px-4">
              <BranchingIcon fill1={"#95ADFF"} fill2={"#1D19AF"} />
              <Typography.SubText className="truncate ... w-[100%] font-semibold text-primary-800">
                {sectionArray[sectionIndex]?.sectionOption}
              </Typography.SubText>
            </div>

            <div className="bg-white p-4">
              <input
                type="text"
                className="w-[100%] text-ss placeholder:text-ss font-[400] h-[45px] pl-2 border-b-[1px] outline-none bg-gray-50"
                placeholder="Enter section title"
                value={sectionArray[sectionIndex]?.sectionName}
                onChange={handleSectionName}
                name="sectionName"
              />
            </div>
          </div>

          <div className="flex h-[90%] items-center">
            <div className="w-[100%] h-[100%]  ">
              {sectionArray[sectionIndex]?.questions?.map((_, i) => {
                return (
                  <div className=" p-4 bg-white my-2 rounded" key={`${i}-surveyQuestionsArray`} ref={newCardRef}>
                    <div className="flex items-center space-x-2">
                      <p className="my-4 text-sm font-semibold">Question {i + 1}</p>

                      {/* {hasNonNullSkipLogic(surveyQuestionsArray[i]) ? (
                        <div className="bg-primary-50 flex justify-center items-center px-2 py-1 rounded-md">
                          <SkipLogicIcon />
                        </div>
                      ) : null} */}
                    </div>

                    <div className="flex justify-between">
                      <input
                        type="text"
                        className="w-[63%] text-ss placeholder:text-ss font-[400] h-[40px] pl-2 border-b-[1px] outline-none"
                        placeholder="Type your question here"
                        value={sectionArray[sectionIndex]?.questions[i]?.question}
                        onChange={(e) => handleQuestionText(e, i)}
                        name="question"
                      />

                      <div className="relative">
                        <div
                          className="border-[1px] w-[190px] h-[40px]  flex items-center text-s justify-between space-x-4 rounded-md cursor-pointer px-4"
                          onClick={() => handleSurveyTypeDropDown(i)}
                        >
                          <div className="flex space-x-2 items-center">
                            <div>{surveyIcons(sectionArray[sectionIndex]?.questions[i]?.questionType)}</div>
                            <p className="text-ss">
                              {surveyType(sectionArray[sectionIndex]?.questions[i]?.questionType)}
                            </p>
                          </div>

                          <IoIosArrowDown />
                        </div>

                        {openSurveyType === i ? (
                          <div className="shadow-md bg-white overflow-hidden rounded-[8px] absolute top-[3em] w-[190px] z-20">
                            {surveyTypes.map((elm) => {
                              return (
                                <div
                                  className="flex items-center space-x-2 h-[35px] text-[#737373] pl-4 border-b-[1px] cursor-pointer "
                                  key={elm}
                                  onClick={() => onTypeChange(elm, i)}
                                >
                                  <div className="w-[30px]">{surveyIcons(elm)}</div>
                                  <p className="text-ss">{surveyType(elm)}</p>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <input
                      type="text"
                      className="w-[100%] mt-[1em] text-ss placeholder:text-ss font-[400] h-[40px] pl-2 border-b-[1px] outline-none"
                      placeholder="Type your description here"
                      value={sectionArray[sectionIndex]?.questions[i]?.description}
                      onChange={(e) => handleQuestionText(e, i)}
                      name="description"
                    />

                    <div></div>

                    <>
                      {surveyOption(
                        sectionArray[sectionIndex]?.questions[i]?.questionType,
                        i,
                        handleLikertChange,
                        sectionArray[sectionIndex]?.questions[i],
                        createValueOptions,
                        handleOptionChange,
                        handleDeleteOption,
                        handleAddMooyiOptions,
                        handleMatrixOptions,
                        handleMatrixDeleteOptions,
                        handleMatrixTextChange,
                        handleRatingTextChange,
                        handleRatingSelectChange,
                        // handleRatingSelectChange,
                        handleRatingDropDownType,
                      )}
                    </>

                    <div className="flex justify-between border-t-[1px] items-center mt-[1em] py-2">
                      <div className="flex space-x-2 items-center ">
                        <label>
                          <Toggle
                            checked={sectionArray[sectionIndex]?.questions[i]?.optional}
                            icons={false}
                            onChange={() => onOptionalToggle(i, sectionArray[sectionIndex].questions[i]?.optional)}
                            className="toggle"
                          />
                        </label>

                        <p className="text-ss -mt-1">Required</p>
                      </div>

                      <div className="flex items-center space-x-3">
                        {/* {sectionArray[sectionIndex].questions[i]?.questionType === "single_choice" ? (
                          <>
                            <GreyButton
                              text={
                                <p
                                  className="flex items-center space-x-2"
                                  onClick={() => pickSelectedQuestion(`skip-${i}`)}
                                >
                                  <SkipLogicIcon />
                                  <span className="text-xs">Skip Logic</span>
                                </p>
                              }
                              type="button"
                            />
                          </>
                        ) : null} */}

                        <div
                          className="bg-slate-100 hover:bg-slate-200 cursor-pointer w-[35px] h-[35px] rounded text-neutral-500 flex items-center justify-center"
                          onClick={() => duplicateQuestion(i)}
                        >
                          <IoCopy />
                        </div>

                        <div
                          className="bg-slate-100 hover:bg-slate-200 cursor-pointer w-[35px] h-[35px] rounded text-neutral-500 flex items-center justify-center"
                          onClick={() => deleteQuestion(i)}
                        >
                          <MdDelete className="text-xl" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* <PrimaryButton text="submit" onClick={handleSubmit} /> */}

        <div className="w-[7%] h-auto bg-primary-50 place-items-center justify-center border-[1px] border-primary-200 rounded flex flex-col items-center py-4 space-y-3">
          <div
            onClick={handleCreateQuestion}
            onMouseEnter={() => handleHover(1)}
            onMouseLeave={() => handleHover(null)}
            className="bg-white hover:bg-slate-100 w-[40px] h-[50px] flex items-center justify-center text-xl border-[1px] border-neutral-500 rounded-md relative cursor-pointer"
          >
            <LuPlus className="text-neutral-500" />
            {hover === 1 && (
              <div className="absolute top-3 right-10 px-3 py-1 flex items-center justify-center border rounded-md w-[100px] bg-white text-xs">
                Add question
              </div>
            )}
          </div>

          <div
            onClick={() => handleModal("copyAndPasteModal")}
            className="bg-white hover:bg-slate-100 w-[40px] h-[50px] flex items-center justify-center text-xl border-[1px] border-neutral-500 rounded-md relative cursor-pointer"
            onMouseEnter={() => handleHover(2)}
            onMouseLeave={() => handleHover(null)}
          >
            <TbCopyPlusFilled className="text-neutral-500" />
            {hover === 2 && (
              <div className="absolute top-3 right-10 px-3 py-1 flex items-center justify-center border rounded-md w-[150px] bg-white text-xs">
                Paste questions
              </div>
            )}
          </div>

          {/* <div
                onClick={() => setOpenThankYou(!openThankYou)}
                onMouseEnter={() => handleHover(3)}
                onMouseLeave={() => handleHover(null)}
                className={`  w-[40px] h-[50px] flex items-center justify-center text-xl border-[1px] border-neutral-500 rounded-md cursor-pointer relative  ${
                  open === "thankYou" ? "bg-primary-300 text-primary-50" : "bg-white hover:bg-slate-50 text-neutral-500"
                }`}
              >
                <IoHeart className="" />
                {hover === 3 && (
                  <div className="absolute top-3 right-10 px-3 py-1 flex items-center justify-center border rounded-md w-[150px] bg-white text-xs">
                    Thank you screen
                  </div>
                )}
              </div> */}
        </div>
      </div>

      <BottomBar step={newStep} setStep={setNewStep} handleSave={handleSubmit} handleModal={() => handleModal(4)} />
    </div>
  );
};

export default SectionQuestionsPanel;
