import DeleteButton from "components/Button/DeleteButton";
import GreyButton from "components/Button/GreyButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import Modal from "components/Modal";
import React, { useState } from "react";

const DeactivateModal = ({ toggleModal, deactivateWs, workspaceName }) => {
  const [name, setName] = useState("");
  const handleChange = (value) => {
    setName(value);
  };

  const handleClick = () => {
    deactivateWs(name);
  };
  return (
    <Modal title={`Deactivate ${workspaceName} workspace`} onClose={toggleModal}>
      <div className="sm:w-[516px] w-[100%] sm:p-0 p-6">
        <p className="text-[16] sm:font-[500] sm:mt-4 w-[250px]">
          To confirm please enter name of Workspace <span className="text-primary-800">{workspaceName}</span>
        </p>
        <div className="w-full mt-[1em]">
          <SecondaryInput
            text=""
            placeholder="Enter Workspace name"
            onChange={(e) => handleChange(e.target.value)}
            name="workspaceName"
            type="text"
            value={name}
          />
        </div>

        <div className="flex justify-between mt-[1em]">
          <div></div>
          <div className="flex gap-[1em] mt-[1em]">
            <GreyButton text="Cancel" css="bg-[#E5E5E5] text-primary-800 h-[40px] text-s" onClick={toggleModal} />
            <DeleteButton text="Deactivated" disabled={name !== workspaceName} onClick={handleClick} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeactivateModal;
