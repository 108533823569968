import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import WelcomeModal from "./WelcomeModal";
import { useStore } from "store";
import Typography from "utils/typography";
import { useQuery } from "@tanstack/react-query";
import { recentActivities } from "services/surveyService";

import RecentCampaignsCard from "./cards/RecentCampaignsCard";
import DashImage from "assets/images/dashImage.png";
import useWorkspace from "hooks/useWorkspace";
import AudienceCard from "./cards/AudienceCard";
import InsightCard from "./cards/insights/InsightCard";
import Tooltip from "components/Tooltip";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import ViewOnlyNotificationModal from "components/Modals/ViewOnlyNotificationModal";
import imgageSVG from "assets/svgs/Mooyimascot.svg";
import BlueButton from "components/Button/BlueButton";

export default function DefaultDashboard() {
  const { data: wsData } = useWorkspace();

  const { data, isLoading } = useQuery({ queryKey: ["recentActivities"], queryFn: recentActivities });

  const { onBoarding, setOnBoardingCount, onBoardingCount } = useStore();
  const [displayOnboarding, setDisplayOnboarding] = useState(false);

  const [activeTab, setActiveTab] = useState(1);
  const { isMobile } = useSideBarStore();
  const [showViewOnlyModal, setShowViewOnlyModal] = useState(true);
  const { remindMeOnNextVisit, setRemindMeOnNextVisit } = useStore();
  // const [showReadyNowModal, setShowReadyNowModal] = useState(false);
  // const [showWelcomeAboardModal, setShowWelcomeAboardModal] = useState(false);
  const navigate = useNavigate();
  const [tableDetailsTapped, setTableDetailsTapped] = useState(false);

  useEffect(() => {
    if (remindMeOnNextVisit) {
      setShowViewOnlyModal(false);
    }
    //  else {
    //   setShowViewOnlyModal(true);
    // }
  }, []);

  const handleRemindMeOnNextVisit = () => {
    setRemindMeOnNextVisit(true);
    setShowViewOnlyModal(false);
  };

  useEffect(() => {
    setOnBoardingCount(1);
  }, [setOnBoardingCount]);

  useEffect(() => {
    if (onBoarding?.importContacts === false) {
      setDisplayOnboarding(true);
      localStorage.setItem("modal", 1);
    } else {
      setDisplayOnboarding(false);
    }
  }, [onBoarding?.importContacts]);

  return (
    <div className="flex flex-col justify-between h-[100%]">
      {onBoardingCount < 3 && displayOnboarding ? (
        <WelcomeModal handleModal={() => setDisplayOnboarding(false)} />
      ) : null}

      <div className="sm:h-[230px] h-[330px] bg-[#B6DCFF] rounded-md">
        <div className="flex h-[100%] bg-dashBg w-[100%] sm:justify-between sm:flex-row flex-col ">
          <div className="sm:w-[50%] w-[100%] p-6 sm:px-[5em] flex flex-col space-y-3 sm:justify-center  justify-start">
            <div className="bg-white w-[170px] flex items-center justify-center rounded-md py-2">
              <Typography.MicroText className="text-primary-800 font-semibold">
                Create, explore, grow!
              </Typography.MicroText>
            </div>
            <Typography.H4 className="text-lg font-medium">
              Welcome back, <span className="">{wsData?.name}</span>
            </Typography.H4>

            <Typography className="text-gray-500">Explore our key features and get valuable insights.</Typography>
          </div>

          <div className="sm:w-[50%] w-[100%] flex items-center justify-center">
            <img src={DashImage} alt="DashImage" className="h-[100%]" />
          </div>
        </div>
      </div>

      <div className="flex flex-col my-4 gap-5">
        {/* <Typography.Text className="py-4">Recent campaigns</Typography.Text> */}

        <div className="flex sm:gap-10 sm:flex-row flex-col">
          <div className="flex flex-col sm:w-[50%] w-[100%]">
            <div className="flex justify-between">
              <div className="flex flex-row items-center justify-center gap-2">
                <Typography.Text className="py-4 text-sm font-[400]">Audience</Typography.Text>
                <Tooltip text="Group your audience to improve targeting and results." className="text-[14px]" />
              </div>
              {!isMobile && (
                <Link to="/contacts">
                  <Typography.Text className="py-4 text-[#1D19AF] text-xs cursor-pointer">See All</Typography.Text>
                </Link>
              )}
            </div>
            <div className=" overflow-scroll no-scrollbar h-72 ">
              <AudienceCard />
            </div>
          </div>

          <div className="flex flex-col sm:w-[50%] w-[100%]">
            <div className="flex flex-row items-center gap-2">
              <Typography.Text className="py-4 text-sm font-[400]">Insights</Typography.Text>
              <Tooltip
                text="Understand your audience with real-time data and actionable insights."
                className="text-[14px]"
              />
            </div>
            <div className=" overflow-scroll no-scrollbar h-72">
              <InsightCard />
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[50%]">
          {!isMobile && (
            <div className="flex flex-row items-center gap-2">
              <Typography.Text className="py-4 text-sm font-normal">Recent Campaigns</Typography.Text>
              <Tooltip
                text={
                  activeTab === 1
                    ? "Create, manage, and track your surveys in real-time."
                    : "Engage your audience with targeted messaging that drives action."
                }
                className="text-[14px]"
              />
            </div>
          )}
          <RecentCampaignsCard
            recentSurveys={data?.recentSurveys}
            recentBroadCasts={data?.recentBroadCasts}
            isLoading={isLoading}
            setActiveTab={setActiveTab}
            //setShowViewOnlyModal={setShowViewOnlyModal}
            setTableDetailsTapped={setTableDetailsTapped}
          />
        </div>

        {isMobile && !onBoarding?.everLoggedIn && onBoarding?.progress < 100 && showViewOnlyModal && (
          <ViewOnlyNotificationModal setShowViewOnlyModal={setShowViewOnlyModal} img={imgageSVG}>
            <div className="flex flex-col items-center justify-center">
              <div className="text-[16px] font-[600]">Welcome aboard!</div>
              <div className="text-[12px]">Please complete your profile for 100 free sends.</div>
              <div className="text-[12px]">You can send emails or SMS to your contacts.</div>
            </div>
            <div className="flex flex-col gap-5 items-center justify-center  p-2 w-full">
              <BlueButton
                text={"Continue"}
                onClick={() => navigate("/dashboard/kyc")}
                loading={isLoading}
                className="w-full"
              />
            </div>
          </ViewOnlyNotificationModal>
        )}

        {isMobile && !onBoarding?.everLoggedIn && showViewOnlyModal && onBoarding?.progress === 100 && (
          <ViewOnlyNotificationModal setShowViewOnlyModal={setShowViewOnlyModal} img={imgageSVG}>
            <div className="flex flex-col items-center justify-center">
              <div className="text-[16px] font-[600]">Welcome to Mooyi</div>
              <div className="text-[12px]">To help you start running campaigns,we have</div>
              <div className="text-[12px]">credited you with 100 free sends</div>
            </div>
            <div className="flex flex-col gap-5 items-center justify-center  p-2 w-full">
              <BlueButton
                text={"Get Started"}
                onClick={() => setShowViewOnlyModal(false)}
                loading={isLoading}
                className="w-full"
              />
            </div>
          </ViewOnlyNotificationModal>
        )}

        {isMobile && onBoarding?.everLoggedIn && onBoarding?.progress < 100 && showViewOnlyModal && (
          <ViewOnlyNotificationModal
            setShowViewOnlyModal={setShowViewOnlyModal}
            img={imgageSVG}
            handleRemindMeOnNextVisit={handleRemindMeOnNextVisit}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="text-[16px] font-[600]">Ready Now?</div>
              <div className="text-[12px]">Please complete your profile for free sends.</div>
              <div className="text-[12px]">You can send emails or SMS to your contacts.</div>
            </div>
            <div className="flex flex-col gap-5 items-center justify-center  p-2 w-full">
              <BlueButton
                text={"Continue"}
                onClick={() => navigate("/dashboard/kyc")}
                loading={isLoading}
                className="w-full"
              />
              <div>
                <Link
                  to={""}
                  className="text-sm text-primary-900 underline font-[700]"
                  onClick={() => handleRemindMeOnNextVisit(true)}
                >
                  Don't remind me again
                </Link>
              </div>
            </div>
          </ViewOnlyNotificationModal>
        )}

        {isMobile && showViewOnlyModal && tableDetailsTapped && (
          <ViewOnlyNotificationModal setShowViewOnlyModal={setShowViewOnlyModal} img={imgageSVG}>
            <div className="flex flex-col items-center justify-center">
              <div className="text-[16px] font-[600]">View-only feature</div>
              <div className="text-[12px]">This feature is designed for larger screens to</div>
              <div className="text-[12px]">provide the best experience.To access,please</div>
              <div className="text-[12px]">switch to a laptop or desktop.</div>
            </div>
            <div className="flex flex-col gap-5 items-center justify-center  p-2 w-full">
              <BlueButton
                text={"Continue"}
                onClick={() => {
                  setShowViewOnlyModal(false);
                  setTableDetailsTapped(false);
                }}
                loading={isLoading}
                className="w-full"
              />
              <div>
                <Link
                  to={""}
                  className="text-sm text-primary-900 underline font-[700]"
                  onClick={() => handleRemindMeOnNextVisit(true)}
                >
                  Remind me on next visit
                </Link>
              </div>
            </div>
          </ViewOnlyNotificationModal>
        )}
      </div>
    </div>
  );
}
