import { useState } from "react";
import dayjs from "dayjs";
import { cn } from "helpers/classHelpers";
import useGetAllTags from "hooks/useGetAllTags";
import React, { useEffect } from "react";
import { IoIosArrowRoundDown } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";
import CustomTable from "../../../components/Table";
import BlueButton from "../../../components/Button/BlueButton";
import EmptyTags from "../../../assets/svgs/emptyTags.svg";

const AudienceCard = () => {
  const { tagData: tag, loadingTags } = useGetAllTags();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (tag) {
      const rows = tag
        ? tag.tags.slice(0, 4).map((item, index) => ({
            id: item._id,
            name: item.name,
            size: item.contactCount,
            lastUsed: dayjs(item.created_at).format("ddd, DD MMM, YYYY | hh:mm a "),
          }))
        : [];
      setRows(rows);

      const columns = [
        { headerName: "Name", width: 200 },
        { headerName: "Size", width: 100 },
        { headerName: "Last Used", width: 200 },
      ];
      setColumns(columns);
    }
  }, [tag]);

  const EmptyState = (
    <div className="flex flex-col text-center justify-center w-full gap-3">
      <div className="flex flex-row w-full justify-center">
        <img src={EmptyTags} className="w-20 h-20" alt="empty audience" />
      </div>
      <div className="w-full">
        <Typography.SubText className="pb-3 font-[600]">No Tags Yet.</Typography.SubText>
        <Typography.MicroText>Tags are like labels.Use them to </Typography.MicroText>
        <Typography.MicroText> categorise and organise your contacts.</Typography.MicroText>
        <Typography.MicroText> Click on the button to create one.</Typography.MicroText>
      </div>
      <div className="flex flex-row w-full justify-center">
        <BlueButton text="Create Your First Tag" className="w-auto" />
      </div>
    </div>
  );

  return (
    <div className="w-[100%] bg-white border-[1px] rounded-md p-4 h-full">
      <div className=" border-[0px] rounded-md overflow-hidden h-full">
        {loadingTags ? (
          <div className="">
            <Skeleton className="h-[60px]" />
            <Skeleton className="h-[40px]" />
            <Skeleton className="h-[20px]" />
          </div>
        ) : rows.length > 0 ? (
          <CustomTable rows={rows} columns={columns} pageSize={rows.length} pagination={false} />
        ) : (
          EmptyState
        )}
      </div>
    </div>
  );
};

export default AudienceCard;
