import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import SecondaryInput from "components/Inputs/SecondaryInput";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import Toast from "config/toast";
import { useFormik } from "formik";
import { createEmailId } from "services/senderIdService";
import useLoggedInUser from "hooks/useLoggedInUser";
import * as Yup from "yup";
import dayjs from "dayjs";
import Modal from "components/Modal";

const initialValues = {
  name: "",
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Enter a valid email address").required("Please fill in this field"),
  name: Yup.string().required("Please fill in this field"),
});

const EmailIDModal = ({ toggleModal }) => {
  const { user } = useLoggedInUser();
  const { mutate } = useMutation({
    mutationFn: createEmailId,
    onSuccess: () => {
      Toast.success("Email ID request sent successfully");
      queryClient.invalidateQueries({ queryKey: ["emailSenderIDs"] });
      trackEvent("create_senderID", {
        email: user?.data?.email,
        settingSection: "sender ID",
        attributeUpdated: "sender email address",
        date: dayjs().format(),
      });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    const userData = {
      ...data,
    };
    mutate(userData);
    toggleModal();
  };

  const { handleChange, values, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  return (
    <Modal title="New sender ID" onClose={toggleModal}>
      <div className="w-[450px] px-5">
        <form onSubmit={handleSubmit}>
          <div className="mt-[1em]">
            <SecondaryInput
              text="Sender mail "
              placeholder="Enter sender mail"
              onChange={handleChange}
              name="email"
              type="text"
              onBlur={handleBlur}
              value={values.email}
              error={getError("email")}
            />
          </div>

          <div className="mt-[1em]">
            <SecondaryInput
              text="Sender name"
              placeholder="Enter sender name"
              onChange={handleChange}
              name="name"
              type="text"
              onBlur={handleBlur}
              value={values.name}
              error={getError("name")}
            />
          </div>

          <div className="flex space-x-5 justify-end items-center my-[2em] mt-[2em]">
            <div
              className="rounded-[4px] h-[40px] px-8 text-sm font-bold bg-primary-300 flex items-center justify-center text-white cursor-pointer"
              onClick={toggleModal}
            >
              Cancel
            </div>

            <div className="flex space-x-4">
              <BlueButton text="Create" />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EmailIDModal;
