import React, { useState } from "react";
import Plans from "./Plans";
import ToggleButton from "components/Button/ToogleButton";
import sendImg from "assets/icons/Pricing/sendImg.png";
import colabImg from "assets/icons/Pricing/colabImg.png";
import responsesImg from "assets/icons/Pricing/responsesImg.png";
import MoneyImg from "assets/icons/Pricing/MoneyImg.png";
import CardList from "../components/PriceCards";
import AdditionalPriceTag from "../components/AdditionalPricsCard";
import useCurrency from "hooks/useCUrrencyHooks";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const EngagementHub = () => {
  const { conversionRate } = useCurrency();
  const [toggleValue, setToggleValue] = useState(false);
  const { isMobile } = useSideBarStore();

  const handleToggleChange = () => {
    setToggleValue(!toggleValue);
  };

  // const rates = usePriceRate();
  // const conversionRate = rates?.data?.data[0].currencyRates[7].userRate;

  console.log(conversionRate);

  const priceCardInfo = [
    {
      icon: sendImg,
      action: "Sends",
      description: "Emails and SMS units are prices at",
      bill: `${toggleValue ? `₦${(0.5 * conversionRate).toLocaleString()}` : "$0.5"} per 100 sends.`,
      bgcolor: "bg-[#FFFBEB]",
      borderColor: "border-[#D97706]",
    },
    {
      icon: responsesImg,
      action: "Responses",
      description: "Survey responses are priced at",
      bill: `${toggleValue ? `₦${(2 * conversionRate).toLocaleString()}` : "$2"} per 100 response.`,
      bgcolor: "bg-[#EBF1FF]",
      borderColor: "border-[#2D28FF]",
    },
    {
      icon: MoneyImg,
      action: "Rewards",
      description: "Reward your survey participants from",
      bill: `${toggleValue ? `₦${(10 * conversionRate).toLocaleString()}` : "$10"} for 10,000 MC.`,
      bgcolor: "bg-[#F0FDF4]",
      borderColor: "border-[#16A34A]",
    },
    {
      icon: colabImg,
      action: "Collaboration tools",
      description: "Unlimited workspaces for teams are priced at ",
      bill: `${toggleValue ? `₦${(10 * conversionRate).toLocaleString()}.` : "$10."}`,
      bgcolor: "bg-[#FFFBEB]",
      borderColor: "border-[#D97706]",
    },
  ];

  const AdditionalPrice = [
    {
      type: "Demographic targeting",
      amount: `${toggleValue ? `₦${(4 * conversionRate).toLocaleString()}` : "$2"}`,
    },
    {
      type: "Detailed targeting",
      amount: `${toggleValue ? `₦${(4 * conversionRate).toLocaleString()}` : "$4"}`,
    },
    {
      type: "Field Interviews",
      amount: `${toggleValue ? `₦${(10 * conversionRate).toLocaleString()}` : "$10"} `,
    },
    {
      type: "Brand tracking",
      amount: `${toggleValue ? `₦${(1000 * conversionRate).toLocaleString()}` : "$1000"}`,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between w-full sm:flex-row flex-col">
        <p>Choose the perfect plan for your business, and pay only for what you use.</p>
        <div className="pt-4 flex justify-center items-center space-x-3">
          <p className={`${!toggleValue && "font-bold"}`}>USD</p>
          <ToggleButton toggleValue={toggleValue} onChange={handleToggleChange} />
          <p className={`${toggleValue && "font-bold"}`}>NGN</p>
        </div>
      </div>
      <div>
        <Plans conversionRate={conversionRate} toggleValue={toggleValue} />
      </div>
      {!isMobile && (
        <div className="bg-white p-10">
          <div className=" md:pt-[1rem] py-5 ">
            <div className="flex justify-center">
              <p className="text-[24px] w-[70%] font-[700] text-center">
                Free users get 100 free emails on completing their profiles. Additional credits are available for
                purchase as stated
              </p>
            </div>
            <div className="md:grid grid-cols-2 gap-4 pt-10">
              {priceCardInfo.map((el, i) => (
                <CardList
                  key={i}
                  icon={el.icon}
                  description={el.description}
                  priceTag={el.bill}
                  action={el.action}
                  bgcolor={el.bgcolor}
                  borderColor={el.borderColor}
                />
              ))}
            </div>
          </div>
          <div className="pt-10">
            <div className=" bg-blue-800 rounded-[8px] p-5 flex flex-col justify-center">
              <p className=" font-[700] text-white text-center text-[18px]">
                Additional pricing information for research projects.
              </p>
              <div className="md:flex items-center md:space-x-4 space-y-5 md:space-y-0 pt-5">
                {AdditionalPrice.map((el, i) => (
                  <AdditionalPriceTag key={i} type={el.type} amount={el.amount} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EngagementHub;
