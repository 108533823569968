import SelectInput from "components/Inputs/SelectInput";
import { formatDate } from "lib/formatDate";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import InviteModal from "./InviteModal";
import { useStore } from "store";
import BlueButton from "components/Button/BlueButton";
import { RiDeleteBin6Line, RiRecycleLine } from "react-icons/ri";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addNewUser, deleteAUser } from "services/userService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import { trackEvent } from "config/mixpanel";
import dayjs from "dayjs";
import { fetchWorkspace } from "services/workspaceService";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const options = [
  { value: "", label: "All users" },
  { value: "rwx_user", label: "Admin" },
  { value: "rw_user", label: "Editor" },
  { value: "r_user", label: "Viewer" },
];

const columns = [
  { field: "fullname", header: "Full Name" },
  { field: "role", header: "Role" },
  { field: "createdAt", header: "Date Added" },
  { field: "email", header: "Email Address" },
  { field: "status", header: "Status" },
  { field: "viewProps", header: "" },
];

const selectName = (x) => {
  switch (x) {
    case "rwx_user":
      return "Admin";
    case "rw_user":
      return "Editor";
    case "r_user":
      return "Viewer";
    default:
  }
};

const Members = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [members, setMembers] = useState(data);
  const [showTag, setShowTag] = useState(null);
  const { user } = useStore();
  const [dropState, setDropState] = useState("");
  const { isMobile } = useSideBarStore();
  const { data: getworkspace } = useQuery({ queryKey: ["defaultWorkspace"], queryFn: fetchWorkspace });
  const { mutate } = useMutation({
    mutationFn: deleteAUser,
    onSuccess: () => {
      Toast.success("User deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["workspaceUsers"] });
      toggleModal();
    },
  });

  const handleShowTag = (id) => {
    setShowTag(showTag === id ? null : id);
  };

  const { mutate: resendUserInvite } = useMutation({
    mutationFn: addNewUser,
    onSuccess: () => {
      Toast.success("User invite sent successfully");
      queryClient.invalidateQueries({ queryKey: ["workspaceUsers"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const deleteWsUsers = (x) => {
    mutate(x);
    trackEvent("update_settings", {
      email: user?.data?.email,
      settingSection: "members",
      attributeUpdated: "Delete user",
      date: dayjs().format(),
    });
  };

  useEffect(() => {
    setMembers(data);
  }, [data]);

  const resendInvite = (email, permission) => {
    toggleModal();
    const payload = {
      workspaceId: getworkspace.id,
      invitations: { email, permission },
    };
    resendUserInvite(payload);

    trackEvent("invite_team", {
      email: user?.data?.email,
      settingSection: "members",
      attributeUpdated: "resend new user invite",
      date: dayjs().format(),
    });
  };

  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };
  const contactData = members?.map((item, i) => ({
    id: item?._id,
    fullname: item?.fullname,
    email: item?.email,
    role: selectName(item?.permission),
    createdAt: formatDate(item?.joined),
    status: item?.approved ? <p className="text-[#16A34A]">Active</p> : <p className="text-[#F59E0B]">Invited</p>,
    viewProps: (
      <div className="relative">
        {user.email !== item?.email ? (
          <div className="w-[50px] z-[10]  cursor-pointer flex items-center space-x-2 ">
            <div
              className="flex items-center space-x-[5px] text-s hover:text-primary-800 hover:border-primary-800 border p-1 w-[30px] h-[30px] rounded justify-center"
              onClick={() => deleteWsUsers(item?.email)}
              onMouseLeave={() => handleShowTag(i)}
              onMouseEnter={() => handleShowTag(i)}
            >
              <RiDeleteBin6Line size={20} />
              {showTag === i ? (
                <p className={`bg-white shadow-sm p-1 text-[11px] absolute top-4 right-[5rem]`}>Delete</p>
              ) : null}
            </div>

            {item?.approved ? null : (
              <div
                className="flex items-center space-x-[5px] text-s hover:text-primary-800 hover:border-primary-800 border p-1 w-[30px] h-[30px] rounded justify-center"
                onClick={() => resendInvite(item?.email, item?.permission)}
                onMouseLeave={() => handleShowTag(`${i}resend`)}
                onMouseEnter={() => handleShowTag(`${i}resend`)}
              >
                <RiRecycleLine size={20} />
                {showTag === `${i}resend` ? (
                  <p className="bg-white shadow-sm p-1 text-[11px] absolute top-4 right-[-1rem]">Resend Invite</p>
                ) : null}
              </div>
            )}
          </div>
        ) : // <div className="relative">
        //   <div
        //     className="flex items-center space-x-2 text-black cursor-pointer"
        //     onClick={() => toggleModal(item?._id)}
        //   >
        //     <BsThreeDots />
        //   </div>

        //   {open === item?._id ? (
        //     <div className="shadow-lg rounded-[5px] bg-white absolute w-[150px] z-[10] right-[2em] cursor-pointer top-[1.5em]">
        //       <div
        //         className="flex items-center space-x-[5px] text-s border-b-[1px] p-[9px] text-[#EF4444] hover:text-[#d31212]"
        //         onClick={() => deleteWsUsers(item?.email)}
        //       >
        //         <MdOutlineDelete />
        //         <p>Delete</p>
        //       </div>

        //       {item?.approved ? null : (
        //         <div
        //           className="flex items-center space-x-[5px] text-s border-b-[1px] p-[9px]"
        //           onClick={() => resendInvite(item?.email, item?.permission)}
        //         >
        //           <RiRecycleLine />
        //           <p>Resend Invite</p>
        //         </div>
        //       )}
        //     </div>
        //   ) : null}
        // </div>
        null}
      </div>
    ),
  }));

  const checkCount = (x) => {
    let count = 0;
    for (let user in data) {
      if (data[user]?.permission === x) {
        count++;
      }
    }
    return count;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    const filteredData = data?.filter((filtered) => filtered.permission === dropState);
    if (value !== "") {
      const newFilteredData = filteredData?.filter(
        (filtered) =>
          filtered.email.toLowerCase().includes(query.toLowerCase()) ||
          filtered.fullname.toLowerCase().includes(query.toLowerCase()),
      );

      if (newFilteredData?.length !== 0) {
        setMembers(newFilteredData);
      }
    } else {
      setMembers(filteredData);
    }
  };

  const handleSelect = (e) => {
    const { value } = e.target;
    setDropState(value);

    if (value !== "") {
      const filteredData = data?.filter((filtered) => filtered.permission === value);

      if (filteredData?.length !== 0) {
        setMembers(filteredData);
      } else {
        setMembers(filteredData);
      }
    } else {
      setMembers(data);
    }
  };

  return (
    <div className="">
      <div className="rounded-[8px] border-[1px] bg-white py-2 flex mt-[1em] sm:gap-0 gap-1 flex-row ">
        <div className="ml-4 border-r-[1px] w-[33.3%] text-center">
          <p className="text-[20px] font-semibold">{checkCount("rwx_user")}</p>
          <p className="text-[#737373] text-s font-[450] flex items-center justify-center space-x-1">
            <span>Admin</span>
            <span>{/* <Tooltip text="" /> */}</span>
          </p>
        </div>

        <div className="ml-4 border-r-[1px] w-[33.3%] text-center">
          <p className="text-[20px] font-semibold">{checkCount("rw_user")}</p>
          <p className="text-[#737373] text-s font-[450] flex items-center justify-center space-x-1">
            <span>Editors</span>
            <span>{/* <Tooltip text="" /> */}</span>
          </p>
        </div>
        {/* 
        <div className="ml-4 border-r-[1px] w-[25%]">
          <p className="text-[20px] font-semibold">{checkCount("rwx_user")}</p>
          <p className="text-[#737373] text-s font-[450]">Creators</p>
        </div>  */}

        <div className="ml-4 w-[33.3%] text-center">
          <p className="text-[20px] font-semibold">{checkCount("r_user")}</p>
          <p className="text-[#737373] text-s font-[450] flex items-center justify-center space-x-1">
            <span>Viewers</span>
            <span>{/* <Tooltip text="" /> */}</span>
          </p>
        </div>
      </div>

      <div className="bg-white p-5 mt-10">
        <p className="text-[#292D32] font-semibold text-[20px]">
          Team: {checkCount("rwx_user") + checkCount("r_user")}
        </p>
        <div className="flex justify-between items-center mb-[1.5em] sm:flex-row flex-col">
          <div className="flex sm:space-x-6 items-center flex-wrap sm:gap-0 gap-2">
            <div className="flex items-center px-4 space-x-2 border-[1px] border-[#DFE5EC rounded-[8px] h-[42px] sm:w-[350px] w-[100%]">
              <FiSearch className="text-[#9CA3AF]" />
              <input
                type="text"
                placeholder="Search for name or emails..."
                className="tracking-[-0.02em] placeholder:text-[#9CA3AF] w-[100%]  outline-none h-[100%] bg-transparent text-sm placeholder:text-sm"
                onChange={handleChange}
                // value={query}
              />
            </div>

            <div>
              <SelectInput
                options={options}
                css="h-[42px] border-[#D0D5DD] w-[150px] rounded-[6px]"
                onChange={handleSelect}
              />
            </div>
          </div>

          {!isMobile && <BlueButton text="Invite member" onClick={() => toggleModal(2)} />}
        </div>

        <div className="overflow-x-auto">
          <table className="w-[100%] whitespace-nowrap">
            <thead className=" text-s bg-[#F9FAFB]  border-b-[1px] ">
              <tr className=" h-[45px] px-7 text-left">
                {columns &&
                  columns.map((head, i) => (
                    <th key={i} className="pl-4">
                      {head.header}{" "}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {contactData &&
                contactData.map((row, i) => (
                  <tr key={i} className="h-[50px] border-b-[1px] text-s text-[#404040] font-[500]">
                    {columns?.map((col, i) => (
                      <td key={i} className="pl-4">
                        {row[col.field]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* {open === 1 ? <MemberModal toggleModal={toggleModal} /> : null} */}
      {open === 2 ? <InviteModal toggleModal={toggleModal} /> : null}
    </div>
  );
};

export default Members;
