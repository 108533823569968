import PrimaryButton from "components/Button/PrimaryButton";
import spacecraft1 from "../../../assets/svgs/spacecraft1.svg";
import spacecraft2 from "../../../assets/svgs/spacecraft2.svg";
import { useNavigate } from "react-router-dom";

const GetFollowers = () => {
  const navigate = useNavigate();
  return (
    <div className="border rounded-md bg-white p-5 overflow-y-auto h-[calc(100vh-80px)]">
      <div className="relative w-[600px] h-[230px]  border-[1px] rounded-xl flex items-center overflow-hidden">
        <div className="flex flex-col space-y-3 p-6">
          <p className="bg-primary-100 text-primary-800 w-[100px] flex  justify-center items-center text-xs rounded-xl py-1 font-semibold">
            Engagement
          </p>

          <p className="text-2xl font-medium text-primary-800">Grow your followers with ease.</p>
          <p className="text-sm w-[75%]">
            Improve your follower count by sending out direct invites to your audience or our 3M+ audience.
          </p>

          <PrimaryButton
            text="Get Started"
            css="bg-primary-800 text-white h-[40px] text-xs w-[150px]"
            onClick={() => navigate("/broadcasts/get-followers/setup")}
          />
        </div>
        <img src={spacecraft2} alt="BroadcastImage" className="max-h-[50px] absolute right-0 top-0" />
        <img src={spacecraft1} alt="BroadcastImage" className="max-h-[150px]" />
      </div>
    </div>
  );
};

export default GetFollowers;
