import React, { useCallback, useEffect, useState } from "react";
import GreaterThan from "assets/images/greaterThan.svg";
import Content from "./Content";
import Publish from "./Publish";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCampaignDetails } from "services/campaignService";
import GreyButton from "components/Button/GreyButton";
import BlueButton from "components/Button/BlueButton";
import { getSingleContact } from "services/contactService";
import { FiChevronLeft } from "react-icons/fi";
import PublishModal from "../PublishModal";
import SaveAndExitModal from "../SaveAndExitModal";
import { SuccessModal } from "../successModal";
import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { saveAndExit } from "services/campaignService";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import { singleSmsCampaign } from "services/campaignService";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const IndividualSms = () => {
  const { user } = useLoggedInUser();
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(null);
  const { id } = useParams();
  const { sidebar } = useSideBarStore();
  const [disableButton, setDisabledButton] = useState(true);
  const navigate = useNavigate();

  const {
    data: details,
    isFetching,
    isLoading,
  } = useQuery({ queryKey: ["singleContact"], queryFn: () => getSingleContact(id), refetchOnMount: true });

  const { data, isLoading: fetchDetails } = useQuery({
    queryKey: ["fetchCampaignId", id],
    queryFn: () => getCampaignDetails(id),
    enabled: !!id,
  });
  const { data: singleContact } = useQuery({ queryKey: ["singleContact"], queryFn: () => getSingleContact(id) });

  const { mutate: saveExit, isLoading: saveExitLoading } = useMutation({
    mutationFn: saveAndExit,
    onSuccess: () => {
      Toast.success(`SMS saved successfully`);
      queryClient.invalidateQueries({ queryKey: ["allSmsCampaigns", "onBoarding"] });
      setTimeout(() => {
        navigate(`/broadcasts/sms`);
      }, 1000);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  console.log(singleContact);
  const phoneArray = isFetching || isLoading ? "" : (singleContact?.phone ?? JSON.parse(id));

  const { mutate } = useMutation({
    mutationFn: singleSmsCampaign,
    onSuccess: () => {
      Toast.success("sms sent successfully");
      handleModal();
      queryClient.invalidateQueries({ queryKey: ["singleSmsBroadcast"] });
      handleModal(5);
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const [smsData, setSmsData] = useState({
    content: "",
    sender_name: "",
    campaign_name: "",
    campaign_subject: "mooyi camp",
    receiver: [{ phone: phoneArray }],
  });

  const disabled = !smsData?.content || !smsData?.sender_name || !smsData?.campaignObjective;

  const handleModal = (id) => {
    setOpen(open === id ? null : id);
  };

  const moveToStep = (value) => {
    if (value <= step) {
      setStep(value);
    }
  };

  const tabs = [
    { name: "Content", value: 0 },
    { name: "Publish", value: 1 },
  ];

  console.log(id);

  const displayStep = () => {
    switch (step) {
      case 0:
        return (
          <Content data={smsData} existingData={data} setData={setSmsData} loading={fetchDetails} setStep={setStep} />
        );
      case 1:
        return (
          <Publish data={smsData} existingData={data} setData={setSmsData} loading={fetchDetails} setStep={setStep} />
        );
      default:
    }
  };

  const handleNext = () => {
    if (step < 1) {
      setStep(step + 1);
    } else if (step === 1) {
      setOpen(1);
    }
  };

  const updatedInfo = {
    campaign_channel: "sms",
    sender_name: smsData.sender_name,
    campaign_subject: "Mooyi Camp",
    content: smsData.content,
    receiver: smsData.receiver,
  };

  const handleSave = () => {
    saveExit({ campaignId: id, payload: updatedInfo });
  };

  const proceedSubmit = async () => {
    trackEvent("publish_campaign", {
      email: user?.data?.email,
      campaignName: smsData.campaign_name,
      date: smsData.personal,
      isScheduledForLater: smsData.scheduled,
      campaignType: "SMS",
    });

    mutate(updatedInfo);
    // console.log(updatedInfo);
  };

  const checkState = useCallback(() => {
    if (step === 0) {
      if (disabled === false) {
        setDisabledButton(false);
      }
    } else if (step === 1) {
      if (smsData.personal !== 0) {
        setDisabledButton(false);
        setOpen(2);
      } else {
        setDisabledButton(true);
      }
    }
  }, [disabled, smsData.personal, step]);

  useEffect(() => {
    checkState();
  }, [checkState]);

  return (
    <div>
      <div className="border p-5 rounded-md bg-white mb-10">
        <div
          onClick={() => navigate(-1)}
          className="flex items-center space-x-1 my-2 text-primary-800 pl-2 cursor-pointer"
        >
          <FiChevronLeft />
          <p>
            Back to {details?.firstname} {details?.lastname ?? "contacts"}
          </p>
        </div>
        <div className="flex space-x-4 mt-4 border-[1px] h-[49px] items-center rounded-l-full rounded-r-full pl-4 w-[100%]">
          {tabs.map((el, i) => {
            return (
              <div
                className="flex items-center justify-between w-[45%]"
                key={el.value}
                onClick={() => moveToStep(el.value)}
              >
                <div className="flex items-center">
                  <p
                    className={`${
                      step >= el.value ? "bg-primary-800 text-white" : "border-[1px] text-[#525252]"
                    } text-ss w-[24px] h-[24px] rounded-full flex items-center justify-center font-[500] `}
                  >
                    {el.value + 1}
                  </p>
                  <p
                    className={`px-2 cursor-pointer text-s ${
                      step >= el.value ? " text-primary-800 font-semibold " : "text-[#525252]"
                    } `}
                  >
                    {el.name}
                  </p>
                </div>

                {el.value === 1 ? null : <img src={GreaterThan} alt="GreaterThan" />}
              </div>
            );
          })}
        </div>
        <div>{displayStep()}</div>
      </div>
      <div
        className={`flex justify-between border-t-[1px] fixed z-20 bottom-0 right-0 px-4 h-[60px] items-center bg-white transition-[width] duration-300 ease-in-out ${
          sidebar === "false" ? "w-[calc(100vw-80px)]" : "w-[calc(100vw-250px)]"
        }`}
      >
        <GreyButton text="Back" onClick={() => (step !== 0 ? setStep(step - 1) : navigate(-1))} />

        <div className="flex justify-between space-x-4">
          <GreyButton text="Save & exit" onClick={() => handleModal(4)} disabled={disabled} />

          <BlueButton
            text={`${step === 1 ? "Publish" : "Next"}`}
            css={`text-white ${step === 3 ? "bg-[#25C15E]" : "bg-primary-800"}  h-[40px] text-s`}
            disabled={disableButton}
            onClick={handleNext}
          />
        </div>
      </div>
      {open === 1 ? (
        <PublishModal
          handleSubmit={proceedSubmit}
          setData={setSmsData}
          data={smsData}
          handleModal={handleModal}
          campType="SMS"
        />
      ) : null}
      {open === 4 ? (
        <SaveAndExitModal handleModal={() => handleModal(null)} handleSave={handleSave} loading={saveExitLoading} />
      ) : null}
      {open === 5 ? (
        <SuccessModal
          handleModal={() => setOpen(null)}
          handleSubmit={() => navigate(`/contacts/${id}/sms`)}
          handleDone={() => navigate(`/contacts/${id}/sms`)}
        />
      ) : null}
    </div>
  );
};

export default IndividualSms;
