import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import BlueButton from "components/Button/BlueButton";
import SecondaryInput from "components/Inputs/SecondaryInput";

import { queryClient } from "config/gateway";
import Toast from "config/toast";
import { useFormik } from "formik";
// import React, { useState } from "react";
// import ScaleLoader from "react-spinners/ScaleLoader";
import { updateBusiness } from "services/userService";
import { categoryV2, options1, sizeOptions } from "./data";
import * as Yup from "yup";
// import { FiLoader } from "react-icons/fi";
// import { TiTick } from "react-icons/ti";
// import { FaTimes } from "react-icons/fa";
// import PurpleState from "assets/images/purpledrop.png";
// import { uploadDocument } from "utils/upload";
// import { BsFileImage } from "react-icons/bs";
// import { byteConverter } from "lib/byteConverter";
// import SelectInput from "components/Inputs/SelectInput";
// import ReasonModal from "./ReasonModal";
// import ViewModal from "./ViewModal";
import { urlRegex } from "lib/regex";
import { useStore } from "store";
import DropDownWithSearch from "components/DropDownWithSearch";
import Dropdown from "components/DropDown";
import SocialHandles from "./SocialHandles";
import { data } from "autoprefixer";

const Company = ({ user }) => {
  // const { onBoarding } = useStore();
  const [category, setCatgeory] = useState(null);
  const [categoryLbl, setCategoryLbl] = useState(
    user?.data?.business?.category ? getCatLabel(categoryV2, user?.data?.business?.category) : null,
  );
  const [subcategoryLbl, setSubcategoryLbl] = useState(
    user?.data?.business?.subcategory
      ? getSubCatLabel(categoryV2, user?.data?.business?.category, user?.data?.business?.subcategory)
      : null,
  );
  const [subcategories, setSubcategories] = useState(null);

  const { mutate } = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      Toast.success("Company information updated successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const initialValues = {
    businessName: user?.data?.business?.businessName || "",
    companySize: user?.data?.business?.companySize || "0-9 employees",
    //industry: user?.data?.business?.industry || "agriculture",
    companyAddress: user?.data?.business?.companyAddress || "",
    websiteUrl: user?.data?.business?.websiteUrl || "",
    rcNumber: user?.data?.rcNumber?.value || "",
    category: user?.data?.business?.category || "",
    subcategory: user?.data?.business?.subcategory || "",
  };

  const validationSchema = Yup.object({
    websiteUrl: Yup.string().matches(urlRegex, "URL is not valid"),
  });

  const onSubmit = async (data) => {
    const userData = {
      ...data,
    };
    mutate(userData);
  };

  const { handleChange, values, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (category) {
      setSubcategories([]);
      setSubcategoryLbl("");
      setFieldValue("subcategory", "");
      const cat = categoryV2.find(({ value }) => value === category);
      setSubcategories(cat.subcategories);
    }
  }, [category, setFieldValue]);

  const getError = (key) => {
    return touched[key] && errors[key];
  };

  function getCatLabel(dataArr, category) {
    return dataArr.find(({ value }) => value === category)?.label ?? category;
  }

  function getSubCatLabel(dataArr, category, subcategory) {
    const catArr = dataArr.find(({ value }) => value === category);

    return catArr.find(({ value }) => value === subcategory).label;
  }

  return (
    <div className="bg-white p-5 rounded-md">
      <p className="text-[#292D32] font-semibold text-[20px]">Details</p>
      <p className="text-[#737373] text-s font-[450] py-2">View and update your company information</p>
      <hr />

      <form action="" onSubmit={handleSubmit} className="w-[80%] mt-[1em]">
        <div className="grid sm:grid-cols-2 gap-4 grid-cols-1">
          <SecondaryInput
            text="Company name"
            placeholder="Enter Company name"
            onChange={handleChange}
            name="businessName"
            type="text"
            value={values.businessName}
          />

          <SecondaryInput
            text="Company address"
            placeholder="Enter Company address"
            onChange={handleChange}
            name="companyAddress"
            type="text"
            value={values.companyAddress}
          />

          {/* <div>
            <p className="text-[#292D32] text-m font-[500] pb-2"> Industry</p>
            <DropDownWithSearch
              tag={values.industry || "Select an option"}
              options={options1}
              onSelect={(select) => setFieldValue("industry", select)}
              name="industry"
              value={values.industry}
            />
          </div> */}

          <div>
            <p className="text-[#292D32] text-m font-[500] pb-2"> Category</p>
            <DropDownWithSearch
              // text="Category (optional)"
              tag={categoryLbl || "Select an option"}
              options={categoryV2}
              onSelect={(select) => {
                setFieldValue("category", select.value);
                setCatgeory(select.value);
                setCategoryLbl(select.label);
              }}
              name="category"
              value={values.category}
            />
          </div>

          <div>
            <p className="text-[#292D32] text-m font-[500] pb-2"> Subcategory</p>
            <DropDownWithSearch
              // text="Category (optional)"
              tag={subcategoryLbl || "Select an option"}
              options={subcategories}
              onSelect={(select) => {
                setFieldValue("subcategory", select.value);
                setSubcategoryLbl(select.label);
              }}
              name="subcategory"
              value={values.subcategory}
            />
          </div>

          <div>
            <p className="text-[#292D32] text-m font-[500] pb-2">Company size</p>
            <Dropdown
              tag={values.companySize || "Select an option"}
              options={sizeOptions}
              onSelect={(select) => setFieldValue("companySize", select)}
              name="companySize"
              value={values.companySize}
            />
          </div>

          <SecondaryInput
            text="Website URL"
            placeholder="Enter Website URL"
            onChange={handleChange}
            name="websiteUrl"
            type="text"
            value={values.websiteUrl}
            error={getError("websiteUrl")}
          />
        </div>

        <div className="mt-[1em]">
          <BlueButton text="Update" />
        </div>
      </form>

      <div className=" mt-[1em] mb-[3em]">
        <hr />
      </div>

      <div>
        <SocialHandles />
      </div>
      <div className=" mt-[1em] mb-[3em]">
        <hr />
      </div>
    </div>
  );
};

export default Company;
