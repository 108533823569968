import ModalWithoutClose from "components/ModalWithoutClose";
import { FaTimes } from "react-icons/fa";
import BlueButton from "components/Button/BlueButton";
import { Link } from "react-router-dom";

const ViewOnlyNotificationModal = ({
  children,
  title,
  setShowViewOnlyModal,
  setAction,
  yesBtnText,
  noBtnText,
  actionType,
  img,
  isLoading,
  handleRemindMeOnNextVisit,
}) => {
  return (
    <ModalWithoutClose>
      <div className="w-auto h-auto  bg-white rounded-xl p-0 py-6 ">
        <div className="flex flex-col gap-2 h-full">
          <div className="flex flex-row justify-end px-2">
            <div
              className="text-[#737373] bg-[#ECECECB2] h-[30px] w-[30px] flex items-center justify-center rounded-full text-[20px] cursor-pointer "
              onClick={() => setShowViewOnlyModal(false)}
            >
              <FaTimes className="w-[10px] " />
            </div>
          </div>

          <div className="flex flex-col gap-10 items-center justify-center">
            <div className={`flex w-full items-center justify-center py-10  bg-blue-50`}>
              <img src={img} alt="" />
            </div>
          </div>
          {children}
          {/* <div className="flex flex-col gap-5 items-center justify-center  p-2 w-full">
            <BlueButton text={"Continue"} onClick={setShowViewOnlyModal} loading={isLoading} className="w-full" />
            <div>
              <Link
                to={""}
                className="text-sm text-primary-900 underline font-[700]"
                onClick={() => handleRemindMeOnNextVisit(true)}
              >
                Remind me on next visit
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </ModalWithoutClose>
  );
};

export default ViewOnlyNotificationModal;
