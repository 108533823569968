import { useMutation } from "@tanstack/react-query";
import { formatDate } from "lib/formatDate";
import { useState } from "react";

import SenderIDModal from "./SenderIDModal";
import { useStore } from "store";
import { deleteEmailId, deleteSMSId } from "services/senderIdService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import BlueButton from "components/Button/BlueButton";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import Compliance from "./Compliance";
import useOutsideClick from "hooks/useOutsideClick";
import { toTitleCase } from "lib/titleCase";
import { trackEvent } from "config/mixpanel";
import useLoggedInUser from "hooks/useLoggedInUser";
import dayjs from "dayjs";

const emailColumns = [
  { field: "email", header: "Email Address" },
  { field: "name", header: "Name" },
  { field: "createdAt", header: "Date Added" },
  { field: "verified", header: "Status" },
  { field: "viewProps", header: "" },
];

const smsColumns = [
  { field: "name", header: "Name" },
  { field: "createdAt", header: "Date Added" },
  { field: "verified", header: "Status" },
  { field: "viewProps", header: "" },
];

const SenderID = ({ sms, email }) => {
  const { user } = useLoggedInUser();
  const [open, setOpen] = useState();
  const toggleModal = (id) => {
    setOpen(open === id ? null : id);
  };
  const refer = useOutsideClick(() => setOpen(null));

  const { mutate } = useMutation({
    mutationFn: deleteSMSId,
    onSuccess: () => {
      Toast.success("SMS sender ID deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["smsSenderIDs"] });
      toggleModal();
    },
    onError: () => {},
  });

  const { mutate: mutateEmail } = useMutation({
    mutationFn: deleteEmailId,
    onSuccess: () => {
      Toast.success("Email sender ID deleted successfully");
      queryClient.invalidateQueries({ queryKey: ["emailSenderIDs"] });
      toggleModal();
    },
    onError: () => {},
  });
  const { wsPlan } = useStore();

  const handleDelete = (id) => {
    mutate(id);
  };

  const deleteEmail = (mail, name) => {
    mutateEmail({
      email: mail,
      name: name,
    });
  };

  const smsData = sms?.map((el) => ({
    id: el?._id,
    name: el?.name,
    createdAt: formatDate(el?.created_at),
    plan: el?.plan,
    amount: el?.amount,
    verified: (
      <>{el?.isActive ? <p className="text-[#22C55E]">Verified</p> : <p className="text-[#737373]">Not Verified</p>}</>
    ),
    viewProps: (
      <div className="relative">
        {el?.isDefault ? null : (
          <div className="flex items-center space-x-2 text-black cursor-pointer" onClick={() => toggleModal(el?._id)}>
            <BsThreeDots />
          </div>
        )}

        {open === el?._id ? (
          <div
            className="shadow-lg rounded-[5px] bg-white absolute w-[150px] z-[10] right-[2em] cursor-pointer"
            ref={refer}
          >
            <div
              className="flex items-center space-x-[5px] text-s border-b-[1px] p-[9px] text-[#EF4444] hover:text-[#d31212]"
              onClick={() => handleDelete(el?._id)}
            >
              <MdOutlineDelete />
              <p>Delete</p>
            </div>
          </div>
        ) : null}
      </div>
    ),
  }));

  const emailData = email?.map((el) => ({
    id: el?._id,
    name: el?.name,
    createdAt: formatDate(el?.dateAdded),
    email: el?.email,
    active: (
      <>{el?.isActive ? <p className="text-[#22C55E]">Verified</p> : <p className="text-[#737373]">Not Verified</p>}</>
    ),
    verified: <>{<p className="">{toTitleCase(el?.status)}</p>}</>,
    viewProps: (
      <div className="relative">
        {el?.isDefault ? null : (
          <div className="flex items-center space-x-2 text-black cursor-pointer" onClick={() => toggleModal(el?._id)}>
            <BsThreeDots />
          </div>
        )}

        {open === el?._id ? (
          <div
            className="shadow-lg rounded-[5px] bg-white absolute w-[150px] z-[10] right-[2em] cursor-pointer"
            ref={refer}
          >
            <div
              className="flex items-center space-x-[5px] text-s border-b-[1px] p-[9px] text-[#EF4444] hover:text-[#d31212]"
              onClick={() => deleteEmail(el?.email, el?.name)}
            >
              <MdOutlineDelete />
              <p>Delete</p>
            </div>
          </div>
        ) : null}
      </div>
    ),
  }));
  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="text-[#292D32] font-semibold text-[20px]">Sender ID : {sms?.length + email?.length}</p>
        <BlueButton
          text="New sender ID"
          onClick={() => toggleModal(1)}
          disabled={wsPlan === "starter" ? true : false}
        />
      </div>

      <div>
        <p className="font-semibold text-[18px] my-4">Registered Email ID</p>
        <table className="w-[100%]">
          <thead className=" text-s bg-[#F9FAFB]  border-b-[1px] ">
            <tr className=" h-[45px] px-7 text-left">
              {emailColumns &&
                emailColumns.map((head, i) => (
                  <th key={i} className="pl-4">
                    {head.header}{" "}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {emailData &&
              emailData.map((row, i) => (
                <tr key={i} className="h-[50px] border-b-[1px] text-s text-[#404040] font-[500]">
                  {emailColumns?.map((col, i) => (
                    <td key={i} className="pl-4">
                      {row[col.field]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="mt-[2em]">
        <p className="font-semibold text-[18px] my-4">Registered SMS ID</p>
        <table className="w-[100%]">
          <thead className=" text-s bg-[#F9FAFB]  border-b-[1px] ">
            <tr className=" h-[45px] px-7 text-left">
              {smsColumns &&
                smsColumns.map((head, i) => (
                  <th key={i} className="pl-4">
                    {head.header}{" "}
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {smsData &&
              smsData.map((row, i) => (
                <tr key={i} className="h-[50px] border-b-[1px] text-s text-[#404040] font-[500]">
                  {smsColumns?.map((col, i) => (
                    <td key={i} className="pl-4">
                      {row[col.field]}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {open === 1 ? <SenderIDModal toggleModal={toggleModal} /> : null}

      {open === 2 ? <Compliance toggleModal={toggleModal} /> : null}
    </div>
  );
};

export default SenderID;
