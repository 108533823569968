import Skeleton from "react-loading-skeleton";
import Typography from "utils/typography";
// import PieChart from "./PieChart";
import { useLocation } from "react-router-dom";
import totalScore from "assets/images/totalScore.png";
import promoters from "assets/images/promoters.png";
import passives from "assets/images/passives.png";
import detractors from "assets/images/detractors.png";

const MetricCard = ({ elm, loading, type, value, title, img }) => {
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const isNPS = locationArray.includes("nps");
  const case1 = isNPS ? "Promoters" : "Satisfied";
  const case2 = isNPS ? "Passives" : "Neutral";
  const case3 = isNPS ? "Detractors" : "Unsatisfied";

  // const pieColor = (type) => {
  //   switch (type) {
  //     case case1:
  //       return "text-green-500";
  //     case case2:
  //       return "text-yellow-500";
  //     case case3:
  //       return "text-red-500";
  //     default:
  //       return "text-primary-800";
  //   }
  // };

  const iconType = (type) => {
    switch (type) {
      case case1:
        return promoters;
      case case2:
        return passives;
      case case3:
        return detractors;
      default:
        return totalScore;
    }
  };

  return (
    <div className="flex items-center justify-between  w-full cursor-pointer  px-4 pb-2">
      <div className="flex items-center space-x-4">
        <img src={img ? img : (iconType(type) ?? img)} alt={elm.id} className="w-[30px]" />
        <Typography>{title}</Typography>
      </div>

      {loading ? <Skeleton count={1} className="w-[40%]" /> : <Typography.H3>{value}</Typography.H3>}

      {/* <div>
        <img src={iconType(type) ?? img} alt={elm.id} className="w-[30px]" />
      </div> */}

      {/* <div className="w-[70%] flex justify-center">
        <PieChart className={pieColor(type)} value={loading ? 0 : value} />
        <div className="absolute w-full flex flex-col items-center bottom-4">
          {loading ? <Skeleton count={1} className="w-[40%]" /> : <Typography.H3>{value}</Typography.H3>}

          <Typography.SubText>{title}</Typography.SubText>
        </div>
      </div> */}
    </div>
  );
};

export default MetricCard;
