import React from "react";
import classNames from "classnames";
import Typography from "utils/typography";
import { motion } from "framer-motion";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const Tabs = ({ step, tabs, clickFn, lookup }) => {
  const { isMobile } = useSideBarStore();
  return (
    <div className="flex justify-start items-center space-x-6 w-[100%] border-b-[1px]">
      {tabs.map((el, idx) => {
        const IconComponent = el.icons;
        return (
          <motion.div
            key={idx}
            className={classNames(
              `cursor-pointer md:text-sm text-lg font-secondary font-medium border-[1px] border-gray-300 border-b-0 py-3 px-6 rounded-t-md flex items-center space-x-2`,
              step === el[lookup]
                ? "border-t-primary-800  border-l-primary-800  border-r-primary-800 text-auxiliary "
                : "text-black",
            )}
            onClick={() => clickFn(el)}
            style={{ width: isMobile ? "100%" : `${100 / tabs.length}%` }}
            animate={{ backgroundColor: step === el[lookup] ? "#EBF1FF" : "#fff" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {step === el[lookup] ? (
              <IconComponent fill1="#95ADFF" fill2="#1D19AF" />
            ) : (
              <IconComponent fill1={"#D4D4D4"} fill2={"#404040"} />
            )}
            <Typography.SubText className={`${step === el[lookup] ? "text-primary-800" : ""} `}>
              {" "}
              {el.name}
            </Typography.SubText>

            <div
              className={`w-[20px] h-[20px]  p-2 flex items-center justify-center ${
                step === el[lookup] ? "bg-primary-200" : "bg-slate-200"
              } rounded-md`}
            >
              <Typography.MicroText className={`font-semibold ${step === el[lookup] ? "text-primary-800" : ""}`}>
                {" "}
                {el.number}
              </Typography.MicroText>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};

export default Tabs;
