import React, { useState } from "react";
import Tables from "./Tables";
import { pricingplans } from "../data";
import PlanCard from "../components/PlanCard";
import ModalWithoutClose from "components/ModalWithoutClose";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import { FaTimes } from "react-icons/fa";
import MobileTables from "./MobileTables";

const Plans = ({ conversionRate, values, toggleValue }) => {
  const [showExploreAllFeaturesModal, setShowExploreAllFeaturesModal] = useState(false);
  const { isMobile } = useSideBarStore();

  const exploreAllFeatures = (
    <div className="bg-white my-[2rem] w-auto h-[50%] py-6">
      <div className="flex flex-row justify-end px-2">
        <div
          className="text-[#737373] bg-[#ECECECB2] h-[30px] w-[30px] flex items-center justify-center rounded-full text-[20px] cursor-pointer "
          onClick={() => setShowExploreAllFeaturesModal(false)}
        >
          <FaTimes className="w-[10px] " />
        </div>
      </div>
      <p className="font-secondary font-[700] text-[24px] text-center" id="explore">
        Explore all our features
      </p>
      <div className="flex justify-center sm:py-0 py-2">
        <p className="text-[14px] text-center px-3">
          Discover the benefits of each pricing tier and choose the one that best fit your business.
        </p>
      </div>
      <div className="flex justify-center">
        <div className="w-full px-4 flex sm:items-center sm:justify-center sm:overflow-visible overflow-y-scroll  sm:h-auto h-[350px]">
          {isMobile ? <MobileTables toggleValue={values} /> : <Tables toggleValue={values} />}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div id="plans">
        <div className="justify-center border rounded-[20px] overflow-hidden items-start h-fit mt-10  md:flex sm:flex-row flex-col">
          {pricingplans.map((el, i) => (
            <PlanCard
              title={el.title}
              subtitle={el.subtitle}
              plan={el.plan}
              planId={el.planId}
              category={el.category}
              tag={el.tag}
              info={el.info}
              featureTitle={el.featureTitle}
              features={el.features}
              amount={el.amount}
              responses={el.responses}
              conversionRate={conversionRate}
              toggle={toggleValue}
              key={i}
              setShowExploreAllFeaturesModal={setShowExploreAllFeaturesModal}
            />
          ))}
        </div>
      </div>

      {!isMobile
        ? exploreAllFeatures
        : showExploreAllFeaturesModal && <ModalWithoutClose>{exploreAllFeatures}</ModalWithoutClose>}
    </div>
  );
};

export default Plans;
