import BlueButton from "components/Button/BlueButton";
import Modal from "components/Modal";
import { statusColor } from "lib/statusColor";
import { useState } from "react";
import useLoggedInUser from "hooks/useLoggedInUser";
import PurpleState from "assets/images/purpledrop.png";
import { BsFileImage } from "react-icons/bs";
import { ScaleLoader } from "react-spinners";
import { byteConverter } from "lib/byteConverter";
import { useStore } from "store";
import { uploadDocument } from "utils/upload";
import SelectInput from "components/Inputs/SelectInput";
import { proofData } from "../../data";
import { useMutation } from "@tanstack/react-query";
import { updateBusiness } from "services/userService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";
import GreyButton from "components/Button/GreyButton";

const IndividualDocUpload = ({ certName, status, image, docType }) => {
  const { user } = useLoggedInUser();
  const { onBoarding } = useStore();
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(null);
  const [fileData, setFileData] = useState({
    incopCert: user?.data?.incopCert?.pathToFile,
    proofOfBusAdd: user?.data?.proofOfBusAdd?.pathToFile,
    incopCertName: "",
    proofOfBusAddName: "",
    fileType: user?.data?.proofOfBusAdd?.documentType,
  });
  const previewDoc = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  const fileAddressName = docType === "proofOfAddr" ? fileData.proofOfBusAddName : fileData.incopCertName;
  const fileAddress = docType === "proofOfAddr" ? fileData.proofOfBusAdd : fileData.incopCert;

  console.log(fileData);

  const disabled = !fileData.incopCert && !fileData.incopCertName && !fileData.fileType && !fileData.proofOfBusAdd;

  const handleHover = (id) => {
    setHover(id === hover ? null : id);
  };

  const checkSelect = async (e, x) => {
    let file = e.target.files[0];
    let formData = new FormData();
    if (x === "incopCert") {
      setFileData({
        ...fileData,
        incopCertName: file,
      });
      formData.append("document", file);
      const response = await uploadDocument(formData);
      setFileData({ ...fileData, incopCert: `https://${response?.data?.filePath}`, incopCertName: file });
    } else if (x === "proofOfBusAdd") {
      setFileData({
        ...fileData,
        proofOfBusAddName: file,
      });
      formData.append("document", file);
      const response = await uploadDocument(formData);
      setFileData({ ...fileData, proofOfBusAdd: `https://${response?.data?.filePath}`, proofOfBusAddName: file });
    }
  };

  const { mutate } = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      Toast.success("Document uploaded successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      handleClose();
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const saveDoc = () => {
    const userData = {
      incopCert: fileData.incopCert,
      proofOfBusAdd: {
        path: fileData.proofOfBusAdd,
        documentType: fileData.fileType,
      },
    };

    mutate({ complianceDocuments: userData });
  };

  return (
    <div className="mt-5 space-y-3 ">
      <div>
        <p>{certName}</p>
      </div>
      <div>
        <div
          className={`h-[280px] w-[254px] border ${
            hover === 1 ? "border-primary-400" : "border-gray-200 "
          } rounded-lg overflow-hidden mb-1 relative cursor-pointer`}
          onMouseEnter={() => handleHover(1)}
          onMouseLeave={() => handleHover(null)}
        >
          <div className="w-[70px] absolute top-5 left-[11rem]">{statusColor(status)}</div>

          <div className="bg-primary-100 p-16 ">
            <img src={image} alt="" />
          </div>

          <div className="bg-white h-[58px] absolute bottom-[0px] left-0 shadow-[0_0px_10px_-5px_rgba(0,0,0,0.3)] rounded-lg w-full">
            <div className="flex items-center justify-center space-x-3 selection h-full px-5">
              <BlueButton
                text="Upload"
                css="rounded-xl font-[800] bg-primaryBlue text-white"
                variant="filled"
                onClick={previewDoc}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {open && (
          <Modal title="Document upload" onClose={handleClose}>
            <div className="relative overflow-hidden">
              <div className="p-5">
                <div
                  className={`w-full rounded-t-xl overflow-hidden flex justify-center ${
                    !fileAddressName && fileAddress === undefined ? "h-[250px]" : "h-[400px]"
                  }  relative`}
                >
                  <div className="w-full">
                    <div className="flex items-center">
                      {!fileAddressName && fileAddress === undefined ? (
                        <div className="">
                          <div>
                            <p>{docType === "proofOfAddr" ? "Proof of address" : "Certificate of Incorporation"}</p>
                            {docType === "proofOfAddr" ? (
                              <div className="flex space-x-2 items-center w-full">
                                <div className="flex justify-between items-center w-full ">
                                  <div className="w-full">
                                    <SelectInput
                                      options={proofData}
                                      onChange={(e) =>
                                        setFileData({
                                          ...fileData,
                                          fileType: e.target.value,
                                        })
                                      }
                                      css="w-full"
                                      value={fileData.fileType}
                                      name="fileType"
                                    />
                                  </div>

                                  <div className="flex items-center justify-center h-[100%]">
                                    {/*{checkStatus(user?.data?.proofOfBusAdd?.status)}*/}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>

                          <div className="border-[1px] border-dashed border-[#D0D5DD] rounded-[8px] h-[120px] w-[500px] mt-5 bg-[#FAFAFA] flex flex-col items-center text-ss justify-center text-[#737373] space-y-2 relative">
                            <img src={PurpleState} alt="PurpleState" className="w-[60px]" />
                            <p>Drag and drop PNG or JPEG file or browse computer </p>
                            <p>Max file size: 10MB</p>
                            <input
                              type="file"
                              name={docType === "proofOfAddr" ? "proofOfBusAdd" : "incopCert"}
                              id=""
                              className="text-ss absolute top-0 left-0 bottom-0 right-0 opacity-0"
                              accept=".jpg, .jpeg, .png"
                              onChange={(e) =>
                                checkSelect(e, docType === "proofOfAddr" ? "proofOfBusAdd" : "incopCert")
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex  w-[500px]  border-[1px] border-[#DFE5EC] rounded-[8px]  h-[300px] items-center px-4 justify-between">
                          <div className="flex items-center space-x-4">
                            {fileAddress ? null : (
                              <div className="bg-[#29085C1A] text-primary-800 w-[40px] h-[40px] rounded-full flex items-center justify-center ">
                                <BsFileImage />
                              </div>
                            )}

                            <div>
                              <p className="text-s">
                                {fileAddress === "" ? (
                                  fileAddressName?.name?.substring(0, 40)
                                ) : (
                                  // : fileData.proofOfBusAdd?.substring(0, 40)}
                                  <img src={fileAddress} alt="" />
                                )}
                                {fileAddress ? "..." : <ScaleLoader width={2} height={15} color="#29085C" />}
                              </p>
                              <p className="text-ss text-[#A3A3A3]">{byteConverter(fileAddressName?.size)}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {onBoarding.compliance === false && fileAddress ? (
                        <p
                          className="text-s text-primary-800 cursor-pointer pl-3"
                          onClick={() => {
                            const fileAddressName = docType === "proofOfAddr" ? "proofOfBusAddName" : "incopCertName";
                            const fileAddress = docType === "proofOfAddr" ? "proofOfBusAdd" : "incopCert";

                            setFileData((prevState) => ({
                              ...prevState,
                              [fileAddress]: undefined,
                              [fileAddressName]: undefined,
                            }));
                          }}
                        >
                          Remove
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute bottom-0 h-[70px] bg-white left-0 w-full border-t">
                <div className="flex justify-end pt-[1.5rem] pr-[3rem] space-x-5">
                  <GreyButton text="cancel" type="button" onClick={handleClose} />
                  <BlueButton text="Upload" type="button" onClick={saveDoc} disabled={disabled} />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default IndividualDocUpload;
