import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OnlineSurveyTab from "./OverviewTabs/OnlineSurveyTab";
import FieldInterviewTab from "./OverviewTabs/FieldInterviewTab";
import BrandTrackingTab from "./OverviewTabs/BrandTrackingTab";
import Typography from "utils/typography";
import BlueButton from "components/Button/BlueButton";
import researchGif from "assets/images/researchGif.png";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/Tooltip";
import { useSideBarStore } from "store/sidebar/sidebarStore";
import { useStore } from "store";
import ViewOnlyNotificationModal from "components/Modals/ViewOnlyNotificationModal";
import imgageSVG from "assets/images/onboarding.png";

const tabs = [
  { id: 1, name: "Online surveys" },
  { id: 2, name: "Field interviews" },
  // { id: 3, name: "Brand tracking" },
];
const Home = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { isMobile } = useSideBarStore();
  const [showViewOnlyModal, setShowViewOnlyModal] = useState(false);
  const { remindMeOnNextVisit, setRemindMeOnNextVisit } = useStore();

  const handleTabChange = (e) => {
    setStep(e);
  };

  const displayStep = () => {
    switch (step) {
      case 1:
        return <OnlineSurveyTab />;
      case 2:
        return <FieldInterviewTab />;
      case 3:
        return <BrandTrackingTab />;
      default:
    }
  };

  useEffect(() => {
    if (remindMeOnNextVisit) {
      setShowViewOnlyModal(false);
    } else {
      setShowViewOnlyModal(true);
    }
  }, []);

  const handleRemindMeOnNextVisit = () => {
    setRemindMeOnNextVisit(true);
    setShowViewOnlyModal(false);
  };

  return (
    <div className="">
      <div className="border-[1px] rounded-lg sm:h-[240px] h-auto w-[100%] bg-white ">
        <div className="bg-contactBg bg-cover h-[100%] flex items-center justify-center sm:flex-row flex-col ">
          <div className="sm:w-[40%] w-[100%] space-y-4">
            <Typography.H3 className="font-semibold text-primary-800">Valuable insights on the go</Typography.H3>

            <Typography.SubText className="w-[60%]">
              Get fast and actionable insights from your target market or existing customers.
            </Typography.SubText>

            <BlueButton text="Get started" onClick={() => navigate("setup")} />
          </div>

          <div className="sm:w-[40%] w-[100%] h-[100%]">
            <img src={researchGif} alt="researchGif" className="h-[100%]" />
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2 pt-5">
        <Typography.Text>Overview</Typography.Text>
        <Tooltip text="This shows a summary of the number of surveys sent, average completion and drop-off rates." />
      </div>

      <div className="flex space-x-4 mt-4">
        {tabs.map((el) => {
          return (
            <Typography.SubText
              className={` pb-[4px] cursor-pointer ${
                step === el.id ? "border-b-[3px] text-[#0F172A] border-primary-800 font-semibold " : "text-[#6C6685]"
              } `}
              onClick={el.id !== 4 ? () => handleTabChange(el.id) : null}
              key={el.id}
            >
              {el.name}
            </Typography.SubText>
          );
        })}
      </div>
      <div className="h-[1px] w-[100%] bg-[#DFE5EC] mb-4"></div>

      <div>{displayStep()}</div>

      {isMobile && showViewOnlyModal && (
        <ViewOnlyNotificationModal
          setShowViewOnlyModal={setShowViewOnlyModal}
          img={imgageSVG}
          handleRemindMeOnNextVisit={handleRemindMeOnNextVisit}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="text-[16px] font-[600]">View-only feature</div>
            <div className="text-[12px]">This feature is designed for larger screens to</div>
            <div className="text-[12px]">provide the best experience.To access,please</div>
            <div className="text-[12px]">switch to a laptop or desktop.</div>
          </div>
          <div className="flex flex-col gap-5 items-center justify-center  p-2 w-full">
            <BlueButton text={"Continue"} onClick={setShowViewOnlyModal} loading={false} className="w-full" />
            <div>
              <Link
                to={""}
                className="text-sm text-primary-900 underline font-[700]"
                onClick={() => handleRemindMeOnNextVisit(true)}
              >
                Remind me on next visit
              </Link>
            </div>
          </div>
        </ViewOnlyNotificationModal>
      )}
    </div>
  );
};

export default Home;
