import { useState, useEffect } from "react";
import ChartCard from "../../../../Insight/components/ChartCard";
import gender from "assets/svgs/gender.svg";
import emptyGender from "assets/svgs/emptyGender.svg";

const CPInsight = ({ nameKey, overViewData }) => {
  const [genderAnalytics, setGenderAnalytics] = useState(null);

  useEffect(() => {
    // console.log("over::" ,overViewData)
    const genderAnalytics = overViewData?.cpData
      ? Object.entries(overViewData.cpData).filter(([key, value]) => key.includes("gender"))
      : [];
    setGenderAnalytics(genderAnalytics && genderAnalytics[0] ? genderAnalytics[0][1].analytics : null);
  }, [overViewData]);

  return genderAnalytics ? (
    <div className="flex flex-col items-center justify-center w-[100%]">
      <div className=" flex flex-row justify-center border-3 py-5">
        <img src={gender} alt="My SVG" width="182" height="100" />
      </div>
      <div className="grid grid-cols-2 gap-5  border-0 p-2 mx-10">
        {Object.entries(genderAnalytics).map(([key, value], index) => (
          <div className="flex flex-row justify-between border-2 shadow p-2 rounded gap-5">
            <div className="flex flex-row justify-center items-center gap-1">
              <div className={`w-3 h-3 border rounded ${index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"}`}></div>
              <div className="text-xs">{key}</div>
            </div>
            <div className="text-xs">{value.percentage}%</div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center py-20 gap-10">
      <div className="flex flex-row items-center justify-center">
        <img src={emptyGender} alt="empty gender details" width={"81.78px"} height={"53.16px"} />
      </div>
      <div className="text-center font-light text-sm ">Your contacts distribution will be displayed here</div>
    </div>
  );
};

export default CPInsight;
