import React from "react";
// interface IProgressbarProps {
//   progress?: number;
//   total?: number;
//   percent?: string;
// }

const ProgressBar = ({ percent, className = "w-[100%]" }) => {
  const percentage = percent?.toString();
  return (
    <div className={`bg-[#D7DDEA] rounded-full h-1 ${className}`}>
      <div
        className={` bg-[#00DA7A] h-1 rounded-full  transition-width duration-300 ease-in-out`}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
