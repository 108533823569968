import { Link } from "react-router-dom";
import Logo from "assets/images/newLogo.png";
import GreyButton from "components/Button/GreyButton";
import { getEmail } from "helpers/authHelper";
import { useMutation } from "@tanstack/react-query";
import { resendAccountVerification } from "services/authService";
import Toast from "config/toast";

export default function EmailSent() {
  const email = getEmail();
  const { mutate } = useMutation({
    mutationFn: resendAccountVerification,
    onSuccess: () => {
      Toast.success("Email sent.");
    },
  });

  const handleClick = () => {
    mutate({
      email,
    });
  };

  return (
    <div className="w-[90%] mx-auto">
      <div className="mt-[2em]">
        <Link to="/" className=" ">
          <img src={Logo} alt="Mooyi logo" className="w-[100px]" />
        </Link>

        <div></div>
      </div>

      <div className="sm:w-[40%] mt-[10%] mx-auto">
        <p className="text-[24px] font-semibold">Activate your account</p>

        <p className="my-[2em] text-s">
          We’ve sent a message to <span className="text-green">{email}</span> with a link to activate your account.{" "}
        </p>

        <div className="space-x-4">
          <GreyButton text="Resend activation mail" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
}
