import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useSideBarStore = create(
  devtools((set) => ({
    sidebar: "true",
    setSideBar: (data) => {
      set(() => ({ sidebar: data }));
    },
    isMobile: false,
    setIsMobile: (data) => {
      set(() => ({ isMobile: data }));
    },
    isMobileSidebarOpen: false,
    setIsMobileSidebarOpen: (data) => {
      set(() => ({ isMobileSidebarOpen: data }));
    },
  })),
);
