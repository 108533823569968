import { Tag } from "assets/icons/contacts/Tag";
import { ContactIcon } from "assets/icons/sidebar";
import React, { useEffect, useMemo, useState } from "react";
import AllContacts from "./AllContacts";
import Tags from "./Tags";
import { Segment } from "assets/icons/contacts/Segment";
import { useNavigate, useSearchParams } from "react-router-dom";
import Toast from "config/toast";
import { useQuery } from "@tanstack/react-query";
import { HiChevronDown, HiChevronRight } from "react-icons/hi2";
import useOutsideClick from "hooks/useOutsideClick";
import { getAllContacts } from "services/contactService";
import CreateCustomFieldModal from "./CustomFields/CreateCustomFieldModal";
import { CreateCustomSuccessModal } from "./CustomFields/CreateCustomSuccessModal";
import { useContactStore } from "store/surveyStore/contactStates";
import { getAllTags } from "services/contactService";
import { getAllSegments } from "services/contactService";
import { createCustom } from "services/contactService";
import Segments from "./Segments";
import AllLeads from "./Leads";
import Typography from "utils/typography";
import { getAllLead } from "services/contactService";
import { useSideBarStore } from "store/sidebar/sidebarStore";

const ContactTabs = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [dropDown, setDropdown] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setopenModal] = useState(null);
  const [searchVal] = useState("");
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const [contactItemPerPage, setContactItemPerPage] = useState(10);
  const { contactTabs, setContactsTabs } = useContactStore();
  const [tagPageCount, setTagPageCount] = useState(10);
  const [tagPage, setTagPage] = useState(1);
  const [sortBy] = useState("name");
  const [contactQueryData, setContactQueryData] = useState({
    from: "",
    to: "",
    updatedFrom: "",
    updatedTo: "",
    gender: [],
  });
  const { setSideBar, isMobile, setIsMobileSidebarOpen, isMobileSidebarOpen } = useSideBarStore();
  console.log(contactQueryData);

  const tabs = [
    { id: 1, name: "All contacts", value: 0, icon: ContactIcon, count: contactTabs?.allContacts },
    { id: 2, name: "All leads", value: 1, icon: ContactIcon, count: contactTabs?.allLeads },
    { id: 3, name: "Tags", value: 2, icon: Tag, count: contactTabs?.tags },
    { id: 4, name: "Segments", value: 3, icon: Segment, count: contactTabs?.segment },
  ];

  // ============================ Query Functions ===========================================

  console.log(contactTabs, "count");
  const {
    data: contact,
    isPending: isFetchingContacts,
    isSuccess: isSuccessContacts,
    isError: isErrorContacts,
    error: errorContacts,
    refetch: refetchContacts,
  } = useQuery({
    queryKey: [
      "allContactTab",
      contactCurrentPage,
      searchVal,
      contactItemPerPage,
      contactQueryData?.gender[0],
      contactQueryData?.from,
      contactQueryData?.to,
      contactQueryData?.updatedFrom,
      contactQueryData?.updatedTo,
    ],
    queryFn: () =>
      getAllContacts({
        page: contactCurrentPage,
        sortBy: "name",
        searchVal: searchVal.toLowerCase(),
        perPage: contactItemPerPage,
        gender: contactQueryData?.gender[0],
        from: contactQueryData?.from,
        to: contactQueryData?.to,
        updatedFrom: contactQueryData?.updatedFrom,
        updatedTo: contactQueryData?.updatedTo,
      }),
  });

  const {
    data: tag,
    isPending: isFetchingTags,
    isSuccess: isSuccessTags,
    isError: isErrorTags,
    error: errorTags,
  } = useQuery({
    queryKey: ["allTags", sortBy, tagPage, tagPageCount],
    queryFn: () => getAllTags(sortBy, tagPage, tagPageCount),
    // queryKey: ["allTags", sortBy],
    // queryFn: () => getAllTags(sortBy),
  });

  const {
    data: newAllLeads,
    isPending: isFetchingLeads,
    isSuccess: isSuccessLeads,
    isError: isErrorLeads,
    error: errorLeads,
  } = useQuery({
    queryKey: ["newAllLeads"],
    queryFn: getAllLead,
  });

  const {
    data: segment,
    isPending: isFetchingSegment,
    isSuccess: isSuccessSegment,
    isError: isErrorSegment,
    error: errorSegment,
  } = useQuery({
    queryKey: ["allSegment", sortBy],
    queryFn: () => getAllSegments(sortBy),
  });

  useEffect(() => {
    refetchContacts();
  }, [contactQueryData, refetchContacts]);

  // Single useEffect to handle onSuccess and onError
  useEffect(() => {
    if (isSuccessContacts) {
      setContactsTabs({ allContacts: contact?.totalContacts });
    }
    if (isSuccessTags && tag) {
      setContactsTabs({ tags: tag?.count });
    }
    if (isSuccessLeads && newAllLeads) {
      setContactsTabs({ allLeads: newAllLeads?.length });
    }
    if (isSuccessSegment && segment) {
      setContactsTabs({ segment: segment?.length });
    }

    // Handling errors for each query
    if (isErrorContacts && errorContacts) Toast.error(errorContacts.message);
    if (isErrorTags && errorTags) Toast.error(errorTags.message);
    if (isErrorLeads && errorLeads) Toast.error(errorLeads.message);
    if (isErrorSegment && errorSegment) Toast.error(errorSegment.message);
  }, [
    contact?.totalContacts,
    errorContacts,
    errorLeads,
    errorSegment,
    errorTags,
    isErrorContacts,
    isErrorLeads,
    isErrorSegment,
    isErrorTags,
    isSuccessContacts,
    isSuccessLeads,
    isSuccessSegment,
    isSuccessTags,
    newAllLeads,
    segment,
    setContactsTabs,
    tag,
  ]);

  console.log(tag, "tag data structure");

  const { mutate: create } = {
    mutationFn: createCustom,
    onSuccess: () => {
      Toast.success("custom field created successfully");
      navigate("/contacts/all-contacts?tab=3");
      handleModal(6);
    },
    onError: (error) => {
      Toast.error(error);
    },
  };

  const switchTab = (value) => {
    setTabValue(value);
    setSearchParams({ tab: value });
  };

  const toggleDropdown = (id) => {
    setDropdown(dropDown === id ? null : id);
  };

  const refer = useOutsideClick(toggleDropdown);

  const handleModal = (id) => {
    setopenModal(openModal === id ? null : id);
  };

  useEffect(() => {
    const navValue = parseInt(searchParams.get("tab") || "0");
    setTabValue(navValue);
  }, [searchParams]);

  const handleSubmit = (data) => {
    const payload = {
      fieldName: data.name,
      fieldType: data.dataType,
    };
    create(payload);
    handleModal(6);
  };

  // const [pageCountStart, pageCountEnd] = useMemo(() => {
  //   const totalValue = contact?.totalContacts;

  //   let startValue = (contactCurrentPage - 1) * 10;
  //   startValue = startValue + 1;

  //   let endValue = Math.min(contactCurrentPage * 10, totalValue);
  //   return [startValue, endValue];
  // }, [contactCurrentPage, contact?.totalContacts]);

  const handlePrev = () => {
    setContactCurrentPage(contactCurrentPage - 1);
    // setSelectArray([]);
  };

  const handleNext = () => {
    setContactCurrentPage(contactCurrentPage + 1);
  };

  const displayButton = () => {
    switch (tabValue) {
      case 0:
        return (
          <div>
            <div
              className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
              onClick={() => toggleDropdown(1)}
            >
              <p className="text-sm font-medium text-white">Create new contact</p>
              <HiChevronRight />
            </div>

            {dropDown === 1 ? (
              <div
                className="bg-white shadow-activeShadow rounded-[8px] w-[150px] absolute left-[13rem] cursor-pointer z-[100] top-[2.5rem]"
                ref={refer}
              >
                <p
                  className="text-[#737373] hover:bg-gray-200 text-xs space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/create")}
                >
                  Individual entry
                </p>

                <p
                  className="text-[#737373] hover:bg-gray-200 text-xs space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/upload")}
                >
                  Upload a list
                </p>
              </div>
            ) : null}
          </div>
        );
      case 1:
        return (
          <div>
            <div
              className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
              onClick={() => toggleDropdown(1)}
            >
              <p className="text-sm font-medium text-white">Create new contact</p>
              <HiChevronDown />
            </div>
            {dropDown === 1 ? (
              <div
                className="bg-white shadow-activeShadow rounded-[8px] w-[250px] absolute left-0 cursor-pointer z-[100]"
                ref={refer}
              >
                <p
                  className="text-[#737373] hover:bg-gray-200 text-s space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/create")}
                >
                  Individual entry
                </p>

                <p
                  className="text-[#737373] hover:bg-gray-200 text-s space-x-2 border-b-[1px] p-3 "
                  onClick={() => navigate("/contacts/upload")}
                >
                  Upload a list
                </p>
              </div>
            ) : null}
          </div>
        );
      case 2:
        return (
          <div
            className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
            onClick={() => navigate("/contacts/tag/create")}
          >
            <p className="text-sm font-medium text-white">Create new tag</p>
          </div>
        );
      case 3:
        return (
          <div
            className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
            onClick={() => navigate("/contacts/segment/create/manual")}
          >
            <p className="text-sm font-medium text-white">Create new segment</p>
          </div>
        );
      case 4:
        return (
          <div
            className="w-[180px] h-[45px] border-[1px] rounded-md text-sm flex items-center justify-center space-x-2 bg-primary-800 text-white cursor-pointer my-5"
            onClick={() => handleModal(4)}
          >
            <p className="text-sm font-medium text-white">Create new field</p>
          </div>
        );
      default:
    }
  };

  const displayTab = () => {
    switch (tabValue) {
      case 0:
        return (
          <AllContacts
            data={contact}
            dataCount={tabs[0].count}
            isFetchingContacts={isFetchingContacts}
            setCurrentPage={setContactCurrentPage}
            currentPage={contactCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            setContactItemPerPage={setContactItemPerPage}
            contactItemPerPage={contactItemPerPage}
            contactQueryData={contactQueryData}
            setContactQueryData={setContactQueryData}
          />
        );
      case 1:
        return (
          <AllLeads
            data={newAllLeads}
            isFetchingContacts={isFetchingLeads}
            // pageCountStart={pageCountStart}
            // pageCountEnd={pageCountEnd}
            currentPage={contactCurrentPage}
            setCurrentPage={setContactCurrentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <div>
            <Tags
              tag={tag?.tags}
              isFetchingTags={isFetchingTags}
              dataCount={tabs[2].count}
              tagPage={tagPage}
              setTagPage={setTagPage}
              setTagPageCount={setTagPageCount}
              tagPageCount={tagPageCount}
            />
          </div>
        );
      case 3:
        return <Segments segment={segment} isFetching={isFetchingSegment} />;
      default:
    }
  };

  return (
    <div className="relative p-5 border rounded-md bg-white  overflow-x-auto h-[calc(100vh-80px)] overflow-y-auto ">
      {!isMobile && displayButton()}
      <div className="sm:grid sm:grid-cols-4 sm:gap-x-[1em] sm:w-[100%]  flex gap-x-[30px] w-[100%] overflow-x-scroll no-scrollbar ">
        {tabs.map((el, i) => {
          const IconComponent = el.icon;
          return (
            <div
              key={i}
              className={`${
                tabValue === el.value ? " border-primary-600" : ""
              } border cursor-pointer border-b-0 py-3 px-2 rounded-t-md  sm:w-auto w-[100%]`}
              onClick={() => switchTab(el.value)}
            >
              <div className="flex items-center space-x-3 w-[100%] ">
                <IconComponent
                  fill1={tabValue === el.value ? "#95ADFF" : "#D4D4D4"}
                  fill2={tabValue === el.value ? "#1D19AF" : "#404040"}
                />
                <div className="flex items-center space-x-5 text-[14px]">
                  <Typography.SubText className={`${tabValue === el.value ? "font-[600] text-primary-800" : ""}`}>
                    {el.name}
                  </Typography.SubText>
                  <div
                    className={`w-[24px] h-[24px] rounded flex items-center justify-center bg-gray-200 text-gray-500 ${
                      tabValue === el.value ? "font-[700] text-primary-800 bg-primary-200" : ""
                    }`}
                  >
                    <Typography.SubText>{el.count}</Typography.SubText>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="border-[1px]"></div>
      <div>{displayTab()}</div>
      {openModal === 4 ? <CreateCustomFieldModal handleSubmitClick={handleSubmit} handleModal={handleModal} /> : null}
      {openModal === 6 ? <CreateCustomSuccessModal handleModal={handleModal} /> : null}
    </div>
  );
};

export default ContactTabs;
