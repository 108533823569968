import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundDown } from "react-icons/io";
import Typography from "utils/typography";

const CustomTable = ({ rows, columns }) => {
  const navigate = useNavigate();
  return (
    <table className="min-w-full border-collapse border border-gray-300 text-left h-auto">
      <thead>
        <tr className="bg-gray-50 text-center">
          {columns.map((item, index) => {
            return index === 0 ? (
              <th className="flex flex-row px-4 py-2 border-b border-gray-300  text-gray-600   font-[400] text-center ">
                <div className="">
                  <Typography.MicroText>{item.headerName}</Typography.MicroText>
                </div>
                <IoIosArrowRoundDown />
              </th>
            ) : (
              <th className="px-4 py-2 border-b border-gray-300  text-gray-600 font-[400] text-left">
                <Typography.MicroText>{item.headerName}</Typography.MicroText>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr
            key={row.id}
            className="bg-white-100 h-[55px] text-left text-sm font-secondary text-gray-600 hover:bg-gray-100 border-b-[1px] hover:cursor-pointer"
            onClick={() => {
              navigate(`/contacts/tag/${row.id}/${row.name}`);
            }}
          >
            <td className="text-sm py-2 px-4">
              {" "}
              <Typography.MicroText>{row.name}</Typography.MicroText>
            </td>
            <td className="text-sm py-2 px-4">
              <Typography.MicroText>{row.size}</Typography.MicroText>
            </td>
            <td className="text-sm py-2 px-4">
              <Typography.MicroText>{row.lastUsed}</Typography.MicroText>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CustomTable;
