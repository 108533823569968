import React from "react";
import { useFormik } from "formik";
import facebook from "assets/images/socials/facebook.png";
import instagram from "assets/images/socials/instagram.png";
import twitter from "assets/images/socials/icons8-x-50.png";
import linkedIn from "assets/images/socials/Frame 1000004579.png";
import BlueButton from "components/Button/BlueButton";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useMutation } from "@tanstack/react-query";
import { updateBusiness } from "services/userService";
import Toast from "config/toast";
import { queryClient } from "config/gateway";

const socialArray = [
  { name: "facebook", icon: facebook },
  { name: "instagram", icon: instagram },
  { name: "twitter", icon: twitter },
  { name: "linkedIn", icon: linkedIn },
];

const SocialHandles = () => {
  const { user } = useLoggedInUser();

  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: updateBusiness,
    onSuccess: () => {
      Toast.success("Social media handles updated successfully");
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    },
    onError: (error) => {
      Toast.error(error);
    },
  });

  const onSubmit = async (data) => {
    const userData = {
      socialMedia: {
        facebook: data.facebook,
        twitter: data.twitter,
        instagram: data.instagram,
        linkedIn: data.linkedIn,
      },
    };

    mutate(userData);
  };

  const initialValues = {
    facebook: user?.data?.socialMedia?.facebook || "",
    instagram: user?.data?.socialMedia?.instagram || "",
    twitter: user?.data?.socialMedia?.twitter || "",
    linkedIn: user?.data?.socialMedia?.linkedIn || "",
  };

  const { handleChange, values, handleSubmit, handleBlur } = useFormik({
    initialValues,
    onSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <div>
      <p className="text-[20px] font-[500]">Social media handles</p>
      <form action="" onSubmit={handleSubmit}>
        <div className="w-[80%] mt-5">
          <div className="grid sm:grid-cols-2 gap-4 grid-cols-1">
            {socialArray.map((item, i) => (
              <div key={i} className="border bg-white rounded-[4px] h-[40px] flex items-center px-2 space-x-2 divide-x">
                <div className="w-[20px] h-[20px]">
                  <img src={item.icon} alt="" className="object-contain" />
                </div>
                <div className="w-full pl-2">
                  <input
                    type="text"
                    name={item.name}
                    className="w-full focus:outline-none text-[14px]"
                    placeholder="@username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[`${item.name}`]}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-[1em]">
          <BlueButton text="Update" type="submit" isLoading={isSubmitting} />
        </div>
      </form>
    </div>
  );
};

export default SocialHandles;
