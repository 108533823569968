import dayjs from "dayjs";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useStore = create(
  devtools((set) => ({
    objectives: "announcement",
    setObjectives: (data) => {
      set(() => ({ objectives: data }));
    },
    surveyTemplate: {
      questions: [],
    },
    setSurveyTemplate: (data) => {
      set(() => ({ surveyTemplate: data }));
    },

    smsTemplate: {},
    setSmsTemplate: (data) => {
      set(() => ({ smsTemplate: data }));
    },
    voiceTemplate: {},
    setVoiceTemplate: (data) => {
      set(() => ({ voiceTemplate: data }));
    },

    onBoarding: "",
    setOnBoarding: (data) => {
      set(() => ({ onBoarding: data }));
    },
    totalDoc: 0,
    setTotalDoc: (data) => {
      set(() => ({ totalDoc: data }));
    },
    onBoardingCount: 0,
    setOnBoardingCount: (data) => {
      set((state) => ({ onBoardingCount: state.onBoardingCount + data }));
    },
    emailTemplate: null,
    setEmailTemplate: (data) => {
      set(() => ({ emailTemplate: { data } }));
    },
    dataCalendar: {},
    setDataFromCalendar: (data) => {
      set(() => ({ dataCalendar: data }));
    },
    wsPlan: "",
    setWSPlan: (data) => {
      set(() => ({ wsPlan: data }));
    },
    userPlan: "",
    setUserPlan: (data) => {
      set(() => ({ userPlan: data }));
    },

    user: "",
    setUser: (data) => {
      set(() => ({ user: data }));
    },
    events: [],
    setEvents: (data) => {
      set(() => ({ events: data }));
    },
    currentYear: "",
    setCurrentYear: (data) => {
      set(() => ({ currentYear: data }));
    },
    currentMonth: "",
    setCurrentMonth: (data) => {
      set(() => ({ currentMonth: data }));
    },
    remindMeOnNextVisit: false,
    setRemindMeOnNextVisit: (data) => {
      set(() => ({ remindMeOnNextVisit: data }));
    },
  })),
);

export const useStoreCalender = create((set) => ({
  monthIndex: dayjs().month(),
  setMonthIndex: (index) => set({ monthIndex: index }),
}));
