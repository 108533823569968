import dayjs from "dayjs";
import React from "react";
import { useSideBarStore } from "store/sidebar/sidebarStore";
// import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";

const WeeklyView = ({ currentDate }) => {
  const { isMobile } = useSideBarStore();

  const getWeekDates = (date) => {
    const startOfWeek = date.startOf("week");
    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(startOfWeek.add(i, "day"));
    }
    return dates;
  };

  const isSameDay = (date1, date2) => {
    return date1.isSame(date2, "day");
  };

  const isCurrentHour = (hour, currentDate, day) => {
    return day.isSame(dayjs(), "day") && hour === dayjs().hour() && currentDate.isSame(dayjs(), "week");
  };

  const weekDates = getWeekDates(currentDate);

  const hours = Array.from({ length: 23 }, (_, i) => i + 1); // Array of hours from 1 to 23

  const formatHour = (hour) => {
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour} ${ampm}`;
  };

  return (
    <div className="flex flex-col sm:items-center w-full">
      {isMobile ? (
        <table className="border border-gray-300">
          <thead>
            <tr className="border">
              <td></td>
              {weekDates.map((date, index) => (
                <td key={index} className="">
                  <p className="font-[400] text-[12px] w-full flex items-center justify-center">{date.format("ddd")}</p>

                  <p className="text-[12px] w-full flex items-center justify-center">{date.format("D")}</p>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {hours.map((hour) => (
              <tr key={hour} className="border">
                <td className="text-[12px] flex items-center justify-center ">{formatHour(hour)}</td>

                {weekDates.map((date, index) => (
                  <td
                    key={index}
                    className={`p-2 border h-12 ${
                      isSameDay(date, dayjs()) && isCurrentHour(hour, currentDate, date) ? "bg-primary-100" : ""
                    }`}
                  >
                    {isSameDay(date, dayjs()) && isCurrentHour(hour, currentDate, date) ? (
                      <span className="font-[400] text-[12px] ">{formatHour(hour)}</span>
                    ) : (
                      ""
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <div className="grid grid-cols-8 w-[100%] border-t-[1px] border-l-[1px]">
            <div className=" border-r-[1px]"></div> {/* Empty top-left corner */}
            {weekDates.map((date, index) => (
              <div
                key={index}
                className="text-center font-bold h-[70px] flex flex-col border-r-[1px] items-center justify-center"
              >
                <p className="font-semibold">{date.format("dddd")}</p>

                <p className="text-lg">{date.format("D")}</p>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-8  w-full border-[1px] h-[62vh] overflow-y-scroll [&::-webkit-scrollbar]:w-0">
            {hours.map((hour) => (
              <React.Fragment key={hour}>
                <div className=" border-t-[1px] border-r-[1px] text-sm pr-2 flex items-center justify-center">
                  {formatHour(hour)}
                </div>

                {weekDates.map((date, index) => (
                  <div
                    key={index}
                    className={`p-2 border-t-[1px] border-r-[1px] h-12 ${
                      isSameDay(date, dayjs()) && isCurrentHour(hour, currentDate, date) ? "bg-primary-100" : ""
                    }`}
                  >
                    {isSameDay(date, dayjs()) && isCurrentHour(hour, currentDate, date) ? (
                      <span className="font-bold text-sm ">{formatHour(hour)}</span>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WeeklyView;
