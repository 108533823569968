import React, { useEffect, useState } from "react";
import OverviewCard from "../OverviewCard";
import StackedBarCharts from "../Charts/StackedBarCharts";
import HalfPieChart from "../Charts/HalfPieChart";
import Typography from "utils/typography";
import ChartCard from "../ChartCard";
import gender from "assets/svgs/gender.svg";
import location from "assets/svgs/Location.svg";
import emptyGender from "assets/svgs/emptyGender.svg";
import emptyLocation from "assets/svgs/emptyLocation.svg";

const CustomerProfilingOverview = ({ nameKey, overViewData }) => {
  const [genderAnalytics, setGenderAnalytics] = useState(null);
  const [locationAnalytics, setLocationAnalytics] = useState(null);

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  useEffect(() => {
    // console.log("over::" ,overViewData)
    const genderAnalytics = overViewData?.cpData
      ? Object.entries(overViewData.cpData).filter(([key, value]) => key.includes("gender"))
      : [];
    setGenderAnalytics(genderAnalytics && genderAnalytics[0] ? genderAnalytics[0][1].analytics : null);

    const locationAnalytics = overViewData?.cpData
      ? Object.entries(overViewData.cpData).filter(([key, value]) => value.questionType === "location")
      : null;
    const stateObj =
      locationAnalytics && locationAnalytics[0]
        ? locationAnalytics[0][1].analytics[Object.keys(locationAnalytics[0][1].analytics)[0]]
        : null;

    //console.log("state ::", stateObj)
    setLocationAnalytics(stateObj);
  }, [overViewData]);

  return (
    <div className="space-y-5">
      <div className="flex items-center sm:justify-between sm:space-x-4 sm:flex-row flex-col sm:gap-0 gap-2">
        {overViewData?.onlineSummary.map((elm, i) => (
          <OverviewCard
            key={`${nameKey}-${i}`}
            elm={elm}
            loading={false}
            img={elm.image}
            title={elm.name}
            value={elm.value}
          />
        ))}
      </div>

      <div className="flex items-center gap-[1.5em] sm:flex-row flex-col">
        <ChartCard subtitle={"Gender"}>
          {genderAnalytics ? (
            <div className="flex flex-col items-center justify-center w-[100%]">
              <div className=" flex flex-row justify-center border-3 py-10">
                <img src={gender} alt="My SVG" width="182" height="122" />
              </div>
              <div className="grid grid-cols-2 gap-5  border-0 p-2 mx-10">
                {Object.entries(genderAnalytics).map(([key, value], index) => (
                  <div className="flex flex-row justify-between border-2 shadow p-2 rounded gap-5">
                    <div className="flex flex-row justify-center items-center gap-1">
                      <div
                        className={`w-3 h-3 border rounded ${index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"}`}
                      ></div>
                      <div className="text-xs">{key}</div>
                    </div>
                    <div className="text-xs">{value.percentage}%</div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center py-20 gap-10">
              <div className="flex flex-row items-center justify-center">
                <img src={emptyGender} alt="empty gender details" width={"81.78px"} height={"53.16px"} />
              </div>
              <div className="text-center font-light text-sm ">Your contacts distribution will be displayed here</div>
            </div>
          )}
        </ChartCard>

        <ChartCard subtitle={"Top location"}>
          {locationAnalytics ? (
            <div className="flex flex-col items-center justify-center w-[100%]">
              <div className="w-full flex flex-row justify-center border-3 py-10">
                <img src={location} alt="My SVG" width="95" height="127" />
              </div>
              <div
                className={`grid ${
                  locationAnalytics.length > 1 ? "grid-cols-2" : "grid-cols-1"
                } gap-5  border-0 p-2 mx-3`}
              >
                {Object.entries(locationAnalytics).map(([key, value], index) => (
                  <div className="flex flex-row justify-between border-2 shadow p-2 rounded">
                    <div className="flex flex-row justify-center items-center gap-1">
                      <div
                        className={`w-3 h-3 border rounded ${index % 2 === 0 ? "bg-violet-400" : "bg-violet-800"}`}
                      ></div>
                      <div className="text-xs">{key}</div>
                    </div>
                    <div className="text-xs">{value}%</div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center py-20 gap-10">
              <div className="flex flex-row items-center justify-center">
                <img src={emptyLocation} alt="empty gender details" width={"81.78px"} height={"53.16px"} />
              </div>
              <div className="text-center font-light text-sm ">Your contacts location will be displayed here</div>
            </div>
          )}
        </ChartCard>
      </div>
    </div>
  );
};

export default CustomerProfilingOverview;
